import * as React from 'react';

import { styled } from '@mui/material';
import { Button, CircularProgress } from '@mui/material';

const SubmitButtonStyled = styled(Button)(({ theme }) => ({
    background: theme.palette.secondary.main,
    width: "100%",
    padding: 2,
    border: "1px solid " + theme.palette.primary.main
}))
export const CustomSubmitIconButton = styled(Button)(({ theme }) => ({
    minWidth: "0px", width: "40px", height: "100%", borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
}))
const CustomButton = ({ title, loading, children, ...props }) => {
    return <SubmitButtonStyled   {...props} >
        {!loading && children}
        {loading && <CircularProgress />}
    </SubmitButtonStyled>


}

export default CustomButton

