import { useCallback, useEffect, useState } from "react"

import { callApiAction } from "../../../store/actions/commonAction"
import { useDispatch } from "react-redux"
import InvoiceDetailUi from "./InvoiceDetailUi"
import { getInvoicesByIdApi } from "../../../apis/invoice.api"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"
import { INVOICES_NAME } from "../../../utils/constants/invoice.constant"
import { closeModal } from "../../../store/actions/modalAction"

const InvoiceDetailsController = ({ id, invoiceNo, type, callBack = () => { } }) => {
    const dispatch = useDispatch()
    const title = `${INVOICES_NAME[type] ?? ""} Invoice: (${invoiceNo})`
    const modalKey = MODAL_KEYS.INVOICE_DETAILS
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)

    const fetchInvoiceById = useCallback((id) => {

        setLoading(true)
        dispatch(callApiAction(
            async () => await getInvoicesByIdApi({ id }),
            (response) => {

                setData(response)

                setLoading(false)

            },
            (err) => {
                setLoading(false)
            }
        ))

    }, [])
    useEffect(() => {
        if (id)
            fetchInvoiceById(id)
    }, [])
    const callBackFun = (projectId, fields, response) => {
        if (!fields){
            setData(null)
            dispatch(closeModal(modalKey))
        }
        else
            setData({ ...data, fields })

        callBack(projectId, fields, response)
        fetchInvoiceById(id)
    }
    return <InvoiceDetailUi title={title} modalKey={modalKey} data={data} loading={loading} setData={setData} callBack={callBackFun} />
}
export default InvoiceDetailsController
