
import {  useState } from "react"

import { deleteTaskApi } from "../../../apis/task.api"
import { useDispatch } from "react-redux"
import { callApiAction } from "../../../store/actions/commonAction"
import { callSnackBar } from "../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../utils/constants/constants"
import { closeModal } from "../../../store/actions/modalAction"
import { CircularProgress, IconButton } from "@mui/material"
import { Delete } from "@mui/icons-material"


const DeleteTaskButton = ({  id }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)

    

    const onDltClick = () => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await deleteTaskApi({ id }),
                async (response) => {


                    dispatch(closeModal())
                    setLoading(false)
                    dispatch(callSnackBar("Task Deleted Successfully", SNACK_BAR_VARIETNS.suceess))
                },
                (err) => {
                    dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                    setLoading(false)

                }
            )
        )
    }

    if(loading)
    return <CircularProgress />
   return <IconButton title="Delete" color="error" onClick={onDltClick} loading={loading} >
    <Delete ></Delete>
   </IconButton>

    
}
export default DeleteTaskButton