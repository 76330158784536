import {  Box, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useTheme } from "@mui/material"

import PaddingBoxInDesktop from "../../../components/layouts/PaddingBoxInDesktop"
import { Chart, ArcElement } from 'chart.js'
import TimeRangeSelector from "../../../components/layouts/common/TimeRangeSelector"
import { useSelector } from "react-redux"

Chart.register(ArcElement);
const LeadAnalyticsByStatusAndSalesPersonUI = ({ loading, list, filters, setFilters }) => {
    const { user } = useSelector(state => state)
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"))
    return (
        <>
            <Paper sx={{ width: isSmallScreen ? "100%" : "unset" }} >
                <Box p={3}>
                    <Box sx={{ display: "flex", alignItems: 'center', flexWrap: "wrap" }} mb={2}>
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                            <Typography variant="h6" fontWeight={"bold"} >Assigned Lead By Sales Person</Typography>
                            <Typography variant="caption" fontWeight={"bold"} >(This calculation is based on last lead update)</Typography>
                        </Box>
                        <PaddingBoxInDesktop sx={{ display: "flex", flex: 1, maxWidth: "300px" }}  >

                            <TimeRangeSelector dateType="last_updated_at" defaultVal={{label:filters.label,_id:filters._id}} margin="none" size="small" onChange={(newRange) => { setFilters({ ...filters, ...newRange }) }} />

                        </PaddingBoxInDesktop>
                    </Box>
                    <Box sx={{ display: "flex" }} >




                    </Box>

                    <Box sx={{ width: "100%", maxWidth: "100%" }}>
                        {
                            loading && <Skeleton width={"100%"} variant="rectangular" height={isSmallScreen ? "250px" : "400px"} />
                        }
                        <TableContainer sx={{ width: "100%" }}>
                            <Table size="small" sx={{ width: "100%", borderRadius: "5px", overflow: "hidden" }}>
                                <TableHead sx={(theme) => ({ background: theme.palette.primary.main ,"*":{color: theme.palette.text.invert}})}>
                                    {
                                        !loading && list && Array.isArray(list) && list?.slice(0, 1).map((item, index) => {

                                            return <TableRow key={index} >
                                                {
                                                    item?.map((subItem, subIndex) => <TableCell key={subIndex}>
                                                        <Typography textTransform="capitalize" variant="subtitle2" >
                                                            {subItem}
                                                        </Typography>
                                                    </TableCell>)
                                                }
                                            </TableRow>
                                        }

                                        )
                                    }
                                </TableHead>
                                <TableBody>
                                    {
                                        !loading && list && Array.isArray(list) && list?.slice(1).map((item, index) => {

                                            return <TableRow key={index} sx={(theme) => ({ background: index == list.length - 2 ? theme.palette.primary.light : index % 2 == 0 ? "#f2f2f2" : '' })} >
                                                {
                                                    item?.map((subItem, subIndex) => <TableCell sx={{ background: subIndex == item.length - 1 ? theme.palette.primary.light : "unset" }} key={subIndex} > <Typography textTransform="capitalize" variant="subtitle2" >
                                                        {subItem?.toString()?.split?.('-')?.[0]}{
                                                            subItem?.toString()?.split?.('-')?.[1]
                                                                ? 
                                                                    (" (" + subItem?.toString()?.split?.('-')?.[1] + "kw)") : ""
                                                        }
                                                    </Typography></TableCell>)
                                                }
                                            </TableRow>
                                        }

                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Box>
                </Box>

            </Paper>

        </>
    )
}
export default LeadAnalyticsByStatusAndSalesPersonUI