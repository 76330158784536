import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Apps, ArrowDropDown, AttachMoney, Call, Cancel, CheckCircle, DepartureBoard, Description, Drafts, DriveEta, Email, Info, Laptop, LocalAirport, LocalCafe, LocalCarWash, LocationOn, Message, Notifications, Person, Phone, Restaurant, Subway, SupportAgent, ThumbDown, ThumbUp, Warning } from '@mui/icons-material';
import { Box, Grid, IconButton } from '@mui/material';
import { CenteredBox } from '../layouts/OneViewBox';


const iconList = [
    {
        icon: Call,
        value: 1
    },
    {
        icon: Email,
        value: 2
    },
    {
        icon: Drafts,
        value: 3
    },
    {
        icon: Message,
        value: 4
    },
    {
        icon: DriveEta,
        value: 5
    },
    {
        icon: LocalCarWash,
        value: 6
    },
    {
        icon: Subway,
        value: 7
    },
    {
        icon: DepartureBoard,
        value: 8
    },

    {
        icon: LocalAirport,
        value: 9
    },
    {
        icon: Notifications,
        value: 10
    },
    {
        icon: LocalCafe,
        value: 11
    },
    {
        icon: Restaurant,
        value: 12
    },
    {
        icon: Description,
        value: 13
    },
    {
        icon: Laptop,
        value: 14
    },
    {
        icon: AttachMoney,
        value: 15
    },
    {
        icon: Warning,
        value: 16
    },
    {
        icon: Info,
        value: 17
    },
    {
        icon: SupportAgent,
        value: 18
    },
    {
        icon: ThumbUp,
        value: 19
    },
    {
        icon: ThumbDown,
        value: 20
    },
    {
        icon: LocationOn,
        value: 21
    },
    {
        icon: Cancel,
        value: 22
    },
    {
        icon: CheckCircle,
        value: 23
    },
    {
        icon: Person,
        value: 24
    }
]
 const getIconFromIconValue = (value) => {
    return iconList.find(item => item.value == value)?.icon ?? Apps
}
export const PreviewIcon = React.memo(({ value,icon,...props }) => {
    const Icon = icon?? getIconFromIconValue(value)
    return <Icon  {...props}/>
})
const IconSelectionComponent = ({ value = 1, onChange=()=>{} }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'icon-popover' : undefined;

    return (
        <>
            <Button  disableElevation startIcon={<PreviewIcon value={value} />} endIcon={<ArrowDropDown />} aria-describedby={id} variant="contained" onClick={handleClick}>

            </Button>
            <Popover
                id={id}
                open={open}
                
                anchorEl={anchorEl}
                onClose={handleClose}
                // 
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{width:"200px"}}>
                <Grid container spacing={2} >
                    {
                        iconList.map((item) => <Grid item sm={3} key={item.value} >
                           <CenteredBox p={1}>
                           <IconButton size='small' onClick={()=>{
                            onChange(item.value)
                            handleClose()
                            }} >
                                <PreviewIcon icon={item.icon} />
                            </IconButton>
                           </CenteredBox>
                        </Grid>)
                    }
                </Grid>
                </Box>
            </Popover>
        </>
    );
}
export default React.memo(IconSelectionComponent)
