import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { closeModal, openModal } from "../../store/actions/modalAction"
import { useParams } from "react-router-dom"
import { getInvoicesByIdApi } from "../../apis/invoice.api"
import moment from "moment"
import { PAYMENT_OPTION } from "../../utils/constants/invoice.constant"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import { payPendingPaymentsApi } from "../../apis/invoice.api"
import { Button, Chip } from "@mui/material"
import PayPendingPaymentsUI from "./PayPendingPaymentsUI"
import { Done, PriceCheck, TaskAlt } from "@mui/icons-material"

export const PayPendingInvoiceButton = memo(({ callBack = () => { }, invoice_id, payment_id }) => {
    const disaptch = useDispatch()
    const [verified, setVerified] = useState(false)
    const onClick = () => {
        disaptch(openModal(<PayPendingPaymentsController invoice_id={invoice_id} payment_id={payment_id} callBack={(response) => {
            setVerified(true)
            callBack(response)
        }} />, 'sm', undefined, MODAL_KEYS.PAY_PENDING_INVOICE))
    }
    if (verified)
        return <Chip label="closed" size="small" color="success" icon={<Done />} />
    return <Button startIcon={<TaskAlt />} disableElevation size="small" variant="contained" onClick={onClick} >
        Close Payment
    </Button>
})
const PayPendingPaymentsController = ({ callBack = () => { }, invoice_id, payment_id }) => {
    const validate = useValidate()
    const dispatch = useDispatch()



    const modalKey = MODAL_KEYS.PAY_PENDING_INVOICE
    const title = "Close Payment"

    const updateApi = payPendingPaymentsApi
    const getByIdApi = getInvoicesByIdApi

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        invoice_id: invoice_id,
        payment_id: payment_id,
        payment_by: PAYMENT_OPTION.BANK,
        transaction_id: null,
        paid_amount: 0,
        payment_date: moment(),
        transaction_proof:''


    })
    const [originalDocument, setOriginalDocument] = useState({


    })



    const validationSchemaForUpdate = useMemo(() => ([
        {
            required: true,
            value: fields.transaction_id,

            field: 'Transaction Id '
        },
        {
            required: true,
            value: fields.paid_amount,

            field: 'Paid Amount'
        },
        {
            required: true,
            value: fields.payment_by,

            field: 'Payment Option'
        },
        {
            required: true,
            value: fields.invoice_id,

            field: 'Invoice Id'
        },
        {
            required: true,
            value: fields.payment_id,

            field: 'Payment Id'
        }
    ]), [fields])






    const updateFunction = async () => {
        const validationResponse = validate(validationSchemaForUpdate)
        let updatedData = { invoice_id, payment_id }

        for (let field in originalDocument) {
            if (originalDocument[field] && fields[field] && fields[field] != originalDocument[field]) {
                updatedData[field] = fields[field]
            }
        }
        updatedData['payment_by'] = fields['payment_by']
        updatedData['transaction_id'] = fields['transaction_id']
        updatedData['paid_amount'] = fields['paid_amount']
        updatedData['transaction_proof'] = fields['transaction_proof']
        if (validationResponse === true) {
            const passData = { ...updatedData }

            passData['payment_date'] = fields['payment_date'].valueOf()

            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateApi(passData),
                    async (response) => {

                        await callBack(fields)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }



    const onSubmit = async (e) => {
        e.preventDefault()
        updateFunction()


    }

    const fetchById = (id) => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await getByIdApi({ id }),
                async (response) => {
                    if (response) {
                        const updatableFields = { ...response }
                        delete updatableFields['status']

                        let paymentDetailsObj = {

                        }

                        if (Array.isArray(updatableFields?.payments) && updatableFields?.payments?.length > 0) {
                            for (let item of updatableFields?.payments) {
                                if (item?._id == payment_id) {
                                    paymentDetailsObj['payment_date'] = moment(item?.payment_date ?? new Date().toISOString())
                                    paymentDetailsObj['paid_amount'] = item?.amount
                                    paymentDetailsObj['transaction_id'] = item?.transaction_id
                                    paymentDetailsObj['payment_by'] = item?.payment_by
                                }
                            }
                        }

                        setFields({ ...fields, ...updatableFields, paid_amount: (updatableFields.amount - updatableFields.paid_amount), ...paymentDetailsObj })
                        setOriginalDocument(response)
                        setLoading(false)
                    }




                },
                (err) => {
                    setFields({ ...fields, err })
                    setLoading(false)

                }
            )
        )
    }

    useEffect(() => {
        if (invoice_id)
            fetchById(invoice_id)

    }, [invoice_id])


    return <PayPendingPaymentsUI modalKey={modalKey} title={title} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(PayPendingPaymentsController)