import { memo, useEffect, useMemo, useState } from "react"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import {  getTaskByIdApi } from "../../../apis/task.api"
import TaskDetailsUi from "./TaskDetailsUi"

const TaskDetailsController = ({ callBack=()=>{}, id }) => {
    
    const dispatch = useDispatch()
    

    const title = "Task Details"
    const getByIdApi = getTaskByIdApi

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        id,


    })
   





    

    const fetchById = (id) => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await getByIdApi({ id }),
                async (response) => {


                    setFields({ ...fields, ...response })
                    
                    setLoading(false)

                },
                (err) => {
                    setFields({ ...fields, err })
                    setLoading(false)

                }
            )
        )
    }

    useEffect(() => {
        if (id)
            fetchById(id)

    }, [id])


    return <TaskDetailsUi title={title}  loading={loading} fields={fields} setFields={setFields} />
}
export default memo(TaskDetailsController)