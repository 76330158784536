import { memo,  useMemo, useState } from "react"
import useValidate from "./../../../store/hooks/useValidator"
import UpdateInvoiceUi from "./UpdateInvoiceUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "./../../../store/actions/commonAction"
import { closeModal } from "./../../../store/actions/modalAction"
import { useParams } from "react-router-dom"

import {  updateInvoiceApi } from "../../../apis/invoice.api"

const UpdateInvoiceAmountController = ({ callBack = () => { },fullEdit, id, amount = 0 }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    const params = useParams()

    const title = "Inovice Amount"
    const updateApi = updateInvoiceApi

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
invoice_no:null,
        id,
        amount

    })



    const validationSchemaForUpdate = useMemo(() => ([



        {
            required: true,
            value: fields.amount,

            field: 'Amount'
        },
        
        {
            
            value: fields.amount,

            field: 'Innvoice No',

            custom:()=>{
                if(fullEdit && (!fields.invoice_no||fields.invoice_no?.trim()=='')){
return "Invoice no is required"
                }

                return true
            }

        },
    ]), [fields,fullEdit])




    const createFunction = async () => {
        const validationResponse = validate(validationSchemaForUpdate)

        if (validationResponse === true) {

            const passData = { ...fields }


            setLoading(true)

            dispatch(
                callApiAction(
                    async () => await updateApi(fields),
                    async (response) => {

                        await callBack(fields)
                        setLoading(false)
                        dispatch(closeModal())
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }





    const onSubmit = async (e) => {
        e.preventDefault()

        createFunction()

    }




    return <UpdateInvoiceUi fullEdit={fullEdit} title={title} isUpdate={id} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(UpdateInvoiceAmountController)