import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../../store/hooks/useValidator"
import CreateUi from "./CreateUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { addUserApi, getUserByIdApi, updateUserField } from "../../../apis/user.api"
import { closeModal } from "../../../store/actions/modalAction"
import { useParams } from "react-router-dom"
import { USER_ROLES } from "../../../utils/constants/constants"
import { addSecretsApi, getSecretsByIdApi, updateSecretsField } from "../../../apis/secrets.api"
import { addInvoicesApi, getInvoicesByIdApi, updateInvoicesField } from "../../../apis/invoice.api"
import moment from "moment"
import { INVOICE_STATUS, INVOICE_TYPE, INVOICE_TYPE_LIST, PAYMENT_OPTION } from "../../../utils/constants/invoice.constant"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"

const CreateController = ({ status, project, project_status,disableStatus, type, callBack = () => { }, id }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    const params = useParams()


    const modalKey = MODAL_KEYS.CREATE_INVOICE
    const title = "Invoice "
    const createApi = addInvoicesApi
    const updateApi = updateInvoicesField
    const getByIdApi = getInvoicesByIdApi

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        id,
        status,
        project,
        project_status,
        invoice_no: '',
        amount: '',
        type: type??(status ? INVOICE_TYPE.DEPOSIT : INVOICE_TYPE.GENERAL),
        creation_date: moment(),
        due_date: moment(),
        payment_by: PAYMENT_OPTION.BANK,
        transaction_id: null,
        paid_amount: 0,
        payment_date: moment(),
        custom_invoice: null,
        disableStatus:disableStatus

    })
    const [originalDocument, setOriginalDocument] = useState({


    })


    const validationSchemaForCreate = useMemo(() => ([

        {
            required: true,
            value: fields.amount,
            field: 'Amount '
        },
        {
            required: true,
            value: fields.type,
            field: 'Type '
        },
        {

            value: fields.paid_amount,
            custom: () => {
                if ((fields.status == INVOICE_STATUS.PAID || fields.status == INVOICE_STATUS.PARTIALLY_PAID) && (!fields.paid_amount || fields.paid_amount == '')) {
                    return false
                }
                if ((fields.status == INVOICE_STATUS.PAID || fields.status == INVOICE_STATUS.PARTIALLY_PAID) && (fields.paid_amount && (fields.paid_amount > fields.amount))) {
                    return "Paid amount must be less than or same of Invoice amount"
                }
                return true
            },
            field: 'Paid Amount '
        },
        {

            value: fields.transaction_id,
            custom: () => {
                if ((status == INVOICE_STATUS.PAID || status == INVOICE_STATUS.PARTIALLY_PAID)&&(!fields.transaction_id || fields.transaction_id == '')) {                    
                    return false
                }
                
                return true
            },
            field: 'Transaction Id '
        },
        
        {

            value: fields.custom_invoice,
            custom: () => {
                if ((fields.type==INVOICE_TYPE.DEPOSIT || fields.type==INVOICE_TYPE.ELECTRICIAN_INVOICE) && (!fields.custom_invoice|| fields.custom_invoice=='')) {
                    return 'Reciept is required'
                }
                return true
            },
            field: 'Custom Invoice'
        },
    ]), [fields])

    const validationSchemaForUpdate = useMemo(() => ([
        {            
            
            value: fields.transaction_id,
            custom: () => {
                // this condition is only gor paying/update invoice not in create 
                // because create feature is for admin and created paid invoice must be with transaction id
                // although it will also go in pending payments
                if (!INVOICE_TYPE_LIST.find((item)=>item.value==fields.type)?.debit && (!fields.transaction_id || fields.transaction_id == '')) {
                    return false
                }
                return true
            },
            field: 'Transaction Id '
        }
    ]), [fields])



    const createFunction = async () => {
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {

            const passData = { ...fields }
            passData['creation_date'] = passData['creation_date'].valueOf()
            passData['due_date'] = passData['due_date'].valueOf()
            passData['payment_date'] = passData['payment_date'].valueOf()
            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createApi(passData),
                    async (response) => {

                        await callBack(project, fields,response)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }


    const updateFunction = async () => {
        const validationResponse = validate(validationSchemaForUpdate)
        let updatedData = { id }

        for (let field in originalDocument) {
            if (originalDocument[field] && fields[field] && fields[field] != originalDocument[field]) {
                updatedData[field] = fields[field]
            }
        }
        updatedData['payment_by'] = fields['payment_by']
        updatedData['transaction_id'] = fields['transaction_id']
        updatedData['paid_amount'] = fields['paid_amount']
        if (validationResponse === true) {
            const passData = { ...updatedData }

            passData['payment_date'] = fields['payment_date'].valueOf()

            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateApi(passData),
                    async (response) => {

                        await callBack(project, fields)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }



    const onSubmit = async (e) => {
        e.preventDefault()
        if (id)
            updateFunction()
        else
            createFunction()

    }

    const fetchById = (id) => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await getByIdApi({ id }),
                async (response) => {
                    if (response) {
                        const updatableFields = { ...response }
                        delete updatableFields['status']
                        delete updatableFields['payment_date']
                        delete updatableFields['payment_by']
                        delete updatableFields['transaction_id']
                        delete updatableFields['creation_date']

                        setFields({ ...fields, ...updatableFields, paid_amount: (updatableFields.amount - updatableFields.paid_amount) })
                        setOriginalDocument(response)
                        setLoading(false)
                    }




                },
                (err) => {
                    setFields({ ...fields, err })
                    setLoading(false)

                }
            )
        )
    }

    useEffect(() => {
        if (id)
            fetchById(id)

    }, [id])


    return <CreateUi modalKey={modalKey} project_status={project_status} type={type} title={title} isUpdate={id} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(CreateController)