import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { callApiAction } from "../../../store/actions/commonAction"
import { callSnackBar } from "../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../utils/constants/constants"


import CreateJobPackUi from "./CreateJobPackUi"
import { downloadJobPackApi } from "../../../apis/projects.api"
import { closeModal } from "../../../store/actions/modalAction"
import fileDownload from "js-file-download"

const CreateJobPackController = ({ projectId, name, documents = [] }) => {

    const { user } = useSelector(state => state)

    const title = "Create Job Pack"
    const modalKey = 'job-create'
    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        projectId,
        remarks: ''
    })
    const [selectedDocuments, setSelectedDocuments] = useState([])

    const dispatch = useDispatch()

    const onSubmit = (e) => {
        e?.preventDefault?.()

        setLoading(true)
        dispatch(
            callApiAction(
                async () => await downloadJobPackApi({ project_id: fields.projectId,...fields, documents: selectedDocuments?.map((item => item._id)) }),
                async (response) => {
                    
                    fileDownload(response, name + ".pdf")

                    setLoading(false)
                    dispatch(callSnackBar("Jobpack Downloaded", SNACK_BAR_VARIETNS.suceess))
                    dispatch(closeModal(modalKey))
                },
                (err) => {
                    setLoading(false)
                    dispatch(callSnackBar(err ?? "Remarks cant added successfully", SNACK_BAR_VARIETNS.error))
                },
                true
            )
        )
    }







    return <CreateJobPackUi

        fields={fields}
        setFields={setFields}
        loading={loading}
        title={title}
        modalKey={modalKey}
        documents={documents}
        selectedDocuments={selectedDocuments}
        setSelectedDocuments={setSelectedDocuments}
        onSubmit={onSubmit}

    />

}
export default CreateJobPackController