const systemDetailsTypeCast = {
    inverters: [
        {
            quantity: 0,
            name: "",
            code: ""
        }
    ],
    battries: [
        {
            quantity: 0,
            name: "",
            code: ""
        }
    ],
    modules: [
        {
            quantity: 0,
            name: "",
            code: ""
        }
    ],
    others: [
        {
            quantity: 0,
            name: "",
            code: ""
        }
    ]
}
export const ORDER_MODULE_TYPES = {
    PANNEL: 'modules',
    INVERTERS: 'inverters',
    BATTERIES: 'battries',
    OTHERS: 'others'
}
export const calculateTotalOrderToBePlaced = (systemDetails = systemDetailsTypeCast) => {

    const counts = {
        inverters: {

        },
        battries: {

        },
        modules: {

        },
        others: {

        }
    }


    const { modules, inverters, battries, others } = systemDetails

    const systemDetailObj = { modules, inverters, battries, others }

    for (let item in systemDetailObj)
        if (Array.isArray(systemDetailObj[item]) && systemDetailObj[item].length > 0)
            for (let quantityObj of systemDetailObj[item])
                if (counts[item]?.[quantityObj.code] && counts[item]?.[quantityObj.code]?.quantity)
                    counts[item][quantityObj.code].quantity = (Number(counts[item]?.[quantityObj.code]?.quantity) ?? 0) + quantityObj?.quantity
                else
                    counts[item][quantityObj.code] = quantityObj



    return counts

}
export const getRemainedCount = (arrayOfOrders, objOfAvailableOrder, moduleType) => {

    const usedDataObj = {}

    for (let order of [...arrayOfOrders]) {
        const moduleList = order[moduleType]
        for (let moduleItem of moduleList) {
            if (usedDataObj[moduleItem?.code]) {
                usedDataObj[moduleItem?.code].quantity = Number(moduleItem.quantity) + Number(usedDataObj[moduleItem?.code].quantity)
            } else {
                usedDataObj[moduleItem?.code] = moduleItem
            }
        }
    }

    const remainedData = {}


    for (let item in objOfAvailableOrder[moduleType]) {
        const val = Number(objOfAvailableOrder[moduleType][item]?.quantity??0) - Number(usedDataObj[item]?.quantity??0)
        remainedData[item] = val //> 0 ? val : 0
    }

    return remainedData
}
