import { memo } from "react"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { Box, ButtonBase, Checkbox, FormControlLabel, FormGroup, Grid, Paper, Radio, Typography, styled } from "@mui/material"
import ReactDragListView from 'react-drag-listview'
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import { DragHandle, DragIndicator } from "@mui/icons-material"
import CustomInput from "../../../components/inputs/CustomInput"

const DocumentSelectButton = styled(ButtonBase)(({ theme, selected, disabled }) => ({
    border: "2px solid " + (selected ? theme.palette.primary.main : theme.palette.grey.main),
    display: "flex",
    width: "100%",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    "*": {
        color: disabled ? theme.palette.grey.main : (selected ? theme.palette.primary.main : theme.palette.dark.light),
    }

}))
const SelectedDocumentChip = styled(Paper)(({ theme, selected }) => ({
    border: "2px solid " + (selected ? theme.palette.primary.main : theme.palette.grey.main),
    display: "flex",
    width: "100%",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    "*": {
        color: (selected ? theme.palette.primary.main : theme.palette.dark.light),
    }

}))
const DocumentSelectComponent = ({ name, selected, onClick, disabled,files }) => {

    return <DocumentSelectButton selected={selected} disabled={disabled} onClick={onClick}>
        <Box sx={{ display: "flex", flex: 1 }} >
            <Typography>
                {name}
            </Typography>
            {/* {files} */}
        </Box>
        <CenteredBox flex="0">
            <Checkbox color="primary" checked={selected} disabled={disabled} />
        </CenteredBox>
    </DocumentSelectButton>
}

const SelectedDocumentChipComponent = ({ name, index }) => {

    return <SelectedDocumentChip variant="outlined" component="div" p={2}  >
        <Box sx={{ display: "flex", flex: 1 }} >
            <Typography>
                {index + 1}.    {name}
            </Typography>
        </Box>
        <Box component="button" sx={{ background: "transparent", border: "none", cursor: 'grab' }}>
            <DragIndicator />
        </Box>

    </SelectedDocumentChip>
}

const CreateJobPackUi = ({
    fields,
    setFields,
    loading,
    documents,
    title,
    modalKey,
    selectedDocuments,
    setSelectedDocuments,
    onSubmit
}) => {


    return <CustomDialog

        title={title}
        id={modalKey}
        loading={loading}
        onSubmit={onSubmit}
        confirmText="Download"
    >


        <Grid container spacing={2}>
            <Grid item xs={6}  >
                <Typography mb={2}>
                    Select Document To Append in Job Pack
                </Typography>
                {
                    (documents)?.map((item) => <DocumentSelectComponent
                        key={item?._id}
                        disabled={item?.files?.length == 0}
                        selected={selectedDocuments.findIndex(findItem => findItem?._id == item?._id) != -1}
                        name={item?.name}
                        files={item.files?.[0]}
                        onClick={() => {
                            const indexOf = selectedDocuments.findIndex(findItem => findItem?._id == item?._id)
                        
                            if (indexOf != -1) {
                                const newDocs = [...selectedDocuments]
                                newDocs.splice(indexOf, 1)
                                setSelectedDocuments(newDocs)
                            } else {
                                const newDocs = [...selectedDocuments]
                                newDocs.push(item)
                                setSelectedDocuments(newDocs)
                            }

                        }


                        } />

                    )
                }


            </Grid>
            <Grid item xs={1} ></Grid>
            <Grid item xs={5} >
                <Typography >
                    Set Order Of Documents
                </Typography>
                <Typography sx={{ display: "block" }} variant="caption" mb={2}>
                    All Pdfs Files will be attached at end of document
                </Typography>
                {selectedDocuments.length > 0 && <ReactDragListView

                    onDragEnd={(fromIndex, toIndex) => {
                        
                        const data = [...selectedDocuments]
                        const item = data.splice(fromIndex, 1)[0];
                        data.splice(toIndex, 0, item);
                        setSelectedDocuments([...data])
                    }}
                    nodeSelector={'div'}
                    handleSelector={'button'}

                >

                    {selectedDocuments.map((item, index) => (
                        // <li key={index}>{`${index + 1}.  ${item}`}</li>
                        <SelectedDocumentChipComponent index={index} key={index} name={item?.name} />
                    ))}
                </ReactDragListView >}

                <CustomInput
                    autoFocus={true}
                    multiline
                    rows={4}
                    disabled={loading}
                    value={fields.remarks}
                    onChange={(e) => setFields({ ...fields, err: '', remarks: e.target.value })}
                    type="text"
                    label={"Remarks*"}
                    margin="none"

                >

                </CustomInput>
                <FormGroup>
  <FormControlLabel control={<Checkbox checked={fields.email} onChange={(e)=>setFields({...fields,email:true})} />} label="Send Email" />
  </FormGroup>
            </Grid>
        </Grid>
        {/* </DraggableList>} */}

    </CustomDialog>
}
export default memo(CreateJobPackUi)