import { useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { openModal } from "../../store/actions/modalAction"

import CreateController from "./CreateController"

import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import { getActivityApi } from "../../apis/activity.api"






const ListController = ({ modal }) => {
    const dispatch = useDispatch()

    const title = "Activities"
    const fetchApi = getActivityApi




    const [filters, setFilters] = useState({
        pageNo: 1,

        pageSize: 10,
        search: '',
        searchable: ['title'],
        sort: 'date',

        sortDirection: -1,

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])









    const fetchList = useCallback(() => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }, [filters, loading, list])





    useEffect(() => {
        fetchList()
    }, [filters])


    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController callBack={fetchList} />, 'xs', undefined, MODAL_KEYS.ACTIVITY_CREATE))
    }



    return (
        <>
            <ListUi
                title={title}
                modal={modal}
                onCreateBtnClick={onCreateBtnClick}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                setList={setList}
                callBack={fetchList}

            />

        </>
    )
}
export default ListController