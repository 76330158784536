export const orderActions = {
    ADD_ORDER: "ADD_ORDER",
    REMOVE_ORDER: "REMOVE_ORDER",
    UPDATE_SUPPLIER: "UPDATE_SUPPLIER",
    ADD_MODULES: "ADD_MODULES",
    UPDATE_MODULE_ITEM: "UPDATE_MODULE_ITEM",
    REMOVE_MODULES: "REMOVE_MODULES",
}


export const addOrder = () => {
    return { type: orderActions.ADD_ORDER }
}
export const removeOrder = (id) => {
    return { type: orderActions.REMOVE_ORDER, id }
}
export const updateSupplier = (id, value) => {
    return { type: orderActions.UPDATE_SUPPLIER, id, value }
}
export const addModules = (orderId,moduleType) => {
    return { type: orderActions.ADD_MODULES,orderId, moduleType }
}
export const removeModules = (orderId, moduleType, itemId) => {
    return { type: orderActions.REMOVE_MODULES, orderId, itemId, moduleType }
}
export const updateModuleItem = (orderId, moduleType, itemId, itemName, itemValue) => {
    return { type: orderActions.UPDATE_MODULE_ITEM, orderId, moduleType, itemId, itemName, itemValue }
}
