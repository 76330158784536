import axios from "axios";
import { getFileHeaders, getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";

export const getChatsApi = async params => {
  const callResponse = await axios({
    url: endpoints.chatBaseUrl,
    method: "get",
    headers: getHeaders(),
    params,

  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const sendChatApi = async data => {
  const callResponse = await axios({
    url: endpoints.chatBaseUrl,
    method: "POST",
    headers: getHeaders(),

    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const getGroupListApi = async params => {
  const callResponse = await axios({
    url: endpoints.chatGroupsBaseUrl,
    method: "get",
    headers: getHeaders(),
    params,

  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const getGroupsByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.chatGrouypsById,
    method: "get",
    headers: getHeaders(),
    params,

  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const getChatsListApi = async params => {
  const callResponse = await axios({
    url: endpoints.chatFetch,
    method: "get",
    headers: getHeaders(),
    params,

  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};