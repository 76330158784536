import { Button, FormControl, MenuItem, Select, Skeleton, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { center } from "../../assets/css/theme/common"

const StyledButton = styled(Button)(({ theme, active }) => ({
    ...center,
    height: '100%',

    flexDirection: "column",
    overflow: "hidden",
    padding: 2,
    border: "1px solid " + theme.palette.primary.main,
    background: active ? theme.palette.primary.main : theme.palette.primary.light,
    // boxShadow:theme.shadows[2],
   
    ".title":{
        color: active ? theme.palette.text.invert : theme.palette.primary.main,
    },
    ".subtitle":{
        color: active ? theme.palette.text.invert : theme.palette.primary.main,
    },
    ":disabled": {
        background: theme.palette.grey.main,
        ":hover": {
            background: theme.palette.grey.main
        }

    },
    ":hover": {
        transition:"all 0.3s linear",
        background:theme.palette.primary.main,
        "*":{
            color: theme.palette.text.invert,
        }

    }
}))



const ClickButton = ({ loading, active, title, subTitle,midTitle, icon, ...props }) => {

    return <StyledButton {...props} fullWidth={true} active={active}>
        {icon}
        <Typography className="title" variant="body1">{title}</Typography>
        {midTitle &&<Typography className="title" variant="body1">{midTitle}</Typography>}
        <Typography className="subtitle" variant="caption"  >{subTitle}</Typography>



    </StyledButton>
}
export default ClickButton