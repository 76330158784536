import { Close, Download, FileOpen, OpenInBrowser, OpenInNew } from "@mui/icons-material";
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Fade, IconButton, Modal, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { getFile, getFileDirect } from "../apis/file.api";
import fileDownload from 'js-file-download'
const blobToDataURL=(blob, callback)  =>{
    var a = new FileReader();
    a.onload = function(e) {callback(e.target.result);}
    a.readAsDataURL(blob);
}
const FileDownloadComponent = ({ view, text, src, direct, heades = false, fileString, fileNameDefault, ...props }) => {
    const [loading, setLoading] = useState(false)
    const filePath = src ? src.split('/') : []
    const fileName = fileNameDefault ?? filePath[filePath.length - 1]
    const fileNameSPlitted = fileName?.split('.')
    const fileExetension = fileNameSPlitted[fileNameSPlitted?.length - 1]

    const fetchImage = async () => {
        setLoading(true)

        if (fileString) {
            const downloadLink = document.createElement("a");
            downloadLink.href = 'data:' + fileString.mimetype + ';base64,' + fileString.buffer;
            downloadLink.download = fileName;
            downloadLink.click()
            // fileDownload('data:'+fileString.mimetype+';base64,'+fileString.buffer,fileString.originalname)
            setLoading(false)
            return
        }
        const file = direct ? await getFileDirect(src, heades) : await getFile(src)

        if (view) {
            
            const blob =file
            let mimeType = 'application/'+fileExetension
            if(fileExetension=='jpeg'||fileExetension=='png'||fileExetension=='jpg'||fileExetension=='svg'){
                mimeType = "image/"+fileExetension
            }
            const newblob = file.slice(0, blob.size, mimeType)
            window.open(URL.createObjectURL(newblob))
        
          

        } else
            fileDownload(file, (fileNameDefault?.split?.('.')?.[0] ?? (text ?? fileName)) + (!direct && fileExetension ? ("." + fileExetension) : '.pdf'))



        setLoading(false)
    }



    if (loading)
        return <CircularProgress />
    if (text)
        return <Button size="small" color="dark" onClick={fetchImage}>{text}</Button>
    if (view)
        return <IconButton onClick={fetchImage} {...props} ><OpenInNew /></IconButton>
    return (

        <>
            <IconButton onClick={fetchImage} {...props} ><Download /></IconButton>

        </>)
}
export default FileDownloadComponent