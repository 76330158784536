import { useState } from "react"
import SubmitButton from "../../../components/button/SubmitButton"
import { Chip, CircularProgress } from "@mui/material"
import { useDispatch } from "react-redux"
import { callApiAction } from "../../../store/actions/commonAction"
import { uploadJobToStcApi } from "../../../apis/projects.api"
import { callSnackBar } from "../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../utils/constants/constants"
import CustomButton, { CustomSubmitIconButton } from "../../../components/button/CustomButton"
import { ArrowForward } from "@mui/icons-material"

const SendToStcButton = ({ val, id, installer_id, callBack = () => { } }) => {
    const [sent, setSent] = useState(val)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const onClick = () => {

        setLoading(true)
        dispatch(
            callApiAction(
                async () => await uploadJobToStcApi({ installer_id, id }),
                async (response) => {
                    callBack()
                    setSent(true)
                    setLoading(false)

                },
                (err) => {
                    setLoading(false)
                    dispatch(callSnackBar("Job Cant sent to Bridge Select", SNACK_BAR_VARIETNS.error))
                }
            )
        )
    }

    if (loading)
        return <CircularProgress size={20} />
    if (sent)
        return <></>
    return <CustomSubmitIconButton disableElevation variant="contained" sx={{}} color="primary" size="small" title="" loading={loading} onClick={onClick} >  <ArrowForward /></CustomSubmitIconButton>
}
export default SendToStcButton