
const CLIENT_EVENTS = {//what server will emit
    CONNECT: "connect",
    DISCONNECT: 'disconnect',
    ONLINE: 'online',
    LEFT: 'left',
    MESSAGE: 'message',
    DELETE_MESSAGE: 'delete_message',
    
    
    LEFT_GROUP: 'left_group',
    CREATED_GROUP: 'created_group',
    MEMBER_REMOVED:"member_removed",
    ADDED_IN_GROUP: 'added_in_group',
    JOINED_GROUP: 'joined_group',
}
const SERVER_EVENTS = {//what server will receive
    CONNECT: "connect",
    DISCONNECTING: 'disconnecting',
    DISCONNECT: 'disconnect',


    CREATE_GROUP: 'create_group',
    CONNECT_GROUP: 'connect_group',
    JOIN_GROUP: 'join_group',
    ADD_GROUP_MEMEBERS: 'add_group_member',
    REMOVE_GROUP_MEMBERS: "remove_group_member",
    MESSAGE: 'message',
    DELETE_MESSAGE: 'delete_message',

    JOIN_GROUP: 'join_group',
    LEFT_GROUP: 'left_group'

}
const CHAT_TYPES = {
    TEXT: 1,
    IMAGE: 2,
    FILES: 3
}
export { CLIENT_EVENTS, SERVER_EVENTS, CHAT_TYPES }