import { useDispatch, useSelector } from "react-redux"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import MapComponents from "../../../components/layouts/common/MapComponents"
import { Box, CircularProgress, Grid, Typography, useTheme } from "@mui/material"
import { openModal } from "../../../store/actions/modalAction"
import ProjectDetailViewForMap from "./ProjectDetailViewForMap"
import { PROJECT_STATUS } from "../../../utils/constants/project.constant"
import { ColorLens } from "@mui/icons-material"
import PinSvg from '../../../assets/icons/PinIcon.svg'
import PinSvg2 from '../../../assets/icons/PinIcon2.svg'
import PinSvg3 from '../../../assets/icons/PinIcon3.svg'

const CustomerHoldMapView = ({ title, filters, setFilters, list, loading }) => {

    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const theme = useTheme()
    const colors = {
        [PROJECT_STATUS.CUSTOMER_HOLD]: theme.palette.primary.main,
        [PROJECT_STATUS.UNDER_INSTALLATION]: theme.palette.warning.main,
        [PROJECT_STATUS.INSTALLATION_COMPLETED]: theme.palette.success.main,
    }

    return (
        <>
            <CustomDialog
                dialogProps={{ sx: { height: "1000px", overflow: "hidden" } }}
                loading={loading}
                title={` ${title}`}

            >

                <Box sx={{ display: "flex", height: "100%", width: "100%", position: 'relative' }} >

                    {loading ? <CircularProgress /> : <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <MapComponents
                                markers={list?.map((item) => ({
                                    lat: item.lat,
                                    lng: item.lon,
                                    id: item._id,
                                    icon: item.status == PROJECT_STATUS.CUSTOMER_HOLD ? PinSvg2 : (item.status == PROJECT_STATUS.UNDER_INSTALLATION ? PinSvg3 : PinSvg),

                                    id: item?._id,
                                    onClick: () => { setFilters({ ...filters, id: item._id, lat: item.lat, lng: item.lon }) }
                                }))}
                                center={
                                    filters?.lat && filters.lng ? ({
                                        lat: filters?.lat,
                                        lng: filters.lng,
                                    }) :

                                        (
                                            list && Array.isArray(list) && list.length > 0 ? {
                                                lat: list[0].lat,
                                                lng: list[0].lon,
                                            } : {
                                                lat: 36.3737797,
                                                lng: 144.1311277,
                                            }
                                        )
                                }
                            />

                        </Grid>
                        <Grid item xs={12} md={4} >
                            <Box >
                                <Box sx={{ display: "flex", alignItems: "center" }} >
                                    <Box sx={{ height: "20px", width: "20px", background: colors[PROJECT_STATUS.UNDER_INSTALLATION] }} >

                                    </Box>
                                    <Typography ml={2}>
                                        Pending Installation
                                    </Typography>
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "center" }} >
                                    <Box sx={{ height: "20px", width: "20px", background: colors[PROJECT_STATUS.CUSTOMER_HOLD] }} >

                                    </Box>
                                    <Typography ml={2}>
                                        Customer Hold
                                    </Typography>
                                </Box>
                                <Box sx={{ display: "flex", alignItems: "center" }} >
                                    <Box sx={{ height: "20px", width: "20px", background: colors[PROJECT_STATUS.INSTALLATION_COMPLETED] }} >

                                    </Box>
                                    <Typography ml={2}>
                                        Installation Completed
                                    </Typography>
                                </Box>
                            </Box>
                            <ProjectDetailViewForMap id={filters.id} />
                        </Grid>
                    </Grid>}

                </Box>
            </CustomDialog >

        </>
    )
}
export default CustomerHoldMapView