
import { Paper, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { useSelector } from "react-redux"
import SubmitButton from "../../components/button/SubmitButton"
import DataTable from "../../components/tables/DataTable"
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop"
import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector"
import AsynSearchBar from "../../components/inputs/AsynSearchBar"
import { USER_ROLES } from "../../utils/constants/constants"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const ListUi = ({  filters, exportLoading, setFilters, list, roles, loading,  onExportBtnClick, columns }) => {

    const user = useSelector(state => state.user)

    return (
        <>

            <Box mb={3}>
                <Paper elevation={0} sx={{ width: "100%" }} >
                    <Box  >
                        <FiltersBox mt={3} p={2} >

                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }}  >
                                <Box mb={2} >
                                    {<SubmitButton loading={exportLoading} variant="contained" onClick={onExportBtnClick} title={"Export CSV"} />}
                                </Box>
                            </PaddingBoxInDesktop>

                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }} pl={3} >


                                <AsynSearchBar
                                    fullWidth
                                    title="Search Project Id"
                                    size="small"
                                    placeholder={"Search Project Id"}
                                    defaultValue={filters.search}
                                    onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
                                />
                            </PaddingBoxInDesktop>

                        </FiltersBox>


                    </Box>

                    <Box sx={{ minHeight: "300px" }} p={2}>
                        <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                    </Box>
                </Paper >
            </Box >

        </>
    )
}
export default ListUi