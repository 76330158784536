import { CenteredBox } from "../../../../components/layouts/OneViewBox"
import CustomDialog from "../../../../components/layouts/common/CustomDialog"

import { Button, Grid, Box, useTheme, Paper, IconButton, Typography, MenuItem, Select, FormControl, InputLabel, Autocomplete, Menu } from '@mui/material'
import { addModules, addOrder, removeModules, removeOrder, updateModuleItem, updateSupplier } from "./createOrderAction"
import { memo, useContext } from "react"
import { AddCircle, Cancel, Delete } from "@mui/icons-material"
import AsyncDropDown from "../../../../components/inputs/AsyncDropDown"
import { StyledSearchBar } from "../../../../components/inputs/SearchBar"
import { getUserApi } from "../../../../apis/user.api"
import { USER_ROLES } from "../../../../utils/constants/constants"
import { ORDER_MODULE_TYPES, getRemainedCount } from "./createOrderHelperFun"
import { OrderContext } from "./OrderContext"
import NoDataComponent from "../../../../components/layouts/NoDataComponent"



const ModuleItemComponent = memo(({ orderId, moduleType, itemId, code, quantity, name, dispatchOrderActions, title }) => {

    const { totalOrder } = useContext(OrderContext)


    return <Box sx={{ width: "100%" }} mb={2} >
        <Grid container spacing={2}>
            <Grid item xs={5} >

                <Autocomplete

                    autoFocus={false}

                    getOptionLabel={(option) => option['name'] != "" ? option['name'] : option['code']}
                    size="small"
                    onChange={(e, newVal) => {

                        dispatchOrderActions(updateModuleItem(orderId, moduleType, itemId, ['name', 'code', 'quantity'], [newVal?.name ?? null, newVal?.code ?? '', newVal?.quantity ?? 0]))
                    }}

                    renderOption={(params, item) => <MenuItem {...params} ><Typography variant="subtitle2" sx={{ textWrap: "wrap" }}>{item?.name != '' ? item?.name : item?.code}</Typography></MenuItem>}
                    options={Object.values(totalOrder[moduleType] ?? {})}
                    sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                    renderInput={(params) => <StyledSearchBar size="small" placeholder={title + " Name"} {...params} />}
                />


            </Grid>

            <Grid item xs={4} ><StyledSearchBar disabled size="small" fullWidth placeholder={title + " Code"} value={code} onChange={(e) => dispatchOrderActions(updateModuleItem(orderId, moduleType, itemId, 'code', e.target.value))} /></Grid>
            <Grid item xs={2} ><StyledSearchBar type="number" size="small" fullWidth placeholder="Pannel" value={quantity} onChange={(e) => dispatchOrderActions(updateModuleItem(orderId, moduleType, itemId, 'quantity', e.target.value))} /></Grid>
            <Grid item xs={1} ><CenteredBox><IconButton onClick={() => dispatchOrderActions(removeModules(orderId, moduleType, itemId))} ><Delete fontSize="small" /></IconButton></CenteredBox></Grid>
        </Grid>
    </Box>

})
const ModuleComponent = memo(({ orderId, dispatchOrderActions, title, moduleType }) => {
    const { orders } = useContext(OrderContext)

    return <>
        {orders?.find(item => item.id === orderId)?.[moduleType]?.map((item) => <ModuleItemComponent key={item.id} title={title} moduleType={moduleType} orderId={orderId} dispatchOrderActions={dispatchOrderActions} itemId={item?.id} name={item.name} code={item.code} quantity={item.quantity} />)}
    </>



})

const OrderListComponent = memo(({
    orderId,
    supplier,
    dispatchOrderActions,

}) => {

    // const { orders } = useContext(OrderContext)
    const onDelete = () => {
        dispatchOrderActions(removeOrder(orderId))
    }


    const theme = useTheme()
    return <Paper component={Box} mb={2} variant="outlined" sx={{ width: "100%", overflow: "hidden" }} >
        <Box sx={{ display: "flex", background: theme.palette.secondary.main, justifyContent: "flex-end" }} px={2} >
            <IconButton onClick={onDelete} ><Cancel color="error" fontSize={"small"} /></IconButton>
        </Box>
        <Box p={2} >
            <Box>
                <Typography variant="body1" >Supplier:</Typography>
            </Box>
            <AsyncDropDown
                id={'supplier-ordercreate'}
                defaultVal={supplier}
                lazyFun={async (params) => { return await getUserApi({ ...params, role: USER_ROLES.supplier }) }}

                OptionComponent={({ option, ...rest }) => {
                    return <MenuItem {...rest}>{option.name}</MenuItem>
                }}
                onChange={async (changedVal) => { dispatchOrderActions(updateSupplier(orderId, changedVal?._id ?? null)) }}
                titleKey={'name'}
                valueKey={"_id"}
                InputComponent={(params) => <StyledSearchBar placeholder="Select Supplier" {...params} size="small" />}

            />



            <Box display="flex" alignItems="center">
                <Typography variant="body1" >Modules:</Typography><IconButton onClick={() => dispatchOrderActions(addModules(orderId, ORDER_MODULE_TYPES.PANNEL))} ><AddCircle color="info" fontSize={"small"} /></IconButton>
            </Box>
            <Box >
                <ModuleComponent title="Pannel" moduleType={ORDER_MODULE_TYPES.PANNEL} orderId={orderId} dispatchOrderActions={dispatchOrderActions} />

            </Box>


            <Box display="flex" alignItems="center">
                <Typography variant="body1" >Inverters:</Typography><IconButton onClick={() => dispatchOrderActions(addModules(orderId, ORDER_MODULE_TYPES.INVERTERS))} ><AddCircle color="info" fontSize={"small"} /></IconButton>
            </Box>
            <Box >
                <ModuleComponent title="Inverters" moduleType={ORDER_MODULE_TYPES.INVERTERS} orderId={orderId} dispatchOrderActions={dispatchOrderActions} />

            </Box>



            <Box display="flex" alignItems="center">
                <Typography variant="body1" >Batteries:</Typography><IconButton onClick={() => dispatchOrderActions(addModules(orderId, ORDER_MODULE_TYPES.BATTERIES))} ><AddCircle color="info" fontSize={"small"} /></IconButton>
            </Box>
            <Box >
                <ModuleComponent title="Batteries" moduleType={ORDER_MODULE_TYPES.BATTERIES} orderId={orderId} dispatchOrderActions={dispatchOrderActions} />

            </Box>
            <Box display="flex" alignItems="center">
                <Typography variant="body1" >Others:</Typography><IconButton onClick={() => dispatchOrderActions(addModules(orderId, ORDER_MODULE_TYPES.OTHERS))} ><AddCircle color="info" fontSize={"small"} /></IconButton>
            </Box>
            <Box >
                <ModuleComponent title="Other Components" moduleType={ORDER_MODULE_TYPES.OTHERS} orderId={orderId} dispatchOrderActions={dispatchOrderActions} />

            </Box>
        </Box>
    </Paper>
})
const RemainedOrderItem = memo(({ moduleType }) => {
    const { totalOrder, orders } = useContext(OrderContext)

    const itemList = getRemainedCount(JSON.parse(JSON.stringify([...orders])), { ...totalOrder }, moduleType)
    if (Object.keys(itemList)?.length == 0)
        return <Typography variant="subtitle2">NA</Typography>
    return <>
        {
            Object.keys(itemList)?.map((item) => <Box key={item} display="flex" sx={{ "*": { color: itemList[item] >= 0 ? "unset" : "red" } }}>
                <Grid container spacing={2} >
                    <Grid item xs={10}>
                        <Typography variant="subtitle2">{item}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="subtitle2">{itemList[item]}</Typography>
                    </Grid>
                </Grid>


            </Box>
            )
        }
    </>
})
const RemainedOrder = memo(({ }) => {



    const theme = useTheme()
    return <Paper component={Box} mb={2} variant="outlined" sx={{ width: "100%", overflow: "hidden", position: "sticky", top: 0 }} >
        <Box sx={{ background: theme.palette.secondary.main }} px={2} >
            <Typography variant="body1" fontWeight={600} >   Remained Modules</Typography>
        </Box>
        <Box p={2}>
            <Typography variant="body1" fontWeight={600} >Pannels:</Typography>

            <RemainedOrderItem moduleType={ORDER_MODULE_TYPES.PANNEL} />


            <Typography variant="body1" fontWeight={600} >Inverters:</Typography>

            <RemainedOrderItem moduleType={ORDER_MODULE_TYPES.INVERTERS} />



            <Typography variant="body1" fontWeight={600} >Batteries:</Typography>

            <RemainedOrderItem moduleType={ORDER_MODULE_TYPES.BATTERIES} />



            <Typography variant="body1" fontWeight={600} >Others:</Typography>

            <RemainedOrderItem moduleType={ORDER_MODULE_TYPES.OTHERS} />
        </Box>
    </Paper>
})

const CreateOrderUI = ({
    modalKey,
    onSubmit,
    loading,
    err,
    dispatchOrderActions,
    data
}) => {

    return <CustomDialog
        err={err}
        loading={loading}
        id={modalKey}
        onSubmit={onSubmit}
        title={`Create Order`}
        closeText="Close"
        confirmText={`Submit`}
    >

        <Grid container spacing={2}>
            <Grid item xs={8} >
                <Box>
                    <Box>
                        {data?.orders?.length == 0 && <Box mb={3} mt={3}>
                            <NoDataComponent variant="body2" iconVariant="h4" message="Click add order to create order" />
                        </Box>

                        }
                    </Box>
                    <Box>
                        {
                            data?.orders?.map(orderItem => <OrderListComponent
                                key={orderItem.id}
                                orderId={orderItem.id}
                                supplier={orderItem.supplier}
                                dispatchOrderActions={dispatchOrderActions}


                            />
                            )
                        }
                    </Box>
                    <CenteredBox justifyContent="flex-end" >
                        <Button disableElevation size="small" fullWidth variant="contained" onClick={() => {
                            dispatchOrderActions(addOrder())
                        }} >Add Order</Button>

                    </CenteredBox>
                </Box>
            </Grid>
            <Grid item xs={4} >
                <RemainedOrder />
            </Grid>
        </Grid>




    </CustomDialog>
}
export default memo(CreateOrderUI)