import { TextField } from "@mui/material"
import { forwardRef, memo, useEffect, useLayoutEffect, useState } from "react"
import { StyledSearchBar } from "./SearchBar"

const AsyncSearchBar = forwardRef(({ onChange, InputComponent, defaultValue, ...props }, ref) => {
    const [timeOut, setTimeOutState] = useState()
    const [val, setVal] = useState(defaultValue ?? '')
    const [firstTimeLoaded, setFirstTimeLoaded] = useState(false)
    // const InputComp = inputComponent ?? TextField

    useEffect(() => {
        if (firstTimeLoaded) {
            if (timeOut) {
                clearTimeout(timeOut)
            }
            const fun = () => {
                onChange(val)
                clearTimeout(timeOut)
            }


            const newTimeOut = setTimeout(fun, 500)
            setTimeOutState(newTimeOut)
        } else
            setFirstTimeLoaded(true)

    }, [val])

    useLayoutEffect(() => {
        if ( val != props.value)
            setVal(props.value)
    }, [props.value])
    return <StyledSearchBar ref={ref} {...props} value={val} onChange={(e) => { setVal(e.target.value) }} />

})
export default memo(AsyncSearchBar)