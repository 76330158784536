import axios from "axios";
import { getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";




export const getProjectOrdersApi = async params => {
    const callResponse = await axios({
        url: endpoints.projectOrdersBase,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const createProjectOrdersApi = async data => {
    const callResponse = await axios({
        url: endpoints.projectOrdersBase,
        method: "POST",
        headers: { ...getHeaders(), "Content-Type": "application/json" },
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const recieveProjectOrdersApi = async data => {
    const callResponse = await axios({
        url: endpoints.projectOrdersRecieve,
        method: "POST",
        headers: { ...getHeaders(), "Content-Type": "application/json" },
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};