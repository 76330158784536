import axios from "axios";
import { getFileHeaders, getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";




export const getEmailsApi = async params => {
  const callResponse = await axios({
    url: endpoints.emailList,
    method: "get",
    headers: getHeaders(),
    params,

  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const sendEmailApi = async data => {
  const callResponse = await axios({
    url: endpoints.emailList,
    method: "POST",
    headers: getFileHeaders(),

    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const deleteEmailApi = async data => {
  const callResponse = await axios({
    url: endpoints.emailList,
    method: "DELETE",
    headers: getHeaders(),

    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const changeEmailFlagApi = async data => {
  const callResponse = await axios({
    url: endpoints.emailList,
    method: "PATCH",
    headers: getHeaders(),

    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};