import { Button } from '@mui/material'
import { useDispatch } from 'react-redux'
import { openModal } from '../../../../store/actions/modalAction'

import { MODAL_KEYS } from '../../../../utils/constants/modal.constant'
import CustomButton, { CustomSubmitIconButton } from '../../../../components/button/CustomButton'
import RecieveOrderController from './RecieveOrderController'
import { ArrowForward } from '@mui/icons-material'
const RecieveOrderButton = ({ suppliers, project_id, callBack }) => {
    const dispatch = useDispatch()
    const onClick = () => {
        dispatch(openModal(
            <RecieveOrderController callBack={callBack} suppliers={suppliers} project_id={project_id} />,
            "md",
            undefined,
            MODAL_KEYS.RECIEVE_ORDER
        ))
    }
    return <CustomSubmitIconButton disableElevation variant="contained" sx={{}} color="primary" size="small" onClick={onClick} >
        <ArrowForward />
    </CustomSubmitIconButton>
}
export default RecieveOrderButton