import { memo, useEffect, useState } from "react"
import ChatMainUi from "./ChatMainUi"
import { useDispatch, useSelector } from "react-redux"
import { openModal } from "../../store/actions/modalAction"
import CreateChatGroupController from "./CreateChatGroupController"

const ChatMainController = () => {
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()

    const [filters, setFilters] = useState({})





    const sendMessage = (e, callBack = () => { }) => {
        e.preventDefault()

    }



const onCreateButtonClick = ()=>{
dispatch(openModal(<CreateChatGroupController />,"md"))
}



    return <ChatMainUi
    onCreateButtonClick={onCreateButtonClick}
        filters={filters}
        setFilters={setFilters}






    />
}
export default memo(ChatMainController)