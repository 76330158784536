import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"

import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { closeModal, openModal } from "../../store/actions/modalAction"


import { updateProjectsField } from "../../apis/projects.api"
import ProjectUpdateUi from "./ProjectUpdateUi"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"


const ProjectUpdateController = ({ callStart, callBack, id, status, title, concernLetterRequired }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    const modalKey = MODAL_KEYS.UPDATE_PROJECT_STATUS

    const updateStatusApi = updateProjectsField

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        id,
        status,
        remarks: '',
        concern_letter: "",
        concernLetterRequired


    })



    const validationSchemaForStatusChange = useMemo(() => ([
        {
            required: true,
            value: fields.remarks,
            field: 'Remarks ',
        },
        {

            value: fields.concern_letter,
            field: 'Concern Letter',
            custom: () => {
                if (fields.concernLetterRequired && (!fields.concern_letter || fields.concern_letter == '')) {
                    return "Concern letter is required"
                }
                return true
            }
        }
    ]), [fields])





    const statusChangeFun = async () => {
        const validationResponse = validate(validationSchemaForStatusChange)

        if (validationResponse === true) {

            const passData = { ...fields }


            callStart()

            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateStatusApi(passData),
                    async (response) => {

                        await callBack(response)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }




    const onSubmit = async (e) => {
        e.preventDefault()
        statusChangeFun()


    }


    return <ProjectUpdateUi modalKey={modalKey} title={title} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />

}
export default memo(ProjectUpdateController)