//data Format

// {
// totalOrder:{
//     inverters: {
//          "CODE1":"quantity",
//          "CODE2":"quantity"
//     },
//     battries: {

//     },
//     modules: {

//     },
//     others: {

//     }
// }
// orders:[
//     {
//         supplier:'',
//         modules:[
//             {
//                 quantity: Number,
//                 name: String,
//                 code: String
//             }
//         ],
//         battries:[
//             {
//                 quantity: Number,
//                 name: String,
//                 code: String
//             }
//         ],
//         inverters:[
//             {
//                 quantity: Number,
//                 name: String,
//                 code: String
//             }
//         ],
//         others:[
//             {
//                 quantity: Number,
//                 name: String,
//                 code: String
//             }
//         ],
//     }



// ]
// }
import moment from "moment"
import { orderActions } from "./createOrderAction"
import { removeObjectById, replaceObjectValueById } from "../../../../utils/helpers/helper"
const createOrderReducer = (currentState, action) => {

    switch (action.type) {

        case orderActions.ADD_ORDER: {
            const orderList = [...currentState.orders]
            const obj = {
                id: moment().valueOf(),
                supplier: null,
                modules: [],
                battries: [],
                inverters: [],
                others: []
            }
            orderList.push(obj)
            return { ...currentState, orders: orderList }
        }
        case orderActions.REMOVE_ORDER: {
            const orderList = [...currentState.orders]
            const id = action.id
            removeObjectById(orderList, id)
            return { ...currentState, orders: orderList }
        }


        case orderActions.UPDATE_SUPPLIER: {
            const orderList = [...currentState.orders]
            const id = action.id
            const value = action.value
            replaceObjectValueById(orderList, id, 'supplier', value)
            return { ...currentState, orders: orderList }
        }

        case orderActions.ADD_MODULES: {
            const orderList = [...currentState.orders]
            const orderId = action.orderId
            const moduleType = action.moduleType

            const obj = {
                id: moment().valueOf(),
                quantity: 0,
                name: null,
                code: null
            }

            const index = orderList.findIndex(item => item.id === orderId);
            if (index !== -1) {
                orderList[index][moduleType].push(obj)

            }
            return { ...currentState, orders: orderList }
        }
        case orderActions.REMOVE_MODULES: {
            const orderList = [...currentState.orders]
            const orderId = action.orderId
            const moduleType = action.moduleType
            const itemId = action.itemId


            const orderIndex = orderList.findIndex(item => item.id === orderId);
            if (orderIndex !== -1) {
                const itemIndex = orderList[orderIndex][moduleType].findIndex(item => item.id === itemId);
                if (itemIndex !== -1)
                    orderList[orderIndex][moduleType].splice(itemIndex, 1)

            }
            return { ...currentState, orders: orderList }
        }
        case orderActions.UPDATE_MODULE_ITEM: {
            const orderList = [...currentState.orders]
            const { orderId, moduleType, itemId, itemName, itemValue } = action

            const orderIndex = orderList.findIndex(item => item.id === orderId);
            if (orderIndex !== -1) {
                const itemIndex = orderList[orderIndex][moduleType].findIndex(item => item.id === itemId);
                if (itemIndex !== -1) {

                    if (Array.isArray(itemName)) {

                        for (let i=0;i<itemName.length;i++)
                            orderList[orderIndex][moduleType][itemIndex][itemName[i]] = itemValue[i]
                        
                    } else
                        orderList[orderIndex][moduleType][itemIndex][itemName] = itemValue //.splice(itemIndex, 1)}

                }
                return { ...currentState, orders: orderList }
            }
        }

        default: return currentState
    }
}
export default createOrderReducer