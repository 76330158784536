import axios from "axios";
import { getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";

export const getInvoiceData = async params => {
    const callResponse = await axios({
        url: endpoints.invoiceBase,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const getInvoiceSummary = async params => {
    const callResponse = await axios({
        url: endpoints.invoiceSummary,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const getInvoiceOverallSummary = async params => {
    const callResponse = await axios({
        url: endpoints.invoiceOverAllSummary,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const getPendingPaymentsApi = async params => {
    const callResponse = await axios({
        url: endpoints.pendingPayments,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const verifyPendingPaymentsApi = async data => {
    const callResponse = await axios({
        url: endpoints.verifypendingPayments,
        method: "PATCH",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const payPendingPaymentsApi = async data => {
    const callResponse = await axios({
        url: endpoints.paypendingPayments,
        method: "PATCH",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const rejectPendingPaymentsApi = async data => {
    const callResponse = await axios({
        url: endpoints.rejectPendingPayments,
        method: "PATCH",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const addRemarksToPaymentsApi = async data => {
    const callResponse = await axios({
        url: endpoints.invoiceRemarks,
        method: "PATCH",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const getInvoiceDownloadsApi = async params => {
    const callResponse = await axios({
        url: endpoints.invoiceDownload+params?.id,
        method: "get",
        headers: getHeaders(),
        responseType: params?.email?"json":"blob",
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};


export const getInvoicesAnalysisApi = async params => {
    const callResponse = await axios({
        url: endpoints.invoiceAnanalysis,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};


export const getInvoicesByIdApi = async params => {

    const callResponse = await axios({
        url: endpoints.invoiceById,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const createInvoiceFromProjectApi = async data => {
    const callResponse = await axios({
        url: endpoints.createInvoiceFromProject,
        method: "POST",
        headers: getHeaders(),
        responseType: "blob",
        data,
    })
        .then(response => {
            
            return response.data
        })
        .catch(async err => {
           
            const isJsonBlob = (data) => data instanceof Blob && data.type === "application/json";
            const responseData = isJsonBlob(err.response.data) ? await (err.response.data)?.text() : err.response.data || {};
            const responseJson = (typeof responseData === "string") ? JSON.parse(responseData) : responseData;
    
            
           
            return responseJson
        });

    return callResponse;
};

export const createBulkInvoiceApi = async data => {
    const callResponse = await axios({
        url: endpoints.bulkInvoice,
        method: "POST",
        headers: getHeaders(),        
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const updateInvoiceApi = async data => {
    const callResponse = await axios({
        url: endpoints.invoiceBase,
        method: "PUT",
        headers: getHeaders(),        
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const addInvoicesApi = async data => {
    const callResponse = await axios({
        url: endpoints.invoiceBase,
        method: "POST",
        headers: getHeaders(),

        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const deleteInvoicesApi = async data => {
    const callResponse = await axios({
        url: endpoints.invoiceBase,
        method: "DELETE",
        headers: getHeaders(),

        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

export const updateInvoicesField = async data => {
    const callResponse = await axios({
        url: endpoints.invoiceBase,
        method: "PATCH",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};

