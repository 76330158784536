import { Alert, Checkbox, CircularProgress, Typography } from "@mui/material"
import { memo } from "react"
import { useSelector } from 'react-redux'
import CustomInput from "../../components/inputs/CustomInput"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"

import FileInput from "../../components/inputs/FileInput"
import { PROJECT_STATUS } from "../../utils/constants/project.constant"



const ProjectUpdatesUi = ({ title, fields, setFields, loading, onSubmit, modalKey }) => {
    const { user } = useSelector(state => state)


    return <>

        <CustomDialog
            id={modalKey}
            loading={loading}
            err={fields.err}
            disabledSubmit={fields?.remarks?.trim() == ''}
            onSubmit={onSubmit}
            title={` ${title}`}
            closeText="Close"
            confirmText={`Submit`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>

                    {
                        fields.concernLetterRequired &&

                        <FileInput
                            defaults={fields.concern_letter ? [fields.concern_letter] : []}
                            title="Concern Letter*"
                            subTitle="Only .pdf  less than 2mb are valid."
                            accept=".pdf"
                            onChange={(newUrl) => {
                                setFields({ ...fields, concern_letter: newUrl })
                            }} />
                    }
                    {
                        fields.status == PROJECT_STATUS.CUSTOMER_HOLD &&
                        <Alert severity="warning" sx={{mb:1}} >
                            <Typography>Alert!!!</Typography>
                            <Typography variant="caption" >This action will remove assigned installation.</Typography>
                        </Alert>
                    }
                    {<CustomInput
                        disabled={loading}
                        value={fields.remarks}
                        onChange={(e) => setFields({ ...fields, err: '', remarks: e.target.value })}
                        multiline
                        rows={3}
                        type="textarea"
                        label={"Remarks*"}

                    />}



                </>}

        </CustomDialog>
    </>
}
export default memo(ProjectUpdatesUi)