import { CircularProgress, MenuItem } from "@mui/material"

import { Fragment, memo } from "react"
import { useDispatch, useSelector } from 'react-redux'



import CustomInput from "../../components/inputs/CustomInput"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import getSourceApi from "../../apis/sources.api"



const CreateUi = ({ title, isUpdate, fields, setFields, loading, onSubmit, modalKey }) => {



    return <>

        <CustomDialog
            id={modalKey}
            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Create"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Create"}`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    {<CustomInput
                        autoFocus={true}
                        disabled={loading}
                        value={fields.page_id}
                        onChange={(e) => setFields({ ...fields, err: '', page_id: e.target.value })}
                        type="text"
                        label={"Page Id*"}

                    />}

                    {<CustomInput
                        disabled={loading}
                        type={"text"}
                        value={fields.page_token}
                        onChange={(e) => setFields({ ...fields, err: '', page_token: e.target.value })}
                        min={0}
                        label={"Page Token*"}

                    />}


                    <AsyncDropDown
                        id={'default_source'}
                        value={fields?.default_source}
                        lazyFun={async (params) => { return await getSourceApi({ ...params, searchable: ['name'] }) }}

                        OptionComponent={({ option, ...rest }) => {
                            return <MenuItem {...rest}>{option.name}</MenuItem>
                        }}
                        onChange={async (changedVal) => { setFields({ ...fields, default_source: changedVal ? changedVal : null }) }}
                        titleKey={'name'}
                        valueKey={"_id"}
                        InputComponent={(params) => <CustomInput  {...params} label="Select Default Source*" />}
                    />


                </>}

        </CustomDialog>
    </>
}
export default memo(CreateUi)