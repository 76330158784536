import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import CreateUi from "./CreateUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { closeModal } from "../../store/actions/modalAction"
import { useParams } from "react-router-dom"

import { addSourceApi, getSourceByIdApi, updateSourceField } from "../../apis/sources.api"
import { addTeamApi, getTeamByIdApi, updateTeamField } from "../../apis/team.api"

const CreateController = ({ callBack, id }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    const params = useParams()

    const title = "Teams "
    const createApi = addTeamApi
    const updateApi = updateTeamField
    const getByIdApi = getTeamByIdApi

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        id,
        name: '',            

    })
    const [originalDocument, setOriginalDocument] = useState({


    })


    const validationSchemaForCreate = useMemo(() => ([

        {
            required: true,
            value: fields.name,
            field: 'Name',
        }
    ]), [fields])

    const validationSchemaForUpdate = useMemo(() => ([

        {
            required: true,
            value: fields.name,
            field: 'Name',
        }
    ]), [fields])



    const createFunction = async () => {
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createApi(fields),
                    async (response) => {

                        await callBack(response)
                        setLoading(false)
                        dispatch(closeModal())
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }


    const updateFunction = async () => {
        const validationResponse = validate(validationSchemaForUpdate)
        let updatedData = { id }

        for (let field in originalDocument) {

            if (originalDocument[field] && fields[field] && fields[field] != originalDocument[field]) {
                updatedData[field] = fields[field]
            }
        }

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateApi(updatedData),
                    async (response) => {

                        await callBack(response,updatedData)
                        setLoading(false)
                        dispatch(closeModal())
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }



    const onSubmit = async (e) => {
        e.preventDefault()
        if (id)
            updateFunction()
        else
            createFunction()

    }

    const fetchById = (id) => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await getByIdApi({ id }),
                async (response) => {


                    setFields({ ...fields, ...response })
                    setOriginalDocument(response)
                    setLoading(false)

                },
                (err) => {
                    setFields({ ...fields, err })
                    setLoading(false)

                }
            )
        )
    }

    useEffect(() => {
        if (id)
            fetchById(id)

    }, [id])


    return <CreateUi title={title} isUpdate={id} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(CreateController)