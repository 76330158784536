import { createContext, memo, useCallback, useEffect, useMemo, useReducer, useState } from "react"
import createOrderReducer from "./createOrderReducer"
import { ORDER_MODULE_TYPES, calculateTotalOrderToBePlaced, getRemainedCount } from "./createOrderHelperFun"
import CreateOrderUI from "./CreateOrderUI"
import { MODAL_KEYS } from "../../../../utils/constants/modal.constant"
import { useDispatch } from 'react-redux'
import { OrderContext } from "./OrderContext"
import { createProjectOrdersApi } from "../../../../apis/order.api"
import { callApiAction } from "../../../../store/actions/commonAction"
import { closeModal } from "../../../../store/actions/modalAction"



const CreateOrderController = memo(({ system, project_id,callBack =()=>{}}) => {

    const dispatch = useDispatch()
    const modalKey = MODAL_KEYS.CREATE_ORDER
    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState('')
    const [data, dispatchOrderActions] = useReducer(createOrderReducer, {
        totalOrder: calculateTotalOrderToBePlaced(system),
        orders: []
    })

    const validateOrder = () => {
        const arrayOfOrders = JSON.parse(JSON.stringify([...data.orders]))
        const objOfAvailableOrder = JSON.parse(JSON.stringify({ ...data.totalOrder }))

        const remainedModules = Object.values(getRemainedCount(arrayOfOrders, objOfAvailableOrder, ORDER_MODULE_TYPES.PANNEL)).findIndex(itemCount => itemCount != 0)
        const remainedInverters = Object.values(getRemainedCount(arrayOfOrders, objOfAvailableOrder, ORDER_MODULE_TYPES.INVERTERS)).findIndex(itemCount => itemCount != 0)
        const remainedBatteries = Object.values(getRemainedCount(arrayOfOrders, objOfAvailableOrder, ORDER_MODULE_TYPES.BATTERIES)).findIndex(itemCount => itemCount != 0)
        const remainedOthers = Object.values(getRemainedCount(arrayOfOrders, objOfAvailableOrder, ORDER_MODULE_TYPES.OTHERS)).findIndex(itemCount => itemCount != 0)

        if (remainedModules !== -1) {
            setErr("Please use all pannels for creating order")
            return false
        }
        if (remainedInverters !== -1) {
            setErr("Please use all inverters for creating order")
            return false
        }
        if (remainedBatteries !== -1) {
            setErr("Please use all batteries for creating order")
            return false
        }
        if (remainedOthers !== -1) {
            setErr("Please use all others for creating order")
            return false
        }
        for (let order of arrayOfOrders) {

            if (!order.supplier || !order.supplier?._id === '') {
                setErr("Please select supplier")
                return false
            }
            for (let module of order[ORDER_MODULE_TYPES.PANNEL]) {
                if ((!module.name && module.name == '') || (!module.code && module.code == '')) {
                    setErr("Please fill all details in order")
                    return false
                }
                if ((!module.quantity && module.quantity == '' && module.quantity < 0)) {
                    setErr("Quantity must be greater than 0")
                    return false
                }
            }
        }

        return true

    }


    const onSubmit = useCallback((e) => {
        e?.preventDefault()
        const validated = validateOrder()

        if (validated) {
            setErr('')
            setLoading(true)
            dispatch(callApiAction(
                async () => await createProjectOrdersApi({ orders: data.orders,project_id }),
                (response) => {
                    callBack()
                    dispatch(closeModal(modalKey))
                    setLoading(false)
                },
                (err) => {
                    setErr(err)
                    setLoading(false)
                }
            ))
        } 



    }, [data, err, loading])


    return <OrderContext.Provider value={data}>


        <CreateOrderUI
            modalKey={modalKey}
            onSubmit={onSubmit}
            err={err}
            loading={loading}
            data={data}
            dispatchOrderActions={dispatchOrderActions}
        />
    </OrderContext.Provider>




})
export default CreateOrderController