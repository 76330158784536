import { Autocomplete, CircularProgress } from "@mui/material"
import { memo } from "react"

import CustomInput from "../../components/inputs/CustomInput"
import { titleCase } from "../../utils/helpers/helper"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import { INVOICE_TYPE, PAYMENT_OPTION } from "../../utils/constants/invoice.constant"
import { MobileDatePicker } from "@mui/x-date-pickers"
import { findObjectKeyByValue } from "../../utils/helpers/helper"
import FileInput from "../../components/inputs/FileInput"




const PayPendingPaymentsUI = ({ fields, setFields, loading, onSubmit, modalKey }) => {

    return <>

        <CustomDialog
            id={modalKey}
            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`Close Payment`}
            closeText="Back"
            confirmText={`Close Payment`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>







                    {<CustomInput

                        disabled={true}
                        value={fields.invoice_no}
                        onChange={(e) => setFields({ ...fields, err: '', invoice_no: e.target.value })}
                        type="text"
                        label={"Invoice No*"}

                    />}

                    <CustomInput

                        disabled={true}
                        value={fields.paid_amount}
                        onChange={(e) => {

                            setFields({ ...fields, amount: fields.type == INVOICE_TYPE.DEPOSIT ? e.target.value : fields.amount, err: '', paid_amount: e.target.value })
                        }}
                        type="number"
                        label={"Paid Amount*"}

                    />

                    <Autocomplete
                        disableClearable
                        value={findObjectKeyByValue(fields.payment_by, PAYMENT_OPTION)}
                        onChange={(e, newVal) => {
                            setFields({ ...fields, payment_by: newVal ? newVal._id : null, transaction_id: newVal?._id == PAYMENT_OPTION.LOSS ? 'Loss' : '' })
                        }}
                        options={[...Object.keys(PAYMENT_OPTION).map((key) => ({ label: titleCase(key), _id: PAYMENT_OPTION[key] }))]}
                        sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                        renderInput={(params) => <CustomInput placeholder="Select Payment Option*" {...params} label="Select Payment Option*" />}
                    />

                    <MobileDatePicker
                        inputFormat="DD/MM/YYYY"
                        renderInput={(props) => <CustomInput {...props} />}
                        label="Invoice Payment Date*"
                        value={fields.payment_date}
                        onChange={(changedVal) => { setFields({ ...fields, payment_date: changedVal }) }}
                    />

                    <CustomInput

                        disabled={loading}
                        value={fields.transaction_id}
                        onChange={(e) => setFields({ ...fields, err: '', transaction_id: e.target.value })}
                        type="text"
                        label={"Transaction Id*"}

                    />

                    <FileInput
                        defaults={fields.transaction_proof ? [fields.transaction_proof] : []}
                        title="Transaction Proof"
                        subTitle="Only .pdf  less than 2mb are valid."
                        accept=".pdf"
                        onChange={(newUrl) => {
                            setFields({ ...fields, transaction_proof: newUrl })
                        }} />
                </>}

        </CustomDialog>
    </>
}
export default memo(PayPendingPaymentsUI)