import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { closeModal, openModal } from "../../store/actions/modalAction"
import CreateController from "./CreateController"
import { createExpenseFromPredefinedExpenseApi, deleteExpenseApi, deletePreDefinedExpenseApi, getExpenseApi, getPreDefinedExpenseApi } from "../../apis/finance.api"

import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../utils/constants/constants"








const ListController = ({ callBack=()=>{}}) => {
    const dispatch = useDispatch()

    const title = "Predefined Expenses"
    const createApi = createExpenseFromPredefinedExpenseApi
    const fetchApi = getPreDefinedExpenseApi
    const deleteApi = deletePreDefinedExpenseApi
    const modalKey = MODAL_KEYS.PRE_DEFINED_EXPENSES_LIST



    const [filters, setFilters] = useState({


    })

    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])









    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                if (response && Array.isArray(response))
                    setList(response?.map(item => ({ ...item, selected: true })))
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }





    useEffect(() => {
        fetchList()
    }, [filters])

    const onSubmit = (e) => {
        e?.preventDefault()
        if (list.length == 0) {
            dispatch(callSnackBar('Please add atleast one expense', SNACK_BAR_VARIETNS.error))
            return
        }
        setLoading(true)
        dispatch(callApiAction(
            async () => await createApi({ expenses: list?.filter((item)=>item?.selected) }),
            (response) => {
                callBack()
                dispatch(closeModal(modalKey))
                callSnackBar('Expense added',SNACK_BAR_VARIETNS.suceess)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController callBack={fetchList} />, 'xs', undefined, MODAL_KEYS.PRE_DEFINED_EXPENSE_CREATE))
    }
    const onChangeFun = (data, index) => {
        const oldList = [...list]
        oldList[index] = data
        setList(oldList)

    }
    const onRemove = (id) => {

        setLoading(true)
        dispatch(callApiAction(
            async () => await deleteApi({ id }),
            (response) => {
                fetchList()
                setLoading(false)
                
            },
            (err) => {
                setLoading(false)
            }
        ))
    }


    return (
        <>
            <ListUi
                title={title}
                onSubmit={onSubmit}
                modalKey={modalKey}
                onCreateBtnClick={onCreateBtnClick}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                onChangeFun={onChangeFun}
                onRemove={onRemove}

            />

        </>
    )
}
export default ListController