import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../../store/hooks/useValidator"

import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"

import { createProjectDocumentsApi } from "../../../apis/projects.api"
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Typography, Zoom, createFilterOptions } from "@mui/material"
import SubmitButton from "../../../components/button/SubmitButton"
import CustomInput from "../../../components/inputs/CustomInput"
import { findObjectKeyByValue } from "../../../utils/helpers/helper"
import { DOCUMENT_TYPES, FILE_TYPES, PROJECT_DOCUMENT_ACTION } from "../../../utils/constants/project.constant"

const CreateDocumentController = ({ dispatch, closeModal, project_id, open }) => {
    const validate = useValidate()
    const apiDispatch = useDispatch()



    const createApi = createProjectDocumentsApi

    const [loading, setLoading] = useState(false)
    const defaultData = useMemo(() => ({
        project: project_id,
        name: '',
        type: FILE_TYPES.IMAGES
    }))
    const [fields, setFields] = useState({
        err: '',

        ...defaultData
    })



    const validationSchemaForCreate = useMemo(() => ([

        {
            required: true,
            value: fields.name,
            field: 'Document Name',
        }
    ]), [fields])





    const createFunction = async () => {
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {


            setLoading(true)
            apiDispatch(
                callApiAction(
                    async () => await createApi(fields),
                    async (response) => {
                        setFields({ ...defaultData })
                        dispatch({ type: PROJECT_DOCUMENT_ACTION.ADD_ITEM, data: response })
                        setLoading(false)
                        closeModal()
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }






    const onSubmit = async (e) => {
        e.preventDefault()
        createFunction()

    }





    return <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={open}
        onClose={closeModal}
        TransitionComponent={Zoom}


    >
        <DialogTitle>
            Create New Document Type
        </DialogTitle>
        <Box onSubmit={onSubmit} component="form" >
            <DialogContent  >
                <Typography color="error" >{fields.err}</Typography>
                {/* {<CustomInput
                    autoFocus={true}
                    disabled={loading}
                    value={fields.name}
                    onChange={(e) => setFields({ ...fields, err: '', name: e.target.value })}
                    type="text"
                    label={"Document Name*"}

                />} */}

                <Autocomplete
                    
                    onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                           
                            setFields({ ...fields, err: '', name: newValue?.value })
                        } else if (newValue && newValue.inputValue) {
                            
                            setFields({ ...fields, err: '', name: newValue.inputValue })
                        } else {
                            setFields({ ...fields, err: '', name: newValue?.value })
                            
                        }
                    }}
                    filterOptions={(options, params) => {
                        const filtered = createFilterOptions()(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue === option.name);
                        if (inputValue !== '' && !isExisting) {
                            filtered.push({
                                inputValue,
                                name: `Add "${inputValue}"`,
                            });
                        }

                        return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="document-name"
                    options={Object.values(DOCUMENT_TYPES).map((item)=>({name:item,value:item}))}
                    getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                            return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                            return option.inputValue;
                        }
                        // Regular option
                        return option.name;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.name}</li>}
                    fullWidth
                    // freeSolo
                    renderInput={(params) => (
                        <CustomInput {...params} label="Document Name" />
                    )}
                />

                <FormControl fullWidth margin="dense">
                    <InputLabel id="demo-simple-select-label">Document Format Type</InputLabel>
                    <Select
                        disableClearable

                        margin="dense"
                        label="Document Format Type"
                        value={fields.type}

                        fullWidth
                        onChange={(e) => { setFields({ ...fields, type: e.target.value }) }}

                        renderInput={(params) => <CustomInput  {...params} placeholder="Document Format Type" label="Document Format Type" size="small" />}
                    >
                        {
                            Object.values(FILE_TYPES).map((val) => (<MenuItem value={val} >{findObjectKeyByValue(val, FILE_TYPES)}</MenuItem>))
                        }

                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeModal} variant="outlined" sx={{ mr: 2 }} >Close</Button>
                <Box>
                    <SubmitButton title="Add" loading={loading} disabled={loading} type="submit"  ></SubmitButton>
                </Box>
            </DialogActions>
        </Box>
    </Dialog>
}
export default memo(CreateDocumentController)