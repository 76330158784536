import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "./../../store/hooks/useValidator"
import BulkCreateUi from "./BulkCreateUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "./../../store/actions/commonAction"
import { closeModal } from "./../../store/actions/modalAction"
import { useParams } from "react-router-dom"
import moment from "moment"
import { BULK_INVOICE_TYPE, INVOICE_STATUS, INVOICE_TYPE, INVOICE_TYPE_LIST, PAYMENT_OPTION } from "../../utils/constants/invoice.constant"
import { createBulkInvoiceApi } from "../../apis/invoice.api"

const BulkCreateController = ({ callBack = () => { }, invoices = [], type = BULK_INVOICE_TYPE.ELECTRICIAN_INVOICE }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    const params = useParams()

    const title = "Bulk Invoice "
    const createApi = createBulkInvoiceApi

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',

        payment_by: PAYMENT_OPTION.BANK,
        transaction_id: null,
        customer_name: "",
        payment_date: moment(),
        custom_invoice: null,
        // invoices: [],
        invoices,

        invoice_type: type,
        installer_id: invoices?.[0]?.installer_id ?? null,
        supplier_id: invoices?.[0]?.supplier_id ?? null
    })



    const validationSchemaForCreate = useMemo(() => ([

        {
            required: true,
            value: fields.customer_name,
            field: 'Invoice Of* '
        },
        // {
        //     required: true,
        //     value: fields.custom_invoice,
        //     field: 'Receipt '
        // },
        {
            required: true,
            value: fields.invoices,
            field: 'Invoices ',
            custom: () => {
                if (fields.invoices.length > 0) {
                    return true
                }
                return false
            },
        },
        {

            value: fields.installer_id,
            field: 'Installer Id ',
            custom: () => {
                if (fields.invoice_type == INVOICE_TYPE.ELECTRICIAN_INVOICE && (!fields.installer_id || fields.installer_id == '')) {
                    return 'Select Installer'
                }
                return true
            },
        },
        {

            value: fields.supplier_id,
            field: 'Supplier Id ',
            custom: () => {
                if (fields.invoice_type == INVOICE_TYPE.SUPPLIER_INVOICE && (!fields.supplier_id || fields.supplier_id == '')) {
                    return 'Select Supplier'
                }
                return true
            },
        },
        {            
            value: fields.transaction_id,
            custom: () => {
                if (!INVOICE_TYPE_LIST.find((item) => item.value == fields.invoice_type)?.debit && (!fields.transaction_id || fields.transaction_id?.trim() == '')) {
                    return false
                }
                return true
            },
            field: 'Transaction Id '
        },
    ]), [fields])




    const createFunction = async () => {
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {

            const passData = { ...fields }

            passData['payment_date'] = passData['payment_date'].valueOf()
            passData['invoices'] = [...passData.invoices].map((item) => item._id)
            if (fields.installer_id) {
                passData['installer_id'] = passData['installer_id']?._id
            }
            if (fields.supplier_id) {
                passData['supplier_id'] = passData['supplier_id']?._id
            }
            setLoading(true)

            dispatch(
                callApiAction(
                    async () => await createApi(passData),
                    async (response) => {

                        await callBack(fields)
                        setLoading(false)
                        dispatch(closeModal())
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }





    const onSubmit = async (e) => {
        e.preventDefault()

        createFunction()

    }




    return <BulkCreateUi title={title} isDefaultInvoices={invoices.length > 0} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(BulkCreateController)