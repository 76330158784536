import { useState } from "react"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { MODAL_KEYS } from "../../../utils/constants/modal.constant"
import { useDispatch } from "react-redux"
import { closeModal } from "../../../store/actions/modalAction"
import { callSnackBar } from "../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../utils/constants/constants"
import { callApiAction } from "../../../store/actions/commonAction"
import fileDownload from "js-file-download";
import CustomInput from "../../../components/inputs/CustomInput"
import { getInvoiceDownloadsApi } from "../../../apis/invoice.api"

const DownloadInvoiceModal = ({ invoiceId, invoiceName = "invoice", email }) => {
    const dispatch = useDispatch()
    const modalKey = MODAL_KEYS.INVOICE_DOWNLOAD
    const [loading, setLoading] = useState(false)
    const [fields, setFields] = useState({
        id: invoiceId,
        remarks: "",
        send_email: email,
    })
    const onSubmit = (e) => {
        e?.preventDefault()
        setLoading(true)
        dispatch(callApiAction(
            async () => await getInvoiceDownloadsApi({ ...fields }),
            (response) => {
                if (!email)
                    fileDownload(response, invoiceName + ".pdf")
                dispatch(closeModal(modalKey))
                setLoading(false)
            },
            (err) => {
                dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                setLoading(false)
            },
            true
        ))
    }
    return <CustomDialog
        id={modalKey}
        title={email ? "Send Invoice" : "Download Invoice"}
        loading={loading}
        onSubmit={onSubmit}
    >
        <CustomInput
            multiline
            rows={4}
            autoFocus={true}
            disabled={loading}
            value={fields.remarks}
            onChange={(e) => setFields({ ...fields, err: '', remarks: e.target.value })}
            type="text"
            label={"Note*"}

        />

    </CustomDialog>
}
export default DownloadInvoiceModal