import moment from "moment"
import { getChatsListApi, getGroupListApi } from "../../apis/chats.api"
import { SERVER_EVENTS } from "../../utils/constants/chat.constant"
import { SNACK_BAR_VARIETNS, actions } from "../../utils/constants/constants"
import { chatgroupsLastReadMessages } from "../../utils/helpers/helper"
import { callApiAction } from "./commonAction"
import { callSnackBar } from "./snackbarAction"

export const connectSocket = (socket, callBack = () => { }) => {
    return (dispatch, getState) => {
        if (getState().chat?.groups?.data?.length > 0) {
            dispatch({ type: actions.SOCKET_CONNECT, socket })
            callBack(true)
        } else {
            dispatch(callApiAction(
                async () => await getGroupListApi(),
                (response) => {
                    dispatch({ type: actions.SOCKET_CONNECT_WTH_GROUPS, socket, groups: response })
                    callBack(true)
                },
                (err) => {
                    callBack(err)
                    dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                }
            ))
        }
    }
}
export const createChatGroupAction = (data) => {
    return (dispatch, getState) => {
        dispatch(callSnackBar('New group is created.', SNACK_BAR_VARIETNS.suceess))
        dispatch({ type: actions.CREATE_CHAT_GROUP, new_group: data })
    }
}
export const createChatGroup = (data, onSuccess = () => { }, onError = () => { }) => {
    return (dispatch, getState) => {

        const chat = getState()?.chat

        if (chat.connected && chat.socket) {
            chat.socket.emit(SERVER_EVENTS.CREATE_GROUP, data, (response) => {
                if (response.status == 0) {
                    dispatch(callSnackBar(response.msg, SNACK_BAR_VARIETNS.error))
                    onError(response.msg)
                } else {
                    onSuccess(response.data)
                    dispatch(createChatGroupAction(response.data))
                }
            })
        } else {
            const err = "Server is not reachable"
            dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
            onError(err)
        }

    }

}

export const addGroupDataAndChats = (groupId, groupData, chatData) => {
    return (dispatch, getState) => {
        const chat = getState()?.chat
        const lastReadMessageTime = chatgroupsLastReadMessages.get(groupId)

        const oldData = chat?.chats?.[groupId]?.data ?? []
        const data = []
        const unreadData = []

        const chatList = chatData?.data ?? []
        for (let item of chatList) {
            if (moment(item?.time).valueOf() > moment(lastReadMessageTime?.messageTime).valueOf()) {
                unreadData.push(item)
            } else {
                data.push(item)
            }
        }

        data.sort((a, b) => moment(a?.time).valueOf() - moment(b?.time).valueOf())

        const finalData = data.concat(oldData)

        dispatch({
            type: actions.ADD_GROUP_CHAT_DATA,
            data: finalData,
            moreAvailable: chatData.more_available,
            groupId,
            unreadData,
            groupData
        })
    }
}

export const startChatGroupLoading = () => {
    return { type: actions.GROUP_CHAT_LOADING, value: true }
}
export const finishChatGroupLoading = () => {
    return { type: actions.GROUP_CHAT_LOADING, value: false }
}
export const openGroupChats = (groupId, groupData, onSuccess = () => { }, onError = () => { }) => {
    return (dispatch, getState) => {

        const chat = getState()?.chat

        if (chat?.chats?.[groupId]) {
            dispatch(changeActiveGroupId(groupId))
        } else {
            // dispatch(startChatGroupLoading())
            dispatch(changeActiveGroupId(groupId, true))
            dispatch(callApiAction(
                async () => await getChatsListApi({ group: groupId }),
                (response) => {

                    dispatch(addGroupDataAndChats(groupId, groupData, response))
                    onSuccess()
                },
                (err) => {
                    dispatch(finishChatGroupLoading())
                    dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                    onError()
                }
            ))
        }


    }

}

export const removeGroupMember = (groupId, memberId, onSuccess = () => { }, onError = () => { }) => {
    return (dispatch, getState) => {

        const chat = getState()?.chat
        if (chat.connected && chat.socket) {
            chat.socket.emit(SERVER_EVENTS.REMOVE_GROUP_MEMBERS, {
                memberId,
                groupId
            },
                (response) => {
                    if (response.status == 0) {

                        dispatch(callSnackBar(response.msg, SNACK_BAR_VARIETNS.error))
                        onError(response.msg)
                    } else {
                        onSuccess(response.data)

                    }
                })
        } else {
            const err = "Server is not reachable"
            dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
            onError(err)
        }

    }

}
export const onGroupMemberReomved = (groupId, memberId) => {
    return {
        type: actions.GROUP_MEMBER_REMOVED,
        groupId,
        memberId
    }
}
export const addMemberToGroup = (groupId, memberId, onSuccess = () => { }, onError = () => { }) => {
    return (dispatch, getState) => {

        const chat = getState()?.chat
        if (chat.connected && chat.socket) {
            chat.socket.emit(SERVER_EVENTS.ADD_GROUP_MEMEBERS, {
                memberId,
                groupId
            },
                (response) => {
                    if (response.status == 0) {

                        dispatch(callSnackBar(response.msg, SNACK_BAR_VARIETNS.error))
                        onError(response.msg)
                    } else {
                        onSuccess(response.data)

                    }
                })
        } else {
            const err = "Server is not reachable"
            dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
            onError(err)
        }

    }
}
export const onAddedToGroup = (groupData) => {
    return (dispatch, getState) => {
        dispatch(callSnackBar('You are added to new group.', SNACK_BAR_VARIETNS.suceess))
        dispatch({ type: actions.ADDED_TO_GROUP, groupData })
    }
}

export const loadMoreChats = (onSuccess, onError) => {
    return (dispatch, getState) => {

        const chat = getState()?.chat
        const groupId = chat?.activeGroupId
        if (chat?.chats?.[groupId]?.moreAvailable) {
            dispatch(callApiAction(
                async () => await getChatsListApi({ group: groupId, last_msg_time: chat?.chats?.[groupId]?.lastMessages?.time }),
                (response) => {

                    dispatch(addGroupDataAndChats(groupId, chat?.chats?.[groupId]?.groupDetails, response))
                    onSuccess()
                },
                (err) => {
                    dispatch(finishChatGroupLoading())
                    onError()
                    dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))

                }
            ))
        }


    }

}
export const sendMessage = (groupId, data = { message: "", messageType: "", user_id: "", group: "", time: '', url: null }, onSuccess = () => { }, onError = () => { }) => {
    return (dispatch, getState) => {

        const chat = getState()?.chat

        if (chat.connected && chat.socket) {



            chat.socket.emit(SERVER_EVENTS.MESSAGE, {


                url: data.url,
                groupId,
                message: data.message,
                time: data.time,
                messageType: data.messageType
            },
                (response) => {
                    if (response.status == 0) {

                        dispatch(callSnackBar(response.msg, SNACK_BAR_VARIETNS.error))
                        onError(response.msg)
                    } else {
                        dispatch(addMessageAction(groupId, { ...data, _id: response.data?._id }))
                        onSuccess(response.data)

                    }
                })
        } else {
            const err = "Server is not reachable"
            dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
            onError(err)
        }

    }

}
export const addMessageAction = (groupId, data = { message: "", messageType: "", url: null }) => {

    return {
        type: actions.ADD_MESSAGE,
        message: { ...data, message_type: data.messageType },
        groupId
    }
}
export const onMessageRecieveAction = (groupId, data = { message: "", messageType: "" }) => {
    return (dispatch, getState) => {
        const chat = getState()?.chat
        const group = chat?.chats?.[chat?.activeGroupId]?.groupDetails?.name ?? "Group"
        if (window.location.pathname?.split('/')[1] != 'chats') {
            dispatch(callSnackBar(`New message from ${data?.name ?? "User"} at ${group}`, SNACK_BAR_VARIETNS.info))

        }
        if (("Notification" in window) && Notification.permission != "granted") {
            (async () => {
                await Notification.requestPermission()
            })()
        } else if (Notification) {
            new Notification(`New message from ${data?.name ?? "User"} at ${group}`, {
                icon: (process.env.PUBLIC_URL + '/' + process.env.REACT_APP_LOGO_SLUG),
                body: data.message ?? "Message here",

            })
        }
        dispatch({
            type: actions.MESSAGE_ARRIVED,
            message: data,
            groupId
        })
    }
}

export const deleteMessageAction = (groupId, messageId, onSuccess = () => { }, onError = () => { }) => {
    return (dispatch, getState) => {

        const chat = getState()?.chat
        if (chat.connected && chat.socket) {
            chat.socket.emit(SERVER_EVENTS.DELETE_MESSAGE, {
                messageId,
                groupId
            },
                (response) => {
                    if (response.status == 0) {

                        dispatch(callSnackBar(response.msg, SNACK_BAR_VARIETNS.error))
                        onError(response.msg)
                    } else {
                        onSuccess(response.data)

                    }
                })
        } else {
            const err = "Server is not reachable"
            dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
            onError(err)
        }

    }

}
export const onMessageDeletedAction = (groupId, messageId) => {
    return {
        type: actions.MESSAGE_DELETED,
        messageId,
        groupId
    }
}
export const changeActiveGroupId = (groupId, loading = false) => {
    return { type: actions.CHANGE_ACTIVE_GROUP, groupId, loading }
}


export const markAsReadToAllChat = (groupId) => {
    return { type: actions.MARK_AS_READ_TO_ALL, groupId }
}

export const markAsUnreadReadToNewMessage = () => {
    return { type: actions.MARK_NEW_MESSAGE_AS_UNREAD, }
}
export const markAsReadReadToNewMessage = () => {
    return { type: actions.MARK_NEW_MESSAGE_AS_READ, }
}

export const disConnectSocket = () => {
    return { type: actions.SOCKET_DISCONNECT }
}
export const resetSocketAndChat = () => {
    return { type: actions.SOCKET_RESET }
}