import { CircularProgress, FilledInput, Paper, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { Fragment, memo } from "react"
import { useDispatch, useSelector } from 'react-redux'
import CustomInput from "../../../../components/inputs/CustomInput"

import CustomDialog from "../../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../../components/layouts/OneViewBox"
import { titleCase } from "../../../../utils/helpers/helper"
import FileInput from "../../../../components/inputs/FileInput"
import { TitleBox } from "../../../../components/layouts/common/boxes"



const RecieveOrderUi = ({ title, fields, setFields, loading, onSubmit, modalKey }) => {
    const { user } = useSelector(state => state)


    return <>

        <CustomDialog
            id={modalKey}
            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={` ${title}`}
            closeText="Close"
            confirmText={`Submit`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    <Typography mt={2} mb={2} >
                        Supplier Invoices:
                    </Typography>
                    {
                        fields?.invoices?.map((item, index) => {
                            return <TitleBox title={titleCase(item?.supplierName)} key={item?.supplier} sx={{ mb: 2 }}  >
                                <CustomInput
                                    autoFocus={true}
                                    disabled={loading}

                                    value={item.invoice_no}
                                    onChange={(e) => {
                                        const invoices = [...fields?.invoices]
                                        invoices[index].invoice_no = e.target?.value
                                        setFields({ ...fields, invoices })
                                    }}
                                    type="text"
                                    label={"Invoice No*"}

                                />
                                <CustomInput
                                    autoFocus={true}
                                    disabled={loading}

                                    value={item.amount}
                                    onChange={(e) => {
                                        const invoices = [...fields?.invoices]
                                        invoices[index].amount = e.target?.value
                                        setFields({ ...fields, invoices })
                                    }}
                                    type="number"
                                    label={"Amount*"}

                                />
                                <FileInput

                                    defaults={fields.custom_invoice ? [fields.custom_invoice] : []}
                                    title="Invoice Slip*"
                                    subTitle="Only .pdf  less than 2mb are valid."
                                    accept=".pdf"
                                    onChange={(newUrl) => {
                                        const invoices = [...fields?.invoices]
                                        invoices[index].custom_invoice = newUrl
                                        setFields({ ...fields, invoices })
                                    }}
                                />
                            </TitleBox>
                        })
                    }
                    <Typography mt={2} mb={2} >
                        Application And Delivery Fees Invoice:
                    </Typography>
                    {<CustomInput
                        autoFocus={true}
                        disabled={loading}
                        value={fields.app_deleivery_fees_amount}
                        onChange={(e) => setFields({ ...fields, err: '', app_deleivery_fees_amount: e.target.value })}
                        type="number"
                        label={"Amount*"}

                    />}




                </>}

        </CustomDialog>
    </>
}
export default memo(RecieveOrderUi)