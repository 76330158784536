import { useState } from "react"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import { Box, Button, ButtonGroup, Grid, Typography } from "@mui/material"
import { findObjectKeyByValue, moduleAccessCondition, titleCase, toTitleCase } from "../../utils/helpers/helper"
import { PROJECT_FLAGS } from "../../utils/constants/project.constant"
import { getStatusOfProjectFlags } from "../../utils/helpers/project.helper"
import { useDispatch, useSelector } from "react-redux"
import { closeModal, openModal } from "../../store/actions/modalAction"
import { Filter, Tune } from "@mui/icons-material"
import CustomButton from "../../components/button/CustomButton"
import MODULES from "../../utils/constants/module.constants"

export const FlagFilterButton = ({ filters, setFilters }) => {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state)
    const onClick = () => {
        dispatch(openModal(
            <FlagFiltersComponent filters={filters} onSubmitFun={(updatedFilters) => {
                setFilters({ ...updatedFilters, filterKey: Math.random() })
                dispatch(closeModal(MODAL_KEYS.PROJECT_FLAG_FILTERS))
            }} />,
            "md",
            undefined,
            MODAL_KEYS.PROJECT_FLAG_FILTERS
        ))
    }
    if (!moduleAccessCondition(user, [MODULES.PROJECT_ACTION_FILTER], []))
        return <></>
    return <Button startIcon={<Tune />} disableElevation variant="contained" size="small" onClick={onClick} >
        Action Filters
    </Button>
}
const FlagFilterBox = ({ flag, setFilters, filters }) => {



    const onChange = (value) => {

        setFilters({ ...filters, ['flag-' + flag]: value })

    }
    const flagValuesObj = getStatusOfProjectFlags(flag)
    return <Box sx={{ width: "100%" }} >
        <Typography variant="body1" > {findObjectKeyByValue(flag, PROJECT_FLAGS)} </Typography>

        <ButtonGroup disableElevation size="small" >
            <Button variant={!filters?.['flag-' + flag] ? "contained" : "outlined"} onClick={() => onChange(null)} >All</Button>

            {
                Object.keys(flagValuesObj).map(item => <Button onClick={() => onChange(flagValuesObj[item])} key={item} variant={filters?.['flag-' + flag] == flagValuesObj[item] ? "contained" : "outlined"}  >

                    {titleCase(item)}

                </Button>)
            }

        </ButtonGroup>
    </Box>

}

const FlagFiltersComponent = ({ filters, onSubmitFun }) => {


    const [flagFilters, setFlagFilters] = useState({ ...filters })
    const onSubmit = (e) => {
        e?.preventDefault()

        onSubmitFun(flagFilters)
    }
    return <CustomDialog

        id={MODAL_KEYS.PROJECT_FLAG_FILTERS}
        title="Set Action Filters"
        onSubmit={onSubmit}
        confirmText="Apply"

    >
        <Grid container spacing={3} >

            {
                Object.keys(PROJECT_FLAGS).map((item) => <Grid item key={item} md={PROJECT_FLAGS[item] == PROJECT_FLAGS.SOLAR_VICTORIA_PRE_APPROVAL ? 12 : 6} xs={12} >
                    <FlagFilterBox flag={PROJECT_FLAGS[item]} filters={flagFilters} setFilters={setFlagFilters} />
                </Grid>
                )
            }
        </Grid>
    </CustomDialog>
}
export default FlagFiltersComponent