import { actions } from "../../utils/constants/constants"

export const dateRangeSelectionAction = (dateType, startDate, endDate, label, id) => {
    return {
        type: actions.CHANGE_DATE_RANGE,
        value: {
            dateType,
            startDate,
            endDate,
            label,
            id
        }
    }
}