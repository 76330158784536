import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { closeModal, openModal } from "../../store/actions/modalAction"

import { Badge, Box, ButtonBase, Chip, CircularProgress, IconButton, Typography } from "@mui/material"
import { useMemo } from "react"
import CreateController from "./CreateController"
import { deleteExpenseApi, getExpenseApi } from "../../apis/finance.api"
import { Delete, Edit, Info } from "@mui/icons-material"
import { getDateFiltersTime } from "../../utils/helpers/helper"

import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import MessageDilog from "../../components/MessageDilog"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import moment from "moment"


export const ExpenseListDialog = ({ title, startDate, endDate }) => {
    return <CustomDialog
        id={MODAL_KEYS.EXPENSES_LIST}
        title={"Expenses List For " + title}
    >
        <ListController modal defaultFilters={{ title,startDate,endDate }} />
    </CustomDialog>
}
const ActionComponent = memo(({ params, setParams }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(0)
    const onDelete = (e) => {
        e?.preventDefault?.()
        setLoading(true)
        dispatch(callApiAction(
            async () => await deleteExpenseApi({ id: params._id }),
            (response) => {
                setParams({})
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
        dispatch(closeModal())
    }
    const onDeleteBtnClick = () => {
        dispatch(openModal(<MessageDilog onSubmit={onDelete} title="Alert!" message="Are you sure to delete??" />, "xs", undefined))
    }
    const onEditBtnClick = () => {
        dispatch(openModal(<CreateController id={params?._id} callBack={(res,editableData)=>setParams({...params,...editableData})} />, "md", undefined,MODAL_KEYS.EXPENSE_CREATE))
    }

    if (loading)
        return <CircularProgress />
    return <Box sx={{ width: "100%", display: "flex" }}>

        {<IconButton disableElevation variant="contained" size="small" onClick={onDeleteBtnClick}>
            <Delete color="primary" />
        </IconButton>}
        {<IconButton disableElevation variant="contained" size="small" onClick={onEditBtnClick}>
            <Edit color="primary" />
        </IconButton>}



    </Box>
})



const ListController = ({ defaultFilters = {},modal }) => {
    const dispatch = useDispatch()

    const title = "Expenses"
    const fetchApi = getExpenseApi

    const columns = useMemo(() => {

        return [
            { id: 3, sort: true, fieldName: 'date', label: 'Date', align: "left",renderValue:(params)=>moment(params.date).format("DD/MM/yyyy") },
            { id: 0, sort: true, fieldName: 'title', label: 'Title', align: "left" },
            { id: 1, sort: true, fieldName: 'amount', label: 'Amount', align: "left" },
            { id: 4, sort: true, fieldName: 'source', label: 'Source', align: "left",renderValue:(params)=>params?.source?.name },
            { id: 2, fieldName: '', label: 'Action', align: "left", renderValue: (params, setParams) => <ActionComponent params={params} setParams={setParams} /> },

        ]

    }, [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,

        pageSize: 10,
        search: '',
        searchable: ['title'],
        sort: 'date',

        sortDirection: -1,
        ...getDateFiltersTime('this_month'),
        ...defaultFilters

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }





    useEffect(() => {
        fetchList()
    }, [filters])


    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController callBack={fetchList} />, 'md', undefined, MODAL_KEYS.EXPENSE_CREATE))
    }



    return (
        <>
            <ListUi
                title={title}
                modal={modal}
                onCreateBtnClick={onCreateBtnClick}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default ListController