import CustomDialog from "../../components/layouts/common/CustomDialog"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import ListController from "./ListController"

const ComplainListDialog = ({ ...props }) => {


    return <CustomDialog
        id={MODAL_KEYS.COMPLAINS_LIST}
        title="Complains"
    >
        <ListController modal={true}  {...props} />
    </CustomDialog>
}
export default ComplainListDialog