import { Button } from "@mui/material"
import { useDispatch } from "react-redux"
import { openModal } from "../../../store/actions/modalAction"
import CreateJobPackController from "./CreateJobPackController"
import CustomButton, { CustomSubmitIconButton } from "../../../components/button/CustomButton"
import { ArrowForward } from "@mui/icons-material"

const CreateJobPackButton = ({ documents, name, projectId }) => {
    const dispatch = useDispatch()
    const onClick = () => {
        dispatch(openModal(<CreateJobPackController name={name} documents={documents} projectId={projectId} />, 'md', undefined, 'job-create'))
    }
    // return <></>
    return <CustomSubmitIconButton disableElevation variant="contained" sx={{}} color="primary" size="small" onClick={onClick}>
        <ArrowForward />
    </CustomSubmitIconButton>
}
export default CreateJobPackButton