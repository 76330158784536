import { actions } from "../../utils/constants/constants";



const initialState = new Map([

])

const dateRangeSelectionReducer = (state = initialState, action) => {    

    switch (action.type) {

        case actions.CHANGE_DATE_RANGE:

            const { dateType, ...values } = action.value
            
            return new Map(state).set(dateType,values);

        default: return new Map(state)
    }

}
export default dateRangeSelectionReducer