import * as React from 'react';
import Menu from '@mui/material/Menu';
import { Box } from '@mui/material';

export default function ContextMenu({ children, renderMenuItems, ...props }) {
  const [contextMenu, setContextMenu] = React.useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6,
        }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
        // Other native context menus might behave different.
        // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
        null,
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  return (
    <Box onContextMenu={handleContextMenu} sx={{ cursor: 'context-menu' }} {...props}>
      {
        children
      }
      <Menu
      sx={{width:"100%",padding:0}}
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {
          renderMenuItems({ handleClose })
        }

      </Menu>
    </Box>
  );
}