import { memo, useEffect, useState } from "react"
import SubmitButton from "../../components/button/SubmitButton"
import { createAttendenceApi, getAttendecesStatusAPi } from "../../apis/attendence.api"
import { useDispatch, useSelector } from "react-redux"
import { callSnackBar } from "../../store/actions/snackbarAction"
import {  LOG_TYPE, SNACK_BAR_VARIETNS } from "../../utils/constants/constants"
import moment from "moment"
import { callApiAction } from "../../store/actions/commonAction"
import { Dialog, DialogContent, Typography } from "@mui/material"

const CheckInCheckOutButton = ({ userId, callBack = () => { }, noDialog }) => {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state)
    const [justCheckout, setJustCheckedOut] = useState(false)
    const [isCheckedIn, setIsCheckIn] = useState(false)
    const [loading, setLoading] = useState(false)
    const fetchStatus = () => {
        setLoading(true)

        dispatch(callApiAction(
            async () => await getAttendecesStatusAPi({ date: moment().toISOString() }),
            (response) => {
                setLoading(false)
                setIsCheckIn(response && response.lastLog == LOG_TYPE.CHECK_IN ? true : false)

               
            },
            (err) => {
                dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                setLoading(false)
            }
        ))
    }
    useEffect(() => {
        fetchStatus()
    }, [])
    const onClick = () => {
        setLoading(true)

        dispatch(callApiAction(
            async () => await createAttendenceApi({ userId }),
            (response) => {
                setLoading(false)
                setJustCheckedOut(isCheckedIn)
                setIsCheckIn(!isCheckedIn)
                
                callBack()
            },
            (err) => {
                dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                setLoading(false)
            }
        ))
    }

    if (!isCheckedIn  && !loading) {
        return <Dialog open={true} >

            <DialogContent>
                <Typography align="center" variant="h5" >
                    {justCheckout?"Bbye" :'Welcome'} {user.data.name}
                </Typography>
                {justCheckout && <> <Typography align="center" variant="subtitle1" mt={3} >
                   See you tomorrow
                </Typography>

                <Typography variant="caption" align="center" mt={3} >
                   Refresh the page if you want to close this popup
                </Typography>
                   
                    </>}
                {!justCheckout && <> <Typography variant="subtitle1" mt={3} >
                    Please Check-in to mark your attendence
                </Typography>
                    <SubmitButton disabled={loading} loading={loading} onClick={onClick} color={isCheckedIn ? "error" : "warning"} title={isCheckedIn ? "Check Out" : "Check In"} /></>}
            </DialogContent>
        </Dialog>
    }
    
    return <SubmitButton size="small" disableElevation disabled={loading} loading={loading} onClick={onClick} color={isCheckedIn ? "warning" : "warning"} title={isCheckedIn ? "Check Out" : "Check In"} />


}
export default memo(CheckInCheckOutButton)