import { Autocomplete, Paper, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";

import { useCallback } from "react";
import { closeModal, openModal } from "../../store/actions/modalAction"
import { useDispatch, useSelector } from "react-redux";
import SubmitButton from "../../components/button/SubmitButton";
import DataTable from "../../components/tables/DataTable";
import AsynSearchBar from "../../components/inputs/AsynSearchBar";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop";
import {
  findObjectKeyByValue,
  moduleAccessCondition,
  titleCase,
} from "../../utils/helpers/helper";
import MODULES from "../../utils/constants/module.constants";
import { MODULES_TYPES } from "../../utils/constants/constants";
import { StyledSearchBar } from "../../components/inputs/SearchBar";
import UploadSheetController from "./UploadSheetController";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const ListUi = ({
  title,
  filters,
  setFilters,
  list,
  roles,
  loading,
  onCreateBtnClick,
  columns,
}) => {
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch()
  const uploadSheet = useCallback((params) => {
    dispatch(
      openModal(
        <UploadSheetController params={params}/>,
        "md", undefined, "upload-sheet")
      )
  }, []);
  return (
    <>
      <Box mb={3}>
        <Paper elevation={2} sx={{ width: "100%", padding: 4 }}>
          <Box mb={4}>
            <FilterTitleBox>
              <Typography variant="h5" mb={2}>
                {title}
              </Typography>

              <Box mb={2}>
                {moduleAccessCondition(user, [MODULES.CREATE_TEAMS], []) && (
                  <SubmitButton
                    variant="contained"
                    onClick={onCreateBtnClick}
                    title={"Add Module"}
                  />
                )}
              </Box>
            </FilterTitleBox>

            <FiltersBox mt={3}>
              <PaddingBoxInDesktop
                mb={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "30%",
                }}
                pl={3}
              >
                <Autocomplete
                  value={findObjectKeyByValue(filters.type, MODULES_TYPES)}
                  disableClearable
                  onChange={(e, newVal) => {
                    setFilters({
                      ...filters,
                      types: newVal ? newVal._id : null,
                    });
                  }}
                  options={[
                    { label: "All", _id: null },
                    ...Object.keys(MODULES_TYPES).map((key) => ({
                      label: titleCase(key),
                      _id: MODULES_TYPES[key],
                    })),
                  ]}
                  sx={{
                    width: "100%",
                    display: "flex",
                    "*": { display: "flex", justifyContent: "center" },
                  }}
                  renderInput={(params) => (
                    <StyledSearchBar
                      placeholder="Select Module Type"
                      {...params}
                      size="small"
                    />
                  )}
                />
              </PaddingBoxInDesktop>
              <PaddingBoxInDesktop
                mb={2}
                sx={{ display: "flex", justifyContent: "flex-end" }}
                pl={3}
              >
                <AsynSearchBar
                  fullWidth
                  title="Search By Name "
                  size="small"
                  placeholder={"Search By Name "}
                  defaultValue={filters.search}
                  onChange={(changedVal) => {
                    setFilters({ ...filters, search: changedVal });
                  }}
                />
                {/* <StyledSearchBar  title="Search By Key Name " size="small" placeholder={"Search By key Name "} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} /> */}
              </PaddingBoxInDesktop>
            </FiltersBox>
          </Box>

          <Box
            sx={{ display: "flex", justifyContent: "center", gap: 2 }}
            mb={2}
          >
            <SubmitButton
              onClick={()=>uploadSheet(MODULES_TYPES.PANNELS)}
              // loading={loading}
              variant="contained"
              title={"Upload Pannels"}
            />
            <SubmitButton
             onClick={()=>uploadSheet(MODULES_TYPES.INVERTERS)}
              // loading={loading}
              variant="contained"
              title={"Upload Inverters"}
            />

            <SubmitButton
             onClick={()=>uploadSheet(MODULES_TYPES.BATTERIES)}
              // loading={loading}
              variant="contained"
              title={"Upload Batteries"}
            />
            <SubmitButton
             onClick={()=>uploadSheet(MODULES_TYPES.OTHERS)}
              // loading={loading}
              variant="contained"
              title={"Upload Others"}
            />
          </Box>

          <Box sx={{ minHeight: "300px" }}>
            <DataTable
              columns={columns}
              rows={list.result ? list.result : []}
              count={list.total ?? 0}
              filters={filters}
              setFilters={setFilters}
              loading={loading}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
};
export default ListUi;
