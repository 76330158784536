
import { Autocomplete, Paper,  Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { StyledSearchBar } from "../../../components/inputs/SearchBar"

import DataTable from "../../../components/tables/DataTable"

import PaddingBoxInDesktop from "../../../components/layouts/PaddingBoxInDesktop"
import { findObjectKeyByValue, titleCase } from "../../../utils/helpers/helper"
import AsynSearchBar from "../../../components/inputs/AsynSearchBar"
import { STOCK_TYPE } from "../../../utils/constants/project.constant"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const ListUi = ({ title, filters, setFilters, list, roles, loading, columns, exportLoading, onExportBtnClick, onCreateBtnClick }) => {




    return (
        <>

            <Box mb={3}>
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={2} >

                        <FilterTitleBox>
                            <Typography variant="h5" mb={2} >{title}</Typography>
                        </FilterTitleBox>


                        <FiltersBox mt={2} p={2} >
                            <PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }}>

                            </PaddingBoxInDesktop>
                            <PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }}  >


                                <Autocomplete
                                    value={findObjectKeyByValue(filters.searchModule, STOCK_TYPE)}
                                    disableClearable
                                    onChange={(e, newVal) => {
                                        setFilters({ ...filters, searchModule: newVal._id })
                                    }}
                                    options={[...Object.keys(STOCK_TYPE).map((key) => ({ label: titleCase(key), _id: STOCK_TYPE[key] }))]}

                                    sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                                    renderInput={(params) => <StyledSearchBar placeholder="Select Stock Type" {...params} size="small" />}
                                />

                            </PaddingBoxInDesktop>
                            <PaddingBoxInDesktop sx={{ display: "flex", justifyContent: "flex-end", flex: 1 }} pl={3} >
                                {/* <StyledSearchBar fullWidth title="Search By Key Name " size="small" placeholder={"Search By Stock name | Stock code "} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} /> */}


                                
                                <AsynSearchBar
                                    fullWidth
                                    title="Search By Key Name " size="small" placeholder={"Search By Stock name | Stock code "}
                                    defaultValue={filters.search}
                                    onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
                                />
                            </PaddingBoxInDesktop>



                        </FiltersBox>

                    </Box>

                    <Box sx={{ minHeight: "300px" }} p={2} pt={0}>
                        <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                    </Box>
                </Paper >
            </Box >

        </>
    )
}
export default ListUi