import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./CustomerHoldMapView"
import { useDispatch, useSelector } from 'react-redux'
import getProjectsApi from "../../../apis/projects.api"
import { callApiAction } from "../../../store/actions/commonAction"
import { PROJECT_PARENT_STATUS } from "../../../utils/constants/project.constant"


const CustomerHoldMapController = ({ }) => {
    const dispatch = useDispatch()

    const { user } = useSelector(state => state)

    const title = "Pending  Projects Installation"
    const fetchApi = getProjectsApi


   


    const [filters, setFilters] = useState({
        
        sort: 'updatedAt',
        parentStatus: PROJECT_PARENT_STATUS.INSTALLATION,
        sortDirection: -1,
       
        id:null

    })




    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])

    



    const fetchList = (isExport) => {
        
            setLoading(true)
            dispatch(callApiAction(
                async () => await fetchApi({ ...filters,all:true,map:true }),
                (response) => {
                    setList(response)
                    setLoading(false)
                },
                (err) => {
                    setLoading(false)
                }
            ))
       
    }
useEffect(()=>{
    fetchList()
},[])

    return (
        <>
            <ListUi
                title={title}                

                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
            />

        </>
    )
}
export default CustomerHoldMapController