import { CircularProgress, MenuItem } from "@mui/material"

import { memo } from "react"
import { useSelector } from 'react-redux'
import CustomInput from "../../../components/inputs/CustomInput"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import { MobileDatePicker } from "@mui/x-date-pickers"

import moment from "moment"
import { getInstallerApi } from "../../../apis/user.api"
import AsyncDropDown from "../../../components/inputs/AsyncDropDown"



const UpdateProjectInstallerUi = ({ title, isUpdate, fields, setFields, loading, onSubmit, type }) => {
    const { user } = useSelector(state => state)


    return <>

        <CustomDialog

            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Create"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Create"}`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>


                    <AsyncDropDown
                        id={'installer-updateinstaller'}
                        value={{ name: fields.assigned_installer_name, _id: fields.assigned_installer }}
                        lazyFun={async (params) => await getInstallerApi({ ...params, onlyValid: true })}
                        label="Installer*"
                        OptionComponent={({ option, ...rest }) => {
                            return <MenuItem {...rest}>{option.name}</MenuItem>
                        }}
                        onChange={async (changedVal) => { setFields({ ...fields, assigned_installer: changedVal ? changedVal._id : null, assigned_installer_name: changedVal ? changedVal.name : null }) }}
                        titleKey={'name'}
                        valueKey={"_id"}
                    />



                    <MobileDatePicker
                        inputFormat="DD/MM/YYYY"
                        renderInput={(props) => <CustomInput {...props} />}
                        label="Installation Date*"
                        value={moment(fields.installtion_date)}
                        onChange={(changedVal) => { setFields({ ...fields, installtion_date: changedVal.toISOString() }) }}
                    />




                </>}

        </CustomDialog>
    </>
}
export default memo(UpdateProjectInstallerUi)