import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import CreateUi from "./CreateUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { closeModal } from "../../store/actions/modalAction"
import { useParams } from "react-router-dom"

import { addSourceApi, getSourceByIdApi, updateSourceField } from "../../apis/sources.api"
import { addLoanBenefitApi, getLoanBenefitByIdApi, updateLoanBenefitField } from "../../apis/loanbenefit.api"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import { addFBIntegrationApi, getFBIntegrationByIdApi, updateFBIntegrationField } from "../../apis/facebookintegration.api"

const CreateController = ({ callBack, id }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    const params = useParams()
    const modalKey = MODAL_KEYS.FB_INTEGRATION
    const title = " Page "
    const createApi = addFBIntegrationApi
    const updateApi = updateFBIntegrationField
    const getByIdApi = getFBIntegrationByIdApi

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        id,
        page_id: '',
        page_token: '',
        default_source: null

    })
    const [originalDocument, setOriginalDocument] = useState({


    })


    const validationSchemaForCreate = useMemo(() => ([

        {
            required: true,
            value: fields.page_id,
            field: 'Paeg Id',
        },
        {
            required: true,
            value: fields.page_token,
            field: 'Page Token',
        },
        {
            required: true,
            value: fields.default_source,
            field: 'Default Source',
        }
    ]), [fields])

    const validationSchemaForUpdate = useMemo(() => ([

        {
            required: true,
            value: fields.page_id,
            field: 'Paeg Id',
        },
        {
            required: true,
            value: fields.page_token,
            field: 'Page Token',
        },
        {
            required: true,
            value: fields.default_source,
            field: 'Default Source',
        }
    ]), [fields])



    const createFunction = async () => {
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {
            const dataToBepassed = {}
            for (let item in { ...fields }) {
                if (typeof (fields[item]) == "object") {
                    dataToBepassed[item] = fields[item]?._id
                }else{
                    dataToBepassed[item] = fields[item]
                }
            }

            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createApi(dataToBepassed),
                    async (response) => {

                        await callBack(response)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }


    const updateFunction = async () => {
        const validationResponse = validate(validationSchemaForUpdate)
        let updatedData = { id }

        for (let field in originalDocument) {

            if (originalDocument[field] && fields[field] && fields[field] != originalDocument[field]) {
                // updatedData[field] = fields[field]
                if (typeof (fields[field]) == "object") {
                    updatedData[field] = fields[field]?._id
                }else{
                    updatedData[field] = fields[field]
                }
            }
        }

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await updateApi(updatedData),
                    async (response) => {

                        await callBack(response, updatedData)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }



    const onSubmit = async (e) => {
        e.preventDefault()
        if (id)
            updateFunction()
        else
            createFunction()

    }

    const fetchById = (id) => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await getByIdApi({ id }),
                async (response) => {


                    setFields({ ...fields, ...response })
                    setOriginalDocument(response)
                    setLoading(false)

                },
                (err) => {
                    setFields({ ...fields, err })
                    setLoading(false)

                }
            )
        )
    }

    useEffect(() => {
        if (id)
            fetchById(id)

    }, [id])


    return <CreateUi modalKey={modalKey} title={title} isUpdate={id} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(CreateController)