import { Autocomplete, Box, CircularProgress, Grid, MenuItem, Table, TableCell, TableHead, TableRow, Typography, useTheme, } from "@mui/material"

import { Fragment, memo } from "react"
import { useSelector } from 'react-redux'
import CustomInput from "./../../components/inputs/CustomInput"
import { titleCase, toTitleCase } from "../../utils/helpers/helper"

import CustomDialog from "./../../components/layouts/common/CustomDialog"
import { CenteredBox } from "./../../components/layouts/OneViewBox"
import { BULK_INVOICE_TYPE, INVOICE_STATUS, INVOICE_TYPE,  INVOICE_TYPE_LIST,  PAYMENT_OPTION } from "../../utils/constants/invoice.constant"
import { MobileDatePicker } from "@mui/x-date-pickers"
import { findObjectKeyByValue } from "../../utils/helpers/helper"
import FileInput from "./../../components/inputs/FileInput"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { getInvoiceData } from "../../apis/invoice.api"
import { getInstallerApi, getUserApi } from "../../apis/user.api"
import { USER_ROLES } from "../../utils/constants/constants"

const TableTotal = memo(({ data = [] }) => {
    const theme = useTheme()
    if (data.length == 0)
        return <></>
    return <Box p={3} >
        <Grid container spacing={2} sx={{ border: "1px solid black" }}>
            <Grid item xs={6} sx={{ background: theme.palette.primary.main }} ><Typography variant="subtitle1" color="text.invert" align="center" >
                Invoice No.</Typography></Grid>
            <Grid item xs={6} sx={{ background: theme.palette.primary.main }}>
                <Typography variant="subtitle1" align="center" color="text.invert"     >
                    Amount
                </Typography>
            </Grid>

            {
                data.map((item, index) => <Fragment key={item._id} >
                    <Grid item xs={6} sx={{ background: index % 2 == 0 ? theme.palette.grey.main : theme.palette.light.main }} >
                        <Typography variant="subtitle1" align="center" >
                            {item.invoice_no}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ background: index % 2 == 0 ? theme.palette.grey.main : theme.palette.light.main }} >
                        <Typography variant="subtitle1" align="center" >
                            {item.amount - item.paid_amount}
                        </Typography>
                    </Grid>
                </Fragment>)
            }

            <Grid item xs={6} sx={{ background: theme.palette.secondary.main, borderTop: "1px solid black" }} ><Typography variant="subtitle1" align="right" >
                Total Amount:</Typography></Grid>
            <Grid item xs={6} sx={{ background: theme.palette.secondary.main, borderTop: "1px solid black" }}>
                <Typography variant="subtitle1" align="center" >
                    {data.reduce((currVal, item) => ((item.amount - item.paid_amount) + currVal), 0)?.toFixed(2)}
                </Typography>
            </Grid>
        </Grid>
    </Box>
})

const BulkCreateUi = ({ title, isUpdate, fields, setFields, loading, onSubmit, isDefaultInvoices }) => {
    const { user } = useSelector(state => state)


    return <>

        <CustomDialog

            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Create"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Create"}`}
        >

            {loading && <CenteredBox><CircularProgress /></CenteredBox>}
            <>


                {!isUpdate && fields.type != INVOICE_TYPE.DEPOSIT && <CustomInput

                    disabled={loading}
                    value={fields.customer_name}
                    onChange={(e) => setFields({ ...fields, err: '', customer_name: e.target.value })}
                    type="text"
                    label={"Invoice Of*"}

                />}

                {<Autocomplete
                    disabled={loading}
                    disableClearable
                    value={findObjectKeyByValue(fields.invoice_type, BULK_INVOICE_TYPE)}
                    onChange={(e, newVal) => {
                        setFields({ ...fields, invoice_type: newVal ? newVal._id : null, installer_id: null, invoices: [] })
                    }}
                    options={Object.keys(BULK_INVOICE_TYPE).map((item) => ({ label: titleCase(item), _id: BULK_INVOICE_TYPE[item] }))}
                    sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                    renderInput={(params) => <CustomInput placeholder="Select Type*" {...params} label="Select Type*" />}
                />}


                {fields.invoice_type == INVOICE_TYPE.ELECTRICIAN_INVOICE && <AsyncDropDown
                    disabled={loading}

                    value={fields.installer_id}
                    key={fields.invoice_type}

                    lazyFun={async (params) => { return await getInstallerApi({ ...params,onlyValid: true }) }}
                    label="Select Installer*"
                    OptionComponent={({ option, ...rest }) => {
                        return <MenuItem {...rest}>{option.name} </MenuItem>
                    }}
                    onChange={async (changedVal) => { setFields({ ...fields, installer_id: changedVal ?? null, invoices: isDefaultInvoices ? [...fields.invoices] : [] }) }}
                    titleKey={'name'}
                    valueKey={"_id"}
                    margin="none"
                />}

                {fields.invoice_type == INVOICE_TYPE.SUPPLIER_INVOICE && <AsyncDropDown
                    disabled={loading}

                    value={fields.supplier_id}

                    lazyFun={async (params) => { return await getUserApi({ ...params, role: USER_ROLES.supplier }) }}
                    label="Select Supplier*"
                    OptionComponent={({ option, ...rest }) => {
                        return <MenuItem {...rest}>{option.name} </MenuItem>
                    }}
                    onChange={async (changedVal) => { setFields({ ...fields, supplier_id: changedVal ?? null, invoices: isDefaultInvoices ? [...fields.invoices] : [] }) }}
                    titleKey={'name'}
                    valueKey={"_id"}
                    margin="none"
                />}
                {(fields.invoice_type != INVOICE_TYPE.ELECTRICIAN_INVOICE || (fields.invoice_type == INVOICE_TYPE.ELECTRICIAN_INVOICE && fields.installer_id && fields.installer_id != '')) &&

                    <AsyncDropDown
                        id={'invoice-list-bulk-create'}
                        disabled={loading}
                        multiple
                        key={fields.invoice_type + (fields.installer_id)}
                        defaultVal={fields.invoices}
                        lazyFun={async (params) => { return await getInvoiceData({ ...params, status: INVOICE_STATUS.PENDING, type: fields.invoice_type, installer_id: fields.installer_id?._id ?? null, supplier_id: fields.supplier_id?._id ?? null, searchable: ['customer_name', 'invoice_no'] }) }}
                        label="Select Invoices*"
                        OptionComponent={({ option, ...rest }) => {
                            return <MenuItem {...rest}>{option.invoice_no}({option.customer_name}) </MenuItem>
                        }}
                        onChange={async (changedVal) => { setFields({ ...fields, invoices: changedVal ?? [] }) }}
                        titleKey={'invoice_no'}
                        valueKey={"_id"}
                        margin="none"
                    />}
                <TableTotal data={fields.invoices} />
                {
                    <FileInput
                        disabled={loading}
                        accept=".pdf"
                        title="Add Receipt"
                        subTitle="Only .pdf are required less than 2mb."
                        onChange={(newUrl) => {
                            setFields({ ...fields, custom_invoice: newUrl })
                        }}

                    />
                }




                {!INVOICE_TYPE_LIST.find((item) => item.value == fields.invoice_type)?.debit &&
                    <>

                        <Autocomplete
                            disableClearable
                            value={findObjectKeyByValue(fields.payment_by, PAYMENT_OPTION)}
                            onChange={(e, newVal) => {
                                setFields({ ...fields, payment_by: newVal ? newVal._id : null })
                            }}
                            options={[...Object.keys(PAYMENT_OPTION).filter((item)=>PAYMENT_OPTION[item]!=PAYMENT_OPTION.LOSS).map((key) => ({ label: titleCase(key), _id: PAYMENT_OPTION[key] }))]}
                            sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                            renderInput={(params) => <CustomInput placeholder="Select Payment Option*" {...params} label="Select Payment Option*" />}
                        />
                        <MobileDatePicker
                            inputFormat="DD/MM/YYYY"
                            renderInput={(props) => <CustomInput {...props} />}
                            label="Invoice Payment Date*"
                            value={fields.payment_date}
                            onChange={(changedVal) => { setFields({ ...fields, payment_date: changedVal }) }}
                        />

                        <CustomInput

                            disabled={loading}
                            value={fields.transaction_id}
                            onChange={(e) => setFields({ ...fields, err: '', transaction_id: e.target.value })}
                            type="text"
                            label={"Transaction Id*"}

                        />
                    </>
                }

            </>

        </CustomDialog>
    </>
}
export default memo(BulkCreateUi)