import axios from 'axios'
import { refreshToken } from '../utils/helpers/helper'
import { getHeaders } from '../utils/helpers/helper'
import endpoints from './endpoints'

export const fetchHolidays = async (params={}) => {
  
  const callResponse = await axios({
    url: endpoints.holiday,
    method: 'get',
    headers: getHeaders(),
    params
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}




export const createHoliday = async (data) => {
  const callResponse = await axios({
    url: endpoints.holiday,
    method: 'post',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}

export const deleteHoliday = async (data) => {
  const callResponse = await axios({
    url: endpoints.holiday,
    method: 'delete',
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => ({status:0,response:err.response,code:err.response.status}))

  return callResponse
}
