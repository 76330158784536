import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserByIdApi, updateUserField } from '../../apis/user.api'
import { callApiAction } from '../../store/actions/commonAction'
import { loggedInUser } from '../../utils/helpers/helper'
import HolidayUI from './HolidayUI'
import {
  createHoliday,
  fetchHolidays,
  deleteHoliday,
} from '../../apis/holiday.api'
import moment from 'moment'
import DeleteHolidayButton from './DeleteHolidayButton'





const HolidayController = ({ userId }) => {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const [state, setState] = useState([])
  const [loading, setLoading] = useState(false)
  const [fields, setFields] = useState({ year: moment(),pageNo:1,pageSize:25,paginated:true })




  const columns = useMemo(() => [
    { id: 1, fieldName: 'name', label: 'Name', align: "left", sort: true, minWidth: '150px' },
    { id: 2, fieldName: 'date', label: 'Date', align: "left", sort: true, minWidth: '150px',renderValue:(params)=>moment(params.date).format("DD/MM/YYYY") },
    { id: 3, fieldName: 'delete', label: 'Date', align: "left",renderValue:(params,setParams)=><DeleteHolidayButton params={params} setParams={setParams} /> },
  ], [dispatch]);


  const fetchHolidaysApi = fetchHolidays
  const deleteHolidayApi = deleteHoliday


  const fetchList = () => {
    setLoading(true)
    dispatch(
      callApiAction(
        async () => await fetchHolidaysApi({ ...fields,date: fields.year.valueOf() }),
        (response) => {
          setState(response)
          setLoading(false)
        },
        (err) => {
          setLoading(false)
        },
      ),
    )
  }

  const DeleteHoliday = (id) => {

    setLoading(true)
    dispatch(
      callApiAction(
        async () => await deleteHolidayApi({ id }),
        (response) => {
          setLoading(false)
          window.location.reload(true)
          
        },
        (err) => {
          setLoading(false)
           
        },
      ),
    )
  }

  useEffect(() => {
    fetchList()
  }, [fields])

  return (
    <HolidayUI
      setFields={setFields}
      fields={fields}
      setState={setState}
      loading={loading}
      callBack={fetchList}
      state={state}
      DeleteHoliday={DeleteHoliday}
      columns={columns}
    />
  )
}
export default HolidayController
