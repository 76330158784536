
import { Paper, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { useSelector } from "react-redux"

import DataTable from "../../components/tables/DataTable"
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop"
import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector"
import AsynSearchBar from "../../components/inputs/AsynSearchBar"
import SubmitButton from "../../components/button/SubmitButton"




const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const ListUi = ({ title,modal, filters, setFilters, list, onCreateBtnClick, loading, columns }) => {

    const user = useSelector(state => state.user)

    return (
        <>

            <Box mb={3}>
                <Paper elevation={modal?0:2} sx={{ width: "100%", padding: modal?0:4 }} >
                    <Box  >
                      {!modal &&  <FilterTitleBox mb={4}>
                            <Typography variant="h5" mb={2} >{title}</Typography>

                        </FilterTitleBox>}
                        <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: 'flex-end' }} pl={3} >

                            <Box>
                                {<SubmitButton onClick={onCreateBtnClick} title="Add Expense" >

                                </SubmitButton>}
                            </Box>
                        </PaddingBoxInDesktop>

                        {!modal && <FiltersBox mt={3} p={2} >


                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }} pl={2} >


                                <TimeRangeSelector defaultVal={{ label: "This Month", _id: 'this_month' }} onChange={(newRange) => { setFilters({ ...filters, ...newRange }) }} />

                            </PaddingBoxInDesktop>
                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }} pl={3} >


                                <AsynSearchBar
                                    fullWidth
                                    title="Search by Title "
                                    size="small"
                                    placeholder={"Search Title"}
                                    defaultValue={filters.search}
                                    onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
                                />
                            </PaddingBoxInDesktop>

                        </FiltersBox>}


                    </Box>

                    <Box sx={{ minHeight: "300px" }} p={2}>
                        <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                    </Box>
                </Paper >
            </Box >

        </>
    )
}
export default ListUi