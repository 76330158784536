import { CircularProgress,  } from "@mui/material"

import {  memo } from "react"
import {  useSelector } from 'react-redux'
import CustomInput from "../../components/inputs/CustomInput"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"




const CreateUi = ({ title, isUpdate, modalKey, fields, setFields, loading, onSubmit }) => {
    const { user } = useSelector(state => state)


    return <>

        <CustomDialog
            id={modalKey}
            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Add"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Add"}`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    <CustomInput

                        disabled={loading}
                        value={fields.title}
                        onChange={(e) => setFields({ ...fields, err: '', title: e.target.value })}

                        label={"Expense Title*"}

                    />
                    <CustomInput

                        disabled={loading}
                        value={fields.amount}
                        onChange={(e) => setFields({ ...fields, err: '', amount: e.target.value })}
                        type="number"
                        label={"Amount*"}

                    />

                   

                </>}

        </CustomDialog >
    </>
}
export default memo(CreateUi)