import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction"
import { getProjectCountsApi } from "../../apis/projects.api"
import { Box, Button, ButtonGroup, Grid, Skeleton } from "@mui/material"
import ClickButton from "../../components/button/ClickButton"
import { findObjectKeyByValue } from "../../utils/helpers/helper"
import { PROJECT_STATUS } from "../../utils/constants/project.constant"
import { getComplainCountsApi } from "../../apis/complain.api"

const ComplainCounts = ({ filters, setFilters }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState()
    const [data, setData] = useState([])
    
    const fetchCounts = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await getComplainCountsApi({ ...filters }),
            (response) => {
                setData(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    useEffect(() => {
        fetchCounts()
    }, [filters.category,filters.subCategory,filters.startDate,filters.endDate])

    if (loading)
        return <Box>
            <Grid container spacing={2} >
                {[1, 2, 3].map((item) => <Grid key={item?.val} item xs={12 / 7} ><Skeleton sx={{ height: "80px" }} variant="rounded" /></Grid>)}

            </Grid>
        </Box>
    return <>
        <Grid container spacing={2} >
            {data?.map((item) => <Grid key={item?.val} item xs={4} md={12 / data?.length} >
                <ClickButton onClick={() => {
                 
                    setFilters({ ...filters,  status: item?.status })
                }}
                    active={filters?.status == item?.status} title={`${item?.count}`} subTitle={item?.title} variant="rounded" />
            </Grid>)}
        </Grid>
     



    </>
}
export default ComplainCounts