import { Box, Button, ButtonBase, CircularProgress, Divider, FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, Typography } from "@mui/material"

import { useDispatch, useSelector } from 'react-redux'
import CustomInput from "../../components/inputs/CustomInput"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"

import getSourceApi from "../../apis/sources.api"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { DesktopDatePicker } from "@mui/x-date-pickers"
import moment from "moment"
import { closeModal, openModal } from "../../store/actions/modalAction"

import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import ListController from "../predefined-expense/ListController"
import { memo } from "react"
import CreatableAsyncDropDown from "../../components/inputs/CreatableAsyncDropDown"
import { getSuggestionForExpenseTitleApi } from "../../apis/finance.api"




const CreateUi = ({ title, isUpdate, modalKey, fields, setFields, loading, onSubmit, callBack }) => {
    
    const dispatch = useDispatch()
    const onPreDefinedExpenseModalClick = () => {
        dispatch(closeModal(modalKey))
        dispatch(openModal(<ListController callBack={callBack} />, 'sm', undefined, MODAL_KEYS.PRE_DEFINED_EXPENSES_LIST))
    }
    return <>

        <CustomDialog
            id={modalKey}
            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Add"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Add"}`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    {!isUpdate && <Box display="flex" alignItems="center">

                        <Box display="flex" flex={3}>
                            <FormControl>
                                <FormLabel id="expenseType">Expense Type</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="expenseType"
                                    name="controlled-radio-buttons-group"
                                    value={fields.selection}
                                    onChange={(e) => setFields({ ...fields, source: null, selection: e.target.value, title: e.target.value == "Other" ? "" : e.target.value })}
                                >
                                    <FormControlLabel value="Marketing" control={<Radio />} label="Marketing" />
                                    <FormControlLabel value="Incentive" control={<Radio />} label="Sales Incentive" />
                                    <FormControlLabel value="Other" control={<Radio />} label="Other" />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                        <Box display="flex" flex={1}>
                            <Divider color="primary">
                                OR
                            </Divider>
                        </Box>
                        <Box display="flex" flex={2} justifyContent="flex-end">
                            <Box>
                                <ButtonBase onClick={onPreDefinedExpenseModalClick}>
                                    <Typography sx={{ textDecoration: "underline" }} variant="caption" color="info" >Add From Predfined Expense</Typography>
                                </ButtonBase>
                            </Box>
                        </Box>
                    </Box>}
                    {fields.selection == 'Other' &&

                        //        <CustomInput

                        //        disabled={loading}
                        //        value={fields.title}
                        //        onChange={(e) => setFields({ ...fields, err: '', title: e.target.value })}

                        //        label={"Expense Title*"}

                        //    />

                        <CreatableAsyncDropDown
                            lazyFun={async (params) => { return await getSuggestionForExpenseTitleApi({ ...params }) }}
                            value={fields.title}
                            OptionComponent={({ option, ...rest }) => {
                                return <MenuItem {...rest}>{option.title}</MenuItem>
                            }}
                            onChange={async (changedVal) => { setFields({ ...fields, title: changedVal }) }}
                            titleKey={'title'}
                            valueKey={"_id"}
                            InputComponent={(params) => <CustomInput{...params} label="Expense Title" />}

                            margin={0}
                        />
                    }
                    <CustomInput

                        disabled={loading}
                        value={fields.amount}
                        onChange={(e) => setFields({ ...fields, err: '', amount: e.target.value })}
                        type="number"
                        label={"Amount*"}

                    />

                    <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        renderInput={(props) => <CustomInput {...props} />}
                        label="Expense Date*"
                        value={fields.date ? moment(fields.date) : null}
                        onChange={(changedVal) => { setFields({ ...fields, date: changedVal?.toISOString() ?? null }) }}
                    />
                    {!isUpdate && fields.selection == 'Marketing' && <AsyncDropDown

                        lazyFun={async (params) => { return await getSourceApi({ ...params }) }}

                        OptionComponent={({ option, ...rest }) => {
                            return <MenuItem {...rest}>{option.name}</MenuItem>
                        }}
                        onChange={async (changedVal) => { setFields({ ...fields, source: changedVal ? changedVal._id : null }) }}
                        titleKey={'name'}
                        valueKey={"_id"}
                        InputComponent={(params) => <CustomInput{...params} label="Select Source" />}

                        margin={0}
                    />
                    }

                </>}

        </CustomDialog >
    </>
}
export default memo(CreateUi)