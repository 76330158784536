import {  Box, CircularProgress, MenuItem} from "@mui/material"

import { memo } from "react"
import { useSelector } from 'react-redux'
import CustomInput from "../../components/inputs/CustomInput"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"

import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { getUserApi } from "../../apis/user.api"





const ChatGroupCreateUi = ({ title, isUpdate, fields, setFields, loading, onSubmit }) => {
    const { user } = useSelector(state => state)


    return <>

        <CustomDialog

            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Create"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Create"}`}
        >


            {loading && <CenteredBox><CircularProgress /></CenteredBox>}
            {<>
                <Box mt={2} />
                <CustomInput
                    autoFocus={true}
                    disabled={loading}
                    value={fields.name}
                    onChange={(e) => setFields({ ...fields, err: '', name: e.target.value })}
                    type="text"
                    label={"Group Name*"}
                    margin="none"
                    helperText="Group name must only contains number & Characters with maximum length of 30 character"

                />
               
               <AsyncDropDown
                        disabled={loading}
                        multiple
                        id="select-users"

                        lazyFun={async (params) => { return await getUserApi({ ...params,isDropDown:null}) }}
                        label="Select Users*"
                        OptionComponent={({ option, ...rest }) => {
                            return <MenuItem {...rest}>{option.name}</MenuItem>
                        }}
                        onChange={async (changedVal) => { setFields({ ...fields, members: changedVal ?? [] }) }}
                        titleKey={'name'}
                        valueKey={"_id"}
                        margin="none"
                    />

            </>}

        </CustomDialog>
    </>
}
export default memo(ChatGroupCreateUi)