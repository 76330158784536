import { Chip, useTheme } from "@mui/material"
import {  TASK_STATUS } from "../../../utils/constants/task.constants"
import { findObjectKeyByValue } from "../../../utils/helpers/helper"
import {  useState } from "react"
import SubmitButton from "../../../components/button/SubmitButton"
import { updateTaskStatusField } from "../../../apis/task.api"
import { useDispatch } from "react-redux"
import { callApiAction } from "../../../store/actions/commonAction"
import { callSnackBar } from "../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../utils/constants/constants"


const StatusComponent = ({ status,id }) => {
const dispatch = useDispatch()
    const [loading,setLoading] = useState(false)
    const [currentStatus ,setCurrentStatus] = useState(status)
    const theme = useTheme()
    let color = 'default'

    if (currentStatus == TASK_STATUS.COMPLETED) {
        color = 'success'
    }
const onCompleteClick = ()=>{
    setLoading(true)
    dispatch(
        callApiAction(
            async () => await updateTaskStatusField({ id ,status:TASK_STATUS.COMPLETED}),
            async (response) => {


              setCurrentStatus(TASK_STATUS.COMPLETED)
                setLoading(false)
                dispatch(callSnackBar("Task MArked As Completed Successfully",SNACK_BAR_VARIETNS.suceess))
            },
            (err) => {
                dispatch(callSnackBar(err,SNACK_BAR_VARIETNS.error))
                setLoading(false)

            }
        )
    )
}

    if(currentStatus==TASK_STATUS.PENDING){
        return <SubmitButton onClick={onCompleteClick} title="Mark As Complete" loading={loading} ></SubmitButton>
    }

    return <Chip size="small" color={color} label={findObjectKeyByValue(currentStatus, TASK_STATUS)} />
}
export default StatusComponent