import { Button } from "@mui/material"
import { PROJECT_STATUS } from "../../../utils/constants/project.constant"
import { useDispatch } from "react-redux"
import { openModal } from "../../../store/actions/modalAction"
import CustomerHoldMapController from "./CustomerHoldMapController"
import { Map } from "@mui/icons-material"

const MapOpenButton = ({ status }) => {
    const dispatch = useDispatch()
    const onClick = () => {
        dispatch(openModal(<CustomerHoldMapController />, "lg"))
    }
    if (status == PROJECT_STATUS.INSTALLATION_CONFIRMATION_CONFIRMED || status == PROJECT_STATUS.INSTALLATION_CONFIRMATION_HOLD_BY_CUSTOMER)
        return <Button color="info" startIcon={<Map />} variant="contained" disableElevation  onClick={onClick}>
            Open Map
        </Button>

    return <></>
}
export default MapOpenButton