import { actions } from "../../utils/constants/constants";
import { chatgroupsLastReadMessages } from "../../utils/helpers/helper";




const initialState = {
    connected: false,
    socket: null,

    groups: {
        data: []
    },
    activeGroupId: null,
    hasNewMessage: false,
    loading: false,
    scrollId: null,
    scrollValue: 0,
    chats: {
        'group_id': {

            lastMessages: null,
            groupDetails: {},
            moreAvailable: false,
            data: [

            ],
            unreadMessages: [

            ]

        }
    }


}
const chatReducer = (state = initialState, action) => {

    switch (action.type) {
        case actions.SOCKET_CONNECT: return { ...state, socket: action.socket, connected: true };
        case actions.SOCKET_CONNECT_WTH_GROUPS: return { ...state, socket: action.socket, groups: { data: action.groups }, connected: true };
        case actions.SOCKET_DISCONNECT: return { ...state, socket: null, connected: false };

        case actions.CREATE_CHAT_GROUP: {
            const groups = [...state.groups.data]
            const newGroup = action.new_group

            groups.unshift(newGroup)

            return {
                ...state, groups: {
                    data: groups
                }
            }
        };

        case actions.GROUP_MEMBER_REMOVED: {

            const { groupId } = action
            const { chats, groups } = {
                ...state
            }
            const chatCopy = { ...chats }

            if (chatCopy[groupId])
                delete chatCopy[groupId]

            const groupsData = [...groups?.data]

            const index = groupsData.findIndex((item) => item?._id == groupId)
            if (index !== -1)
                groupsData.splice(index, 1)

            return {
                ...state, chats: chatCopy, groups: {
                    data: groupsData,
                  
                },
                activeGroupId: state?.activeGroupId == groupId ? null : state?.activeGroupId
            }


        }

        case actions.ADDED_TO_GROUP: {

            const { groupData } = action
            const { groups } = {
                ...state
            }


            const groupsData = [...groups?.data]

            groupsData.unshift(groupData)

            return {
                ...state,hasNewMessage:true, groups: {
                    data: groupsData

                }
            }


        }
        case actions.ADD_GROUP_CHAT_DATA: {

            const { data, moreAvailable, unreadData, groupData, groupId } = action

            const chats = { ...state.chats }
            chats[groupId] = {
                lastMessages: data[0],
                groupDetails: groupData,
                moreAvailable: moreAvailable,
                data,
                unreadMessages: unreadData
            }

            return { ...state, loading: false, chats }

        };
        case actions.MARK_AS_READ_TO_ALL: {

            const { groupId } = action

            const chats = { ...state.chats }

            let existingChat = chats[groupId]
            if (!existingChat)
                return { ...state }

            existingChat = { ...chats[groupId] }
            chats[groupId] = {
                ...existingChat,
                data: existingChat.data?.concat(existingChat?.unreadMessages),
                unreadMessages: []
            }
            const lastMessage = existingChat?.unreadMessages[existingChat?.unreadMessages?.length - 1]

            if (lastMessage)
                chatgroupsLastReadMessages.set(groupId, lastMessage?._id, lastMessage?.time)
            return { ...state, chats }

        };
        case actions.ADD_MESSAGE: {

            const { groupId, message } = action


            const chats = { ...state.chats }

            const existingChat = chats[groupId]
            if (!existingChat)
                return { ...state }




            chats[groupId] = {
                ...existingChat,
                data: [...existingChat.data?.concat(...existingChat?.unreadMessages), message],
                unreadMessages: [],

            }



            const groups = [...state.groups.data]
            let index = 0
            for (let item of groups) {
                if (item._id == groupId) {
                    break;
                }

                index++
            }

            const groupData = groups[index]

            groupData['time'] = message.time
            groupData['last_message'] = message.message
            groupData['last_message_id'] = message._id

            groups[index] = groupData
            chatgroupsLastReadMessages.set(groupId, message._id, message.time)
            return {
                ...state,
                scrollId: Math.random(),
                scrollValue: 0,
                groups: { data: groups },
                chats
            }

        };


        case actions.MESSAGE_ARRIVED: {

            const { groupId, message } = action

            const chats = { ...state.chats }

            let existingChat = chats[groupId]


            if (!existingChat)
                chats[groupId] = {

                    lastMessages: null,
                    groupDetails: {},
                    moreAvailable: false,
                    data: [

                    ],
                    unreadMessages: [

                    ]
                }

            existingChat = chats[groupId]
            if (state.activeGroupId == groupId) {
                chats[groupId] = {
                    ...existingChat,
                    data: [...existingChat.data?.concat(existingChat?.unreadMessages), message],
                    unreadMessages: []
                }
                chatgroupsLastReadMessages.set(groupId, message._id, message.time)
            }
            else
                chats[groupId] = {
                    ...existingChat,
                    unreadMessages: [...existingChat.unreadMessages, message]
                }


            const groups = [...state.groups.data]
            let index = 0
            for (let item of groups) {
                if (item._id == groupId) {
                    break;
                }

                index++
            }

            const groupData = groups[index]

            groupData['time'] = message.time
            groupData['last_message'] = message.message
            groupData['last_message_id'] = message._id

            groups[index] = groupData

            return { ...state, groups: { data: groups }, chats, hasNewMessage: true }

        };


        case actions.MESSAGE_DELETED: {

            const { groupId, messageId } = action

            const chats = { ...state.chats }
            if (chats[groupId]) {
                const groupData = chats[groupId]


                const chatData = groupData?.data ?? []

                const undreadData = groupData?.unreadMessages ?? []

                const chatDataIndex = chatData?.findIndex((item) => item?._id == messageId)
                if (chatDataIndex != -1)
                    chatData.splice(chatDataIndex, 1)
                else {
                    const undreadDataIndex = undreadData?.findIndex((item) => item?._id == messageId)
                    if (undreadDataIndex != -1)
                        undreadData.splice(undreadDataIndex, 1)
                }


            }



            return { ...state, chats }

        };



        case actions.MARK_NEW_MESSAGE_AS_READ: return { ...state, hasNewMessage: false }

        case actions.MARK_NEW_MESSAGE_AS_UNREAD: return { ...state, hasNewMessage: true }
        case actions.CHANGE_ACTIVE_GROUP: return { ...state, activeGroupId: action.groupId, loading: action.loading }
        case actions.GROUP_CHAT_LOADING: return { ...state, loading: action.value }
        default: return { ...state }
    }

}
export default chatReducer