import axios from "axios";
import { getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";

export const getAutoComplete = async params => {
    const callResponse = await axios({
      url: endpoints.getautoComplete,
      method: "get",
      headers: getHeaders(),
      params,
    })
      .then(response => response.data)
      .catch(err => err.response.data);
  
    return callResponse;
  };

export const getLatAndLon = async params => {
    const callResponse = await axios({
      url: endpoints.getlatandLon,
      method: "get",
      headers: getHeaders(),
      params,
    })
      .then(response => response.data)
      .catch(err => err.response.data);
  
    return callResponse;
  };