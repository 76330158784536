import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import CreateUi from "./CreateUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { closeModal } from "../../store/actions/modalAction"
import { useParams } from "react-router-dom"

import { addSourceApi, getSourceByIdApi, updateSourceField } from "../../apis/sources.api"
import { addLoanBenefitApi, getLoanBenefitByIdApi, updateLoanBenefitField } from "../../apis/loanbenefit.api"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import { addComplainCategoryApi } from "../../apis/complaincategory.api"

const CreateController = ({ callBack, parent }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    

    const modalKey = MODAL_KEYS.COMPLAIN_CATEGORY_CREATE
    const title =parent && parent!=''? " Complain Sub Category " :" Complain Category "
    const createApi = addComplainCategoryApi
    

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        parent,
        title: ''        

    })
 


    const validationSchemaForCreate = useMemo(() => ([

        {
            required: true,
            value: fields.title,
            field: 'Title',
        }
    ]), [fields])

   



    const createFunction = async () => {
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createApi(fields),
                    async (response) => {

                        await callBack(response)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }


   


    const onSubmit = async (e) => {
        e.preventDefault()
        createFunction()

    }

   



    return <CreateUi modalKey={modalKey} title={title}  loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(CreateController)