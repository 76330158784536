import axios from "axios";
import { getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";




export const getComplainCategoryApi = async params => {
  const callResponse = await axios({
    url: endpoints.complainCategory,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};




export const addComplainCategoryApi = async data => {
  const callResponse = await axios({
    url: endpoints.complainCategory,
    method: "POST",
    headers: getHeaders(),
    
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};



export const deleteComplainCategoryField = async data => {
  const callResponse = await axios({
    url: endpoints.complainCategory,
    method: "DELETE",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export default getComplainCategoryApi