import { Paper, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { useSelector } from "react-redux"
import DataTable from "../../../../components/tables/DataTable"
import PaddingBoxInDesktop from "../../../../components/layouts/PaddingBoxInDesktop"
import AsynSearchBar from "../../../../components/inputs/AsynSearchBar"
import CustomDialog from "../../../../components/layouts/common/CustomDialog"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const ListUi = ({ title, filters, setFilters, list, modalKey, loading, columns }) => {

    const user = useSelector(state => state.user)

    return (
        <>
            <CustomDialog
            title={title}
            id={modalKey}
            >

                <Box sx={{}}>
                    <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                </Box>
            </CustomDialog>

        </>
    )
}
export default ListUi