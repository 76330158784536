import { useTheme } from "@mui/material"
import { INVOICE_STATUS } from "../../../utils/constants/invoice.constant"

const useInovice = (invoice) => {
    const theme = useTheme()

    const obj = {
        color: theme.palette.error.main,
        bg: theme.palette.error.main,
        isVarificationPending: false
    }
    if (invoice.status == INVOICE_STATUS.PAID) {
        obj['color'] = theme.palette.success.main
        obj['bg'] = theme.palette.success.main
    }

    if(invoice.payments && Array.isArray(invoice.payments) ){
        invoice.payments.forEach((item)=>{
            if(item.verified===false){
                obj['isVarificationPending'] = true

                obj['color'] = theme.palette.warning.main
                obj['bg'] =theme.palette.warning.main

            }
        })
    }

  


    return obj


}
export default useInovice