import { createContext } from "react";
import modulesConfig from "../../config/modules.config";

export const ModuleAccessContext = createContext([])

export const moduleAccessAction = {
    setModules: (modules) => {

        return { type: "SET_MODULE", modules }
    },
    selectModule: (moduleValue, value, role, children, dependentOn) => {
        return { type: "SELECT_MODULE", moduleValue, value, role, children, dependentOn }
    },
    resetModule: (role) => {
        return { type: "RESET_TO_DEFAULT_MODULE", role }
    }
}

export const indentifyAndCollectModulesFromChildren = (role, children = [],value) => {

    return children?.filter(item => !item.not_allowed || item.not_allowed?.length == 0 || (item?.not_allowed?.length > 0 && !item?.not_allowed?.includes(role)))
        ?.map(item => {
            if (!item?.children || item?.children?.length == 0) {
                const dependentmodules = [item.value]

                if (value===true && Array.isArray(item?.dependent_on) && item?.dependent_on?.length > 0) {
                    item?.dependent_on?.forEach((item) => dependentmodules.push(item))
                }
                return dependentmodules
            }
            else
                return indentifyAndCollectModulesFromChildren(role, item?.children,value)




        })?.flat(2)
}
export const getRecommendedModulesList = (role, array = []) => {

    return array
        ?.filter(item => (typeof item.recommended == "string" && item.recommended === "*" && !(item?.not_allowed?.includes?.(role))) || (!item.recommended && item?.children?.length > 0) || (Array.isArray(item.recommended) && item.recommended?.includes(role)))
        ?.map(item => {

            const arrayToBeReturned = []

            if (item.value) {
                arrayToBeReturned.push(item.value)
            }
            if (item?.children && item?.children?.length > 0) {
                const subArray = getRecommendedModulesList(role, item?.children)
                for (let subItems of subArray) {
                    arrayToBeReturned.push(subItems)
                }
            }
            return arrayToBeReturned
        })?.flat(2)
}
export const moduleContextReducer = (state, action) => {

    switch (action.type) {
        case "SET_MODULE": {
            const { modules } = action

            return modules ?? []
        }
        case "RESET_TO_DEFAULT_MODULE": {

            const { role } = action

            const modulesToBeChanged = getRecommendedModulesList(role, modulesConfig)


            return [...modulesToBeChanged]
        }
        case "SELECT_MODULE":
            const modules = [...state]
            const { moduleValue, value, role, children, dependentOn } = action

            const modulesToBeChanged = []
            if (moduleValue)
                modulesToBeChanged.push(moduleValue)

            if (value === true && Array.isArray(dependentOn) && dependentOn?.length > 0) {
                dependentOn.forEach((item) => modulesToBeChanged.push(item))
            }

            if (children && children.length > 0) {
                const subModules = indentifyAndCollectModulesFromChildren(role, children,value) ?? []

                subModules.forEach((item) => modulesToBeChanged.push(item))


            }

            //finding module if not exist and value is true then adding 
            // else if module is exist and value is false then removing other vise no action

            for (let item of modulesToBeChanged) {
                if (!modules.includes(item) && value === true) {
                    modules.push(item)
                }
                if (modules.includes(item) && value !== true) {
                    const index = modules.indexOf(item)
                    if (index !== -1)
                        modules.splice(index, 1)

                }
            }


            return [...modules]
        default: return [...state]
    }

}