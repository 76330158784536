import { createContext, memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { sendMessage } from "../../store/actions/chatAction"
import moment from "moment"
import { CHAT_TYPES } from "../../utils/constants/chat.constant"
import { Box, CircularProgress, IconButton, LinearProgress, Typography, Zoom, useTheme } from "@mui/material"
import { StyledSearchBar } from "../../components/inputs/SearchBar"
import { Cancel, Image, Send } from "@mui/icons-material"
import { FileContext } from "../../components/inputs/FileInput"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import ImageComponent from "../../components/ImageComponent"

const ChatContext = createContext({
    setType: () => { },
    str: '',
    type: CHAT_TYPES.TEXT,
    setStr: () => { },
    setUrl: () => { },
    url: null,
    onSubmit: () => { }
})
export const ChatInputWrapper = ({ ...props }) => {

    const theme = useTheme()
    const dispatch = useDispatch()
    const { chat, user } = useSelector(state => state)
    const [str, setStr] = useState('')
    const [url, setUrl] = useState(null)
    const [type, setType] = useState(CHAT_TYPES.TEXT)

    const onSubmit = useCallback((e, callBack = () => { }) => {
        e.preventDefault()
        if (str?.trim() != '') {
            dispatch(sendMessage(chat.activeGroupId, {
                messageType: type,
                message: str,
                url,
                time: moment().toISOString(),
                user_id: user.data._id,
                name: user.data.name,
                group: chat.activeGroupId,

            }, () => {

            }))
            setStr('')
            setUrl(null)
            setType(CHAT_TYPES.TEXT)
            callBack()
        }
    }, [str, url, setUrl, type, dispatch, chat.activeGroupId])



    const value = useMemo(() => ({
        setType,
        str,
        type,
        setStr,
        setUrl,
        url,
        onSubmit,
    }), [onSubmit, setStr, setType,])


    return <ChatContext.Provider value={value} >
        {props.children}
    </ChatContext.Provider>

}
export const ChatInput = memo(() => {
    const theme = useTheme()


    const [isShiftPressed, setShiftPressed] = useState(false)

    const { chat } = useSelector(state => state)

    const ref = useRef()

    const { str, setStr, onSubmit } = useContext(ChatContext)
    const { onReset } = useContext(FileContext)
    const submitFun = (e) => {
        onSubmit(e, () => {
            onReset()
        })

    }
    if (!chat.activeGroupId)
        return <></>
    return <Box ref={ref} component="form" onSubmit={submitFun} sx={{ display: "flex", background: theme.palette.primary.main }} p={2}>

        <MediaInput />

        <StyledSearchBar inputProps={{}}

            onKeyUp={(e) => {
                if (e.key == 'Shift') {
                    setShiftPressed(false)
                }
            }}
            onKeyDown={(e) => {

                if (e.key == 'Shift') {
                    setShiftPressed(true)
                }
                if (e.key == "Enter" && !isShiftPressed) {
                    submitFun(e)
                }

            }} multiline maxRows={2} value={str} onChange={(e) => { setStr(e.target.value) }} fullWidth size="small" placeholder="Write a message" margin="none" sx={{ background: "white" }} />
        {<Zoom in={str && str.trim() != ''}>
            <IconButton type="submit" ><Send color="light" /></IconButton>
        </Zoom>}
    </Box>
})
export const MediaViewer = memo(() => {
    const [deleteLoading, setDeleteLoading] = useState(false)
    const { onDeleteFile,
        files,
        onReset,
        loading,
        percentage } = useContext(FileContext)

    const { setType, setUrl, url } = useContext(ChatContext)
    const theme = useTheme()

    const onDelete = () => {
        setDeleteLoading(true)
        onDeleteFile(files?.[0], () => {
            setDeleteLoading(false);
            setUrl(null);
            setType(CHAT_TYPES.TEXT)
        })
    }

    useEffect(() => {
        if (files.length > 0) {
            setUrl(files[0]);
            const extension = files[0]?.split?.('.')
            const extensionName = extension[extension.length - 1]
            setType((extensionName == "jpg" || extensionName == "jpeg" || extensionName == "png" || extensionName == "svg") ? CHAT_TYPES.IMAGE : CHAT_TYPES.FILES)
        }
    }, [files])

    if (loading)
        return <Box sx={{ position: "absolute", top: 0, left: 0, height: "100%", width: "100%", zIndex: 111, background: "white", overflow: "hidden" }} >
            <CenteredBox sx={{ background: theme.palette.secondary.main, border: 1, borderColor: theme.palette.primary.main, minHeight: "50px" }} p={2}>
                <LinearProgress variant="determinate" value={percentage} sx={{ width: "100%" }} />
            </CenteredBox>
        </Box>
    if (files.length > 0) {
        return <Box sx={{ position: "absolute", top: 0, left: 0, height: "100%", width: "100%", zIndex: 111, background: "white", overflow: "hidden" }} >
            {


                <Box sx={{ height: "100%", display: "flex", flexDirection: "column", background: theme.palette.secondary.main }} >
                    <CenteredBox px={2} sx={{ flex: 0, justifyContent: "space-between" }} >

                        <Typography variant="caption" >
                            {files[0]}
                        </Typography>
                        {!deleteLoading && <IconButton onClick={onDelete} >
                            <Cancel color="error" />
                        </IconButton>}
                        {
                            deleteLoading && <CircularProgress />
                        }
                    </CenteredBox>
                    <Box sx={{ display: "flex", flex: 1, overflow: "hidden" }} >
                        <Box sx={{ height: "100%", width: "100%" }} p={2} >
                            <ImageComponent sx={{ height: "100%", width: "100%", objectFit: "contain" }} src={files[0]} />
                        </Box>
                    </Box>


                </Box>
                // )
            }
        </Box>
    }
    return <></>
})
export const MediaInput = memo(() => {
    const { onDeleteFile,
        onChangeFile,
        files,
        loading,
        percentage } = useContext(FileContext)

    const { url, setUrl, setType } = useContext(ChatContext)

    // if(file)

    return <IconButton disabled={loading} >
        <input accept="image/*" onChange={onChangeFile} style={{ opacity: 0, position: "absolute", cursor: "pointer" }} type="file" />
        <Typography color="text.invert" lineHeight="100%">
            <Image color="inherit" />
        </Typography>
    </IconButton>
})
