import {
  Box,
  Button,
  Paper,
  Typography,
  styled,
} from '@mui/material'

import {

  Add,
} from '@mui/icons-material'
import AddHolidayDataController from './AddHolidayDataController'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { openModal } from '../../store/actions/modalAction'
import { DatePicker } from '@mui/x-date-pickers'
import CustomInput from '../../components/inputs/CustomInput'
import DataTable from '../../components/tables/DataTable'
import SubmitButton from '../../components/button/SubmitButton'

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down('md')]: {
    flexDirection: "column"
  }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down('md')]: {
    flexDirection: "column"
  }
}))
const HolidayUI = ({ setState, callBack, fields, setFields, loading, state, columns }) => {
  const dispatch = useDispatch()
  const addholiday = useCallback(() => {
    dispatch(openModal(<AddHolidayDataController callBack={callBack} />,"sm"))
  }, [])
  return (
    <>

      <Box mb={3}>
        <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
          <Box mb={4} >
            <FilterTitleBox>
              <Typography variant="h5" mb={2} >Holidays</Typography>

              <Box>
                <SubmitButton onClick={addholiday} title="Add Holiday" >

                </SubmitButton>
              </Box>
            </FilterTitleBox>


          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box>
              <DatePicker margin="dense" label="Year" renderInput={(props) => <CustomInput {...props} />} views={['year']} value={fields.year} onChange={(e) => setFields({ ...fields, year: e })} ></DatePicker>
            </Box>
          </Box>
          <Box sx={{ minHeight: "300px" }}>
            <DataTable columns={columns} rows={state.result} count={state?.total} filters={fields} setFilters={setFields} loading={loading} />
          </Box>
        </Paper>
      </Box>


    </>

  )
}
export default HolidayUI
