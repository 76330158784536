




import { memo } from "react"
import OneViewBox, { CenteredBox } from "./OneViewBox"
import { Typography } from "@mui/material"
import { Comment } from "@mui/icons-material"



const workAreaStyle = (theme) => ({
    display: "flex",
    width: "100%",
    flexDirection: "column",
    flex: 1,
    background: theme.palette.grey.main,
    borderTopLeftRadius: theme.shape.borderRadius * 1

})


const NoDataComponent = ({ message, Icon ,iconSize,variant="h4",iconVariant="h1"}) => {
    return <OneViewBox>
        <CenteredBox sx={{ flexDirection: "column" }}>
            <Typography variant={iconVariant}>
                {Icon ?<Icon color="primary" fontSize="inherit" {...iconSize} />: <Comment color="primary" fontSize="inherit" {...iconSize}  />}
            </Typography>
            <Typography variant={variant} fontWeight="bold" color="grey">
                {message ?? "Page Not Found"}
            </Typography>
        </CenteredBox>
    </OneViewBox>

}
export default memo(NoDataComponent)