import axios from "axios";
import { getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";




export const getComplainApi = async params => {
  const callResponse = await axios({
    url: endpoints.complainBase,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const getComplainCountsApi = async params => {
  const callResponse = await axios({
    url: endpoints.complainCounts,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const getComplainByIdApi = async params => {
  const callResponse = await axios({
    url: endpoints.complainById,
    method: "get",
    headers: getHeaders(),
    params,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};


export const addComplainApi = async data => {
  const callResponse = await axios({
    url: endpoints.complainBase,
    method: "POST",
    headers: getHeaders(),
    
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const addComplainNoteApi = async data => {
  const callResponse = await axios({
    url: endpoints.complainNotes,
    method: "POST",
    headers: getHeaders(),
    
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};

export const updateComplainStatus = async data => {
  const callResponse = await axios({
    url: endpoints.complainBase,
    method: "PATCH",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};
export const updateComplainData = async data => {
  const callResponse = await axios({
    url: endpoints.complainBase,
    method: "PUT",
    headers: getHeaders(),
    data,
  })
    .then(response => response.data)
    .catch(err => err.response.data);

  return callResponse;
};



export default getComplainApi