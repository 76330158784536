import { memo, useCallback, useEffect, useState } from "react"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { Box, Grid, styled, Skeleton, Typography, Paper, useTheme } from "@mui/material"
import { getInvoiceSummary } from "../../apis/invoice.api"

import { CenteredBox } from "../../components/layouts/OneViewBox"
import { RemoveCircle } from "@mui/icons-material"

const OuterBox = styled(Paper)(({ theme }) => ({
    border: "2px solid " + theme.palette.primary.main,
    background: theme.palette.secondary.main,
    overflow: "hidden"
}))

export const CountBox = ({ title, value, active, ...props }) => {
    return <CenteredBox flexDirection="column" {...props} >
        <Typography color={active ? "text.invert" : "primary.main"} align="center" variant="body1">
            {(isNaN(value) ? 0 : value).toFixed(2)}
        </Typography>
        <Typography color={active ? "text.invert" : "primary.main"} align="center" variant="caption">
            {title}
        </Typography>
    </CenteredBox>
}

const InvoiceSummaryController = ({ filters, setFilters }) => {

    const dispatch = useDispatch()

    const theme = useTheme()

    // const title = "Invoice"
    const fetchApi = getInvoiceSummary




    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }




    useEffect(() => {
        fetchList()
    }, [filters.type, filters.installer_id, filters.supplier_id,filters.dueMissed,filters.filterKey])


    if (loading)

        return <CenteredBox>
            <Grid container spacing={2} >
                <Grid item xs={6} >
                    <Skeleton variant="rounded" height="112px" />
                </Grid>
                <Grid item xs={6} >
                    <Skeleton variant="rounded" height="112px" />
                </Grid>

            </Grid>
        </CenteredBox>



    return (
        <>
            {
                list && Object.keys(list) && <OuterBox elevation={0} >

                    <Grid container spacing={2} >

                        <Grid item xs={4} >
                            <CountBox value={Number(list.totalAmount) - Number(list.paidAmount)} title={"Pending Amount"} >

                            </CountBox>
                        </Grid>

                        <Grid xs={0.25} >
                                <CenteredBox sx={{height:"100%"}}>
                                   <Box mt={2}>
                                   <Typography sx={{verticalAlign:"center"}} variant="body2" lineHeight="100%" >
                                        <RemoveCircle sx={{margin:0,lineHeight:"100%"}} fontSize="small" />
                                    </Typography>
                                   </Box>
                                </CenteredBox>
                            </Grid>
                        <Grid item xs={4} >
                            <CountBox ml={1} value={Number(list.pendingVerifications)} title={"Under Verification"} >

                            </CountBox>
                        </Grid>
                        <Grid item xs={3.75} >

                            <CountBox ml={1} active sx={{ backgroundColor: theme.palette.primary.main }} value={Number(list.totalAmount) - Number(list.paidAmount) - Number(list.pendingVerifications)} title={"Net Pending"} >

                            </CountBox>

                        </Grid>
                    </Grid>
                </OuterBox>
            }
        </>
    )
}
export default InvoiceSummaryController


