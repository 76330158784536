import { useEffect, useState } from "react"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { Box, Divider, FormControlLabel, Grid, Paper, Skeleton, Switch, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import { getInvoiceOverallSummary } from "../../apis/invoice.api"

import { CenteredBox } from "../../components/layouts/OneViewBox"
import { CountBox } from "./InvoiceSummary"
import { INVOICE_TYPE } from "../../utils/constants/invoice.constant"
import { FlagFilterButton } from "../projects/FlagFiltersComponent"



const InvoiceOverAllSummary = ({ }) => {

    const dispatch = useDispatch()



    // const title = "Invoice"
    const fetchApi = getInvoiceOverallSummary




    const [loading, setLoading] = useState(false)
    const [list, setList] = useState([])


    const [filters, setFilters] = useState({
        onlyAfterInstalltionComplition: false
    })






    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }




    useEffect(() => {
        fetchList()
    }, [filters])


    if (loading)

        return <Paper sx={{ display: "flex", flexDirection: "column" }} elevation={0} p={3} component={Box}>
            <Skeleton height="30px" variant="rounded" sx={{ mb: 3, mt: 3, width: "40%" }} />
            <Table size="small" >
                {

                    Object.keys(INVOICE_TYPE).map((item) => {
                        return <TableRow>
                            <TableCell> <Skeleton variant="rounded" /> </TableCell>
                            <TableCell> <Skeleton variant="rounded" /> </TableCell>
                            <TableCell> <Skeleton variant="rounded" /> </TableCell>
                            <TableCell> <Skeleton variant="rounded" /> </TableCell>
                        </TableRow>
                    })
                }
            </Table>
        </Paper>



    return (
        <>
            <Box sx={{ display: "flex", flexDirection: "column" }} p={3} elevation={0} component={Box}>
                {
                    list && list.map((item, index) => {
                        return <Box component={Paper} variant="outlined" mb={3}>
                            <Box p={3} sx={{ display: "flex", justifyContent: "space-between" }} >
                                {
                                    index == 0 ? <Typography variant="h6" fontWeight="bold" >
                                        Credit amounts
                                    </Typography> : <Typography variant="h6" fontWeight="bold" >
                                        Debit amounts
                                    </Typography>
                                }

                                <FlagFilterButton filters={filters} setFilters={setFilters} />
                                    {/* <FormControlLabel control={<Switch checked={filters.onlyAfterInstalltionComplition} onChange={(e) => setFilters({ ...filters, onlyAfterInstalltionComplition: e.target.checked ?? null })} />} label="All Installed Projects" /> */}
                            </Box>
                            <Divider />
                            <Box p={3} >
                                <Table size="small">
                                    <TableHead sx={(theme) => ({ background: theme.palette.primary.main, "*": { color: theme.palette.text.invert + "!important" } })} >
                                        <TableRow>
                                            <TableCell sx={(theme) => ({ borderTopLeftRadius: theme.shape.borderRadius })}>
                                                Invoice name
                                            </TableCell>
                                            <TableCell>
                                                Pending Amount
                                            </TableCell>
                                            <TableCell>
                                                Under Verification
                                            </TableCell>
                                            <TableCell sx={(theme) => ({ borderTopRightRadius: theme.shape.borderRadius })}>
                                                Net Pending
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            item.map((invoices) => {
                                                return <TableRow>
                                                    <TableCell>
                                                        {invoices.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {(Number(invoices.totalAmount) - Number(invoices.paidAmount))?.toFixed(2)}
                                                    </TableCell>
                                                    <TableCell>
                                                        {Number(invoices.pendingVerifications).toFixed(2)}
                                                    </TableCell>
                                                    <TableCell>
                                                        {(Number(invoices.totalAmount) - Number(invoices.paidAmount) - Number(invoices.pendingVerifications)).toFixed(2)}
                                                    </TableCell>
                                                </TableRow>


                                            })
                                        }
                                        <TableRow sx={(theme) => ({ background: theme.palette.primary.light })}>
                                            {
                                                item.reduce((currVal, invoiceItem) => {
                                                    const arr = [...currVal]
                                                    arr[1] = arr[1] + (Number(invoiceItem.totalAmount) - Number(invoiceItem.paidAmount))
                                                    arr[2] = arr[2] + Number(invoiceItem.pendingVerifications)
                                                    arr[3] = arr[3] + ((Number(invoiceItem.totalAmount) - Number(invoiceItem.paidAmount)) - Number(invoiceItem.pendingVerifications))

                                                    return arr;

                                                }, ["Total", 0, 0, 0]).map((item, index) => {
                                                    return <TableCell key={index} >
                                                        {typeof item == "number" ? item?.toFixed(2) : item}
                                                    </TableCell>
                                                })
                                            }
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                        </Box>
                    })
                }
            </Box>
        </>
    )
}
export default InvoiceOverAllSummary


