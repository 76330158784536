import { Box, Typography, useTheme } from "@mui/material"
import { useProjectStatusColor } from "../../../utils/helpers/project.helper"
import { findObjectKeyByValue } from "../../../utils/helpers/helper"
import { CenteredBox } from "../../../components/layouts/common/boxes"
import { PROJECT_STATUS } from "../../../utils/constants/project.constant"
import CustomChip from "../../../components/button/CustomChip"

const ProjectStatus = ({ status, chip }) => {

    const { bgColor, color } = useProjectStatusColor()(status)
    const theme = useTheme()
    if (chip)
        return <CustomChip bg={bgColor} fontColor={color} label={findObjectKeyByValue(status, PROJECT_STATUS)?.toUpperCase?.()} />

    return <CenteredBox p={1} sx={{ flex: 0, border: 2, borderStyle: "dashed", borderColor: bgColor, position: "relative" }}  >
        <Box sx={{ position: "absolute", top: "0px", left: "0px", height: "100%", width: "100%", backgroundColor: bgColor, zIndex: 0, opacity: 0.1 }} />
        <Typography align="center" variant="subtitle2" sx={{ color: bgColor }} >
            {findObjectKeyByValue(status, PROJECT_STATUS)?.toUpperCase?.()}
        </Typography>
    </CenteredBox>
}
export default ProjectStatus