import { Autocomplete, Box, ButtonBase, FormControl, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup, Skeleton, Typography } from "@mui/material"
import { memo } from "react"
import ClickButton from "../../components/button/ClickButton"
import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector"
import { CenteredBox } from "../../components/layouts/OneViewBox"
import { useDispatch } from "react-redux"
import { openModal } from "../../store/actions/modalAction"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import { ExpenseListDialog } from "../expenses/ListController"
import { FlagFilterButton } from "../projects/FlagFiltersComponent"
import { FinanceListModal } from "./ListController"
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop"
import { AUS_STATES } from "../../utils/constants/constants"
import { StyledSearchBar } from "../../components/inputs/SearchBar"
import SalesPersonFilterDropDown from "../../components/filterdropdowns/SalesPersonFilter"

const FinanceByInvoiceComponent = ({ title, type, expense, amount, project_counts, filters }) => {
    const dispatch = useDispatch()
    const onClick = () => {
        if (expense)
            dispatch(openModal(<ExpenseListDialog title={title} startDate={filters?.startDate} endDate={filters?.endDate} />, "lg", undefined, MODAL_KEYS.EXPENSES_LIST))
        else
            dispatch(openModal(<FinanceListModal type={type} defaultFilters={{ ...filters }} />, "lg", undefined, MODAL_KEYS.FINANCE_LIST))
    }
    return <Grid item md={12 / 5} xs={4} >
        <ClickButton
            onClick={onClick}
            title={amount?.toFixed?.(2)}
            midTitle={!expense ? "Projects: " + project_counts : ""}
            subTitle={title}
        />
    </Grid>
}
const FinanceTypeComponent = memo(({ title,description, data, filters }) => {

    return <Box mb={3} sx={{ width: "100%", overflow: "hidden" }}>
        <Typography>{title}</Typography>
        <Typography variant="caption">{description}</Typography>

        <Box mt={2} sx={{ width: "100%", overflow: "hidden" }} >
            <Grid container spacing={2} >
                {
                    data?.map((item) => <FinanceByInvoiceComponent
                        key={item?.type}
                        type={item?.type}
                        filters={filters}
                        expense={item?.expense}
                        project_counts={item.projects_counts}
                        amount={item?.amount}
                        title={item?.title}
                    />)
                }
            </Grid>
        </Box>
    </Box>
})

const FinanceSummaryComponent = memo(({ length, sign, title, value, onClick }) => (
    <Grid item md={12 / length} xs={4} >
        <Paper variant="outlined" component={Box} height="100%" display="flex" alignItems="stretch" >
            <Box component={Box} sx={{ flex: 0, minHeight: "100%" }} p={2}>
                <CenteredBox>
                    {sign}
                </CenteredBox>
            </Box>
            <CenteredBox component={onClick ? ButtonBase : undefined} onClick={onClick ?? (() => { })} borderLeft={1} borderColor="divider" display="flex" flex={1} flexDirection="column"  >
                <Typography variant="h6" align="center" >{value}</Typography>
                <Typography variant="subtitle2" align="center" >{title}</Typography>
            </CenteredBox>
        </Paper>
    </Grid>
))
const Loader = () => {
    return <Box mt={2}>

        <Grid container spacing={2} >
            {[1, 2, 3, 4].map((item) => <Grid key={item?.val} item xs={12 / 4} ><Skeleton sx={{ height: "80px" }} variant="rounded" /></Grid>)}

        </Grid>
        <Box mt={1} />
        <Grid container spacing={2} >
            {[1, 2, 3, 4].map((item) => <Grid key={item?.val} item xs={12 / 4} ><Skeleton sx={{ height: "80px" }} variant="rounded" /></Grid>)}

        </Grid>
        <Grid container spacing={2} >
            {[1, 2, 3, 4].map((item) => <Grid key={item?.val} item xs={12 / 4} ><Skeleton sx={{ height: "80px" }} variant="rounded" /></Grid>)}

        </Grid>
        <Grid container spacing={2} >
            {[1, 2, 3, 4].map((item) => <Grid key={item?.val} item xs={12 / 4} ><Skeleton sx={{ height: "80px" }} variant="rounded" /></Grid>)}

        </Grid>
    </Box>
}
const calculateNetProfit = (data) => {
    return data?.data?.reduce((currVal, financeItem) => {
        const subValues = financeItem?.data?.reduce?.(
            (currentVal, item) => currentVal + item?.amount,
            0)

        if (financeItem.debit) {
            return currVal - subValues
        }
        return currVal + subValues
    }
        , 0)?.toFixed?.(2)
}
const FinanceCountsUi = ({ loading, data, filters, setFilters }) => {

    const dispatch = useDispatch()
    return (

        <><Paper elevation={0} sx={{ mb: 2 }} >
            <Box p={3}>
                <Box mb={2}>
                    <FlagFilterButton filters={filters} setFilters={setFilters} />
                </Box>
                <Box sx={{ display: "flex" }} mb={2} >
                    <PaddingBoxInDesktop sx={{ flex: 1 }} >


                        <Autocomplete
                            disablePortal
                            size="small"
                            id="combo-box-demo"
                            options={AUS_STATES}
                            onChange={(e, newVal) => {
                                setFilters({ ...filters, state: newVal })
                            }}
                            fullWidth
                            renderInput={(params) => <StyledSearchBar {...params} placeholder="States" />}
                        />



                    </PaddingBoxInDesktop>
                    <PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }} pl={2} >


                        <SalesPersonFilterDropDown
                            id={'sales-projects'}
                            filters={filters}
                            setFilters={setFilters}
                            filtersKey="assigned_sales_person"
                        />
                    </PaddingBoxInDesktop>
                </Box>
                { }
                <Box p={2} mb={3} component={Paper} variant="outlined" sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }} >


                    <Box sx={{ display: "flex", flex: 1, minWidth: "300px" }}>
                        <FormControl>
                            <FormLabel id="date-type-radio">Date Type</FormLabel>
                            <RadioGroup
                                aria-labelledby="date-type-radio"
                                value={filters.dateKey}
                                row
                                name="radio-buttons-group"
                                onChange={(e) => setFilters({ ...filters, dateKey: e.target.value })}
                            >
                                <FormControlLabel value="createdAt" control={<Radio />} label="Creation Date" />

                                <FormControlLabel value="approval_date" control={<Radio />} label="Approval Date" />
                                <FormControlLabel value="installtion_date" control={<Radio />} label="Installation Date" />

                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <Box sx={{ display: "flex", flex: 1, maxWidth: "400px" }}>
                        <TimeRangeSelector defaultVal={{ label: "This Month" }} onChange={(newRange) => { setFilters({ ...filters, ...newRange }) }} />
                    </Box>



                </Box>
            </Box>
        </Paper>
            <Paper elevation={0}>
                <Box p={3}>
                    {
                        loading ? <Loader /> :
                            <Box sx={{ width: "100%", overflow: "hidden" }} >
                                <Typography fontWeight="Bold">
                                    Finance Summary
                                </Typography>

                                {
                                    data && data?.data?.length > 0 && <Box mt={2}>
                                        <Grid container spacing={2} >

                                            {
                                                data?.data?.map((item) => (
                                                    <FinanceSummaryComponent
                                                        key={item.title}
                                                        sign={item?.debit ? "-" : "+"}
                                                        title={item?.title}
                                                        value={item?.data?.reduce?.((currentVal, item) => currentVal + item?.amount, 0)?.toFixed?.(2)}
                                                        length={(data?.data?.length ?? 0) + 1}
                                                    />
                                                ))
                                            }
                                            <FinanceSummaryComponent

                                                sign={"="}
                                                title={"Net Profit"}
                                                value={calculateNetProfit(data)}
                                                length={(data?.data?.length ?? 0) + 1}
                                            />
                                        </Grid>
                                    </Box>
                                }





                                <Typography fontWeight="Bold" mt={2} mb={2}>
                                    Project Based Summary
                                </Typography>
                                <Box>
                                    <Grid container spacing={3}>
                                        <FinanceSummaryComponent
                                            onClick={() => {
                                                dispatch(openModal(<FinanceListModal type={undefined} defaultFilters={{ ...filters }} />, "lg", undefined, MODAL_KEYS.FINANCE_LIST))
                                            }}
                                            // sign={""}
                                            title={"Total Projects"}
                                            value={data?.totalProjects}
                                            length={2}
                                        />
                                        <FinanceSummaryComponent

                                            // sign={""}
                                            title={"Per Project Net Profit"}
                                            value={(calculateNetProfit(data) / (data?.totalProjects > 0 ? data?.totalProjects : 1))?.toFixed(2)}
                                            length={2}
                                        />
                                    </Grid>
                                </Box>



















                                <Typography fontWeight="Bold" mt={4} mb={2}>
                                    Finance Summary Details
                                </Typography>
                                <Box component={Paper} variant="outlined" p={2}>
                                    {
                                        data && data?.data?.length > 0 && data?.data?.map((item) => <FinanceTypeComponent description={item.description} filters={filters} key={item.title} data={item.data} title={item?.title} />)
                                    }

                                </Box>
                            </ Box >


                    }
                </Box>

            </Paper>
        </>
    )
}
export default memo(FinanceCountsUi)