import { Box, Button, Paper, Typography, styled } from "@mui/material";

import { Add } from "@mui/icons-material";
// import AddHolidayDataController from './AddHolidayDataController'
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../store/actions/modalAction";
import { DatePicker } from "@mui/x-date-pickers";
import CustomInput from "../../components/inputs/CustomInput";
import DataTable from "../../components/tables/DataTable";
import SubmitButton from "../../components/button/SubmitButton";
import AddVersionController from "./AddVersionController";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));
const VersionUI = ({
  // setState,
  callBack,
  filters,
  setFilters,
  loading,
  list,
  columns,
}) => {

  const dispatch = useDispatch();
  const addVersion = useCallback(() => {
    dispatch(openModal(<AddVersionController callBack={callBack} />, "sm"));
    }, []);
    return (
      <>
      <Box mb={3}>
        <Paper elevation={2} sx={{ width: "100%", padding: 4 }}>
          <Box mb={4}>
            <FilterTitleBox>
              <Typography variant="h5" mb={2}>
                Versions
              </Typography>

              <Box>
                <SubmitButton
                  onClick={addVersion}
                  title="Add Version"
                ></SubmitButton>
              </Box>
            </FilterTitleBox>
          </Box>

          <Box sx={{ minHeight: "300px" }}>
            <DataTable
              columns={columns}
              rows={list}
              count={list?.length}
              filters={filters}
              setFilters={setFilters}
              loading={loading}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
};
export default VersionUI;
