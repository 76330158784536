import { CircularProgress } from "@mui/material"

import { memo } from "react"
import { useSelector } from 'react-redux'
import CustomInput from "../../../components/inputs/CustomInput"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../components/layouts/OneViewBox"


const UpdateInvoiceUi = ({ title, isUpdate, fields, setFields, loading, onSubmit, fullEdit }) => {
    const { user } = useSelector(state => state)


    return <>

        <CustomDialog

            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Create"} ${title}`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Create"}`}
        >

            {loading && <CenteredBox><CircularProgress /></CenteredBox>}
            <>
                {fullEdit && < CustomInput

                    disabled={loading}
                    value={fields.invoice_no}
                    onChange={(e) => setFields({ ...fields, err: '', invoice_no: e.target.value })}
                    type="text"
                    label={"Purchase Invoice No*"}

                />}

                <CustomInput

                    disabled={loading}
                    value={fields.amount}
                    onChange={(e) => setFields({ ...fields, err: '', amount: e.target.value })}
                    type="number"
                    label={"Total Invoice Amount*"}

                />



            </>

        </CustomDialog>
    </>
}
export default memo(UpdateInvoiceUi)