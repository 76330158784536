import { fetchVersiones } from "../../apis/version.api";
import { actions } from "../../utils/constants/constants";
import { callApiAction } from "./commonAction";

export const fetchVersionDataAction = (
  filters,
  onSuccess = () => {},
  onError = () => {}
) => {
  return async (dispatch, getState) => {
    dispatch({ type: actions.START_VERSION_LOADING });
    dispatch(
      callApiAction(
        async () => await fetchVersiones(filters),
        (response) => {
          
          dispatch({
            type: actions.SET_VERSION_DATA,
            value: { data: response?.result, filters: filters },
          });
          onSuccess(response);
        },
        (err) => {
          onError(err);
        }
      )
    );
  };
};

export const setVersionDataOnDeleteAction = (data, filters) => {
  return async (dispatch, getState) => {
    dispatch({
      type: actions.SET_VERSION_DATA_ONDELETE,
      value: { data: data, filters: filters },
    });
  };
};