import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import CreateUi from "./CreateUi"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { closeModal } from "../../store/actions/modalAction"


import { createExpenseApi, createPreDefinedExpenseApi, getExpenseByIdApi, updateExpenseApi } from "../../apis/finance.api"
import moment from "moment"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"


const CreateController = ({ callBack = () => { } }) => {

    const validate = useValidate()
    const dispatch = useDispatch()

    const modalKey = MODAL_KEYS.PRE_DEFINED_EXPENSE_CREATE
    const title = "Predefined Expenses"
    const createApi = createPreDefinedExpenseApi


    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',

        amount: 0,
        title: "",




    })


    const validationSchemaForCreate = useMemo(() => ([
        {
            required: true,
            value: fields.title,
            field: 'Title',
        },
        {
            required: true,
            value: fields.amount,
            field: 'Amount',
        }

    ]), [fields])



    const createFunction = async () => {

        const validationResponse = validate(validationSchemaForCreate)

        let dataToBePassed = { ...fields }


        if (validationResponse === true) {
            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createApi({ ...dataToBePassed }),
                    async (response) => {
                        await callBack(fields)
                        setLoading(false)
                        dispatch(closeModal(modalKey))
                    },
                    (err) => {

                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }


    const onSubmit = async (e) => {
        e?.preventDefault()

        createFunction()

    }


    return <CreateUi title={title} modalKey={modalKey}  loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(CreateController)