import { useEffect, useState } from "react"
import BulkInvoiceListUI from "./BulkInvoiceListUI"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"


import { openModal } from "../../store/actions/modalAction"
import { Box } from "@mui/material"
import { useMemo } from "react"


import moment from "moment"
import FileDownloadComponent from "../../components/FileDownloadComponent"
import { getInvoiceData } from "../../apis/invoice.api"
import { INVOICE_STATUS, INVOICE_TYPE } from "../../utils/constants/invoice.constant"

import BulkCreateController from "./BulkCreateController"
import { findObjectKeyByValue } from "../../utils/helpers/helper"


const BulkInvoiceListController = ({ title = "Bulk Invoices" }) => {

    const dispatch = useDispatch()



    // const title = "Invoice"
    const fetchApi = getInvoiceData
    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        installer_id: null,
        sub_type: null,
        searchable: ['invoice_no', 'customer_name'],
        status: null,
        type: INVOICE_TYPE.BULK_INVOICE,
        installer_id: null,
        sort: 'due_date',
        sortDirection: -1,
        currentDate: moment().toISOString(),
        onlyAfterInstalltionComplition: null

    })


    const columns = useMemo(() => {

        const tableCells = [
            {
                id: 0, fieldName: 'due_date', label: 'Due Date.', align: "left", renderValue: (params, setParams) => {
                    return moment(params.due_date).format("DD/MM/YYYY")
                }
            },
            { id: 1, fieldName: 'customer_name', label: 'Invoice Of', align: "left", minWidth: "150px", renderValue: (params) => <Box dangerouslySetInnerHTML={{ __html: params.customer_name }} /> },


            { id: 3, fieldName: 'amount', label: 'Total Amount', align: "left", renderValue: (params) => Number(params.amount).toFixed(2) },

            { id: 5, fieldName: 'paid_amount', label: 'Remained Amount', align: "left", renderValue: (params) => (Number(params.amount) - Number(params.paid_amount)).toFixed(2) },
            { id: 6, fieldName: 'invoice_no', label: 'Invoices', align: "left", renderValue: (params) => params.invoice_no?.split?.('_')?.join?.(', ')?.slice?.(1) },
            {
                id: 7, fieldName: '_id', minWidth: "150px", label: 'Download Attachment', align: "left", renderValue: (params) => {
                    return <>

                        {(params.custom_invoice && params.custom_invoice != '') ? <FileDownloadComponent heades src={params.custom_invoice} /> : "NA"}

                    </>
                }
            }
        ]
        if (!filters.sub_type)
            tableCells.splice(2, 0, { id: 2, fieldName: 'sub_type', label: 'Type', align: "left", renderValue: (params) => findObjectKeyByValue(params.sub_type, INVOICE_TYPE) })
        if (filters.sub_type == INVOICE_TYPE.ELECTRICIAN_INVOICE)
            tableCells.splice(2, 0, { id: 4, fieldName: 'installer_id', label: 'Installer', align: "left", minWidth: "150px", renderValue: (params) => params?.installer_id?.name ?? "NA" })
        if (filters.sub_type == INVOICE_TYPE.SUPPLIER_INVOICE)
            tableCells.splice(2, 0, { id: 8, fieldName: 'supplier_id', label: 'Supplier', align: "left", minWidth: "150px", renderValue: (params) => params?.supplier_id?.name ?? "NA" })



        return tableCells
    }, [dispatch, filters.sub_type]);







    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const onCreateBtnClick = () => {
        dispatch(openModal(<BulkCreateController callBack={async () => { fetchList() }} />, "md"))
    }



    useEffect(() => {
        fetchList()
    }, [filters])



    return (
        <>
            <BulkInvoiceListUI

                title={title}
                onCreateBtnClick={onCreateBtnClick}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default BulkInvoiceListController