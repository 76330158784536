import {  CircularProgress} from "@mui/material"
import { Box } from "@mui/system"
import { Fragment, memo } from "react"
import { useDispatch, useSelector } from 'react-redux'

import CustomInput from "../../components/inputs/CustomInput"

import CustomDialog from "../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../components/layouts/OneViewBox"



const CreateUi = ({ title, isUpdate, fields, setFields, loading, onSubmit }) => {
    const { user } = useSelector(state => state)


    return <>

        <CustomDialog

            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={` ${title}`}
            closeText="Close"
            confirmText={`Fetch`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>
                    { <CustomInput
                        autoFocus={true}
                        disabled={loading}
                        value={fields.id}
                        onChange={(e) => setFields({ ...fields, err: '', id: e.target.value })}
                        type="text"
                        label={"Open Solar Project Id*"}

                    />}

                   


                </>}

        </CustomDialog>
    </>
}
export default memo(CreateUi)