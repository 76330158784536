import { Autocomplete, CircularProgress } from "@mui/material"
import { Box } from "@mui/system"
import { memo } from "react"
import { useSelector } from 'react-redux'
import CustomInput from "../../../components/inputs/CustomInput"
import { titleCase, toTitleCase } from "../../../utils/helpers/helper"
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import { CenteredBox } from "../../../components/layouts/OneViewBox"
import { INVOICES_NAME, INVOICE_STATUS, INVOICE_TYPE, INVOICE_TYPE_DROP_DOWN, INVOICE_TYPE_LIST, PAYMENT_OPTION } from "../../../utils/constants/invoice.constant"
import { MobileDatePicker } from "@mui/x-date-pickers"
import { findObjectKeyByValue } from "../../../utils/helpers/helper"
import FileInput from "../../../components/inputs/FileInput"
import { allowedInvoiceBasedonProjectStatus, isAllowedPartialPayment } from "../../../utils/helpers/invoice.helper"



const CreateUi = ({ title, isUpdate, fields, setFields, loading, onSubmit, type, project_status, modalKey }) => {
    const { user } = useSelector(state => state)


    return <>

        <CustomDialog
            id={modalKey}
            loading={loading}
            err={fields.err}
            onSubmit={onSubmit}
            title={`${isUpdate ? "Update" : "Create"} ${INVOICES_NAME[fields.type] ?? toTitleCase(findObjectKeyByValue(fields.type, INVOICE_TYPE))} Invoice`}
            closeText="Close"
            confirmText={`${isUpdate ? "Update" : "Create"}`}
        >

            {loading ? <CenteredBox><CircularProgress /></CenteredBox> :
                <>


                    {!isUpdate && fields.type != INVOICE_TYPE.DEPOSIT && <CustomInput

                        disabled={loading}
                        value={fields.amount}
                        onChange={(e) => setFields({ ...fields, err: '', amount: e.target.value, paid_amount: fields.disableStatus ? e.target.value : fields.paid_amount })}
                        type="number"
                        label={"Amount*"}

                    />}


                    {!isUpdate && (fields.type != INVOICE_TYPE.DEPOSIT && fields.type != INVOICE_TYPE.ELECTRICIAN_INVOICE) && <Autocomplete
                        disableClearable
                        value={INVOICES_NAME[fields.type] ?? findObjectKeyByValue(fields.type, INVOICE_TYPE)}
                        onChange={(e, newVal) => {
                            const val = newVal ? newVal._id : null
                            if (val == INVOICE_TYPE.CUSTOMER_INVOICE)
                                setFields({ ...fields, type: val, disableStatus: false, paid_amount: '', invoice_no: undefined })
                            else
                                setFields({ ...fields, type: val, disableStatus: false, invoice_no: undefined })

                        }}
                        options={project_status ? allowedInvoiceBasedonProjectStatus(project_status, user?.data.role) :
                            [...Object.keys(INVOICE_TYPE_DROP_DOWN).map((key) => ({ label: titleCase(key), _id: INVOICE_TYPE[key] }))]}

                        sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                        renderInput={(params) => <CustomInput placeholder="Select Type*" {...params} label="Select Type*" />}
                    />}



                    {(fields.type == INVOICE_TYPE.SUPPLIER_INVOICE || fields.type == INVOICE_TYPE.ELECTRICIAN_INVOICE) && <CustomInput

                        disabled={loading}
                        value={fields.invoice_no}
                        onChange={(e) => setFields({ ...fields, err: '', invoice_no: e.target.value })}
                        type="text"
                        label={"Invoice No*"}

                    />}

                    {!isUpdate && fields.type != INVOICE_TYPE.DEPOSIT && !fields.disableStatus && <Autocomplete
                        disableClearable
                        value={findObjectKeyByValue(fields.status, INVOICE_STATUS)}
                        onChange={(e, newVal) => {
                            setFields({ ...fields, status: newVal ? newVal._id : null })
                        }}
                        options={[...Object.keys(INVOICE_STATUS).map((key) => ({ label: titleCase(key), _id: INVOICE_STATUS[key] }))]}
                        sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                        renderInput={(params) => <CustomInput placeholder="Select Status*" {...params} label="Select Status*" />}
                    />}

                    {!isUpdate && fields.type != INVOICE_TYPE.DEPOSIT && <MobileDatePicker
                        inputFormat="DD/MM/YYYY"
                        renderInput={(props) => <CustomInput {...props} />}
                        label="Invoice Creation Date"
                        value={fields.creation_date}
                        onChange={(changedVal) => { setFields({ ...fields, creation_date: changedVal }) }}
                    />}

                    {
                        !isUpdate && (fields.type == INVOICE_TYPE.DEPOSIT || fields.type == INVOICE_TYPE.ELECTRICIAN_INVOICE) && <FileInput
                            accept=".pdf"
                            title="Add Receipt*"
                            subTitle="Only .pdf are required less than 2mb."
                            onChange={(newUrl) => {
                                setFields({ ...fields, custom_invoice: newUrl })
                            }}

                        />
                    }

                    {!isUpdate && fields.type != INVOICE_TYPE.DEPOSIT && <MobileDatePicker
                        inputFormat="DD/MM/YYYY"
                        renderInput={(props) => <CustomInput {...props} />}
                        label="Invoice Due Date"
                        value={fields.due_date}
                        onChange={(changedVal) => { setFields({ ...fields, due_date: changedVal }) }}
                    />}


                    {(fields.status == INVOICE_STATUS.PAID || fields.status == INVOICE_STATUS.PARTIALLY_PAID) &&
                        <>
                            <CustomInput

                                disabled={loading || (isUpdate && !isAllowedPartialPayment(fields.type)) || fields.disableStatus}
                                value={fields.paid_amount}
                                onChange={(e) => {

                                    setFields({ ...fields, amount: fields.type == INVOICE_TYPE.DEPOSIT ? e.target.value : fields.amount, err: '', paid_amount: e.target.value })
                                }}
                                type="number"
                                label={"Paid Amount*"}

                            />
                            {(!isUpdate || !INVOICE_TYPE_LIST.find((item) => item.value == fields.type)?.debit) && <>
                                <Autocomplete
                                    disableClearable
                                    value={findObjectKeyByValue(fields.payment_by, PAYMENT_OPTION)}
                                    onChange={(e, newVal) => {
                                        setFields({ ...fields, payment_by: newVal ? newVal._id : null, transaction_id: newVal?._id == PAYMENT_OPTION.LOSS ? 'Loss' : '' })
                                    }}
                                    options={[...Object.keys(PAYMENT_OPTION).map((key) => ({ label: titleCase(key), _id: PAYMENT_OPTION[key] }))]}
                                    sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                                    renderInput={(params) => <CustomInput placeholder="Select Payment Option*" {...params} label="Select Payment Option*" />}
                                />
                                {fields.payment_by != PAYMENT_OPTION.LOSS && <>
                                    <MobileDatePicker
                                        inputFormat="DD/MM/YYYY"
                                        renderInput={(props) => <CustomInput {...props} />}
                                        label="Invoice Payment Date*"
                                        value={fields.payment_date}
                                        onChange={(changedVal) => { setFields({ ...fields, payment_date: changedVal }) }}
                                    />

                                    <CustomInput

                                        disabled={loading}
                                        value={fields.transaction_id}
                                        onChange={(e) => setFields({ ...fields, err: '', transaction_id: e.target.value })}
                                        type="text"
                                        label={"Transaction Id*"}

                                    />

                                </>}
                            </>}
                        </>
                    }

                </>}

        </CustomDialog>
    </>
}
export default memo(CreateUi)