import { memo,  useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import ChatGroupCreateUi from "./CreateUiChatGroup"
import { useDispatch, useSelector } from 'react-redux'

import { closeModal } from "../../store/actions/modalAction"


import moment from "moment"
import { createChatGroup } from "../../store/actions/chatAction"

const AddHolidayDataController = ({ }) => {
    const { user } = useSelector(state => state)
    const validate = useValidate()
    const dispatch = useDispatch()


    const title = "Group "



    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        name: '',
        members: [],
        time: moment().toISOString()
    })


    const validationSchemaForCreate = useMemo(() => ([
        {
            required: true,
            value: fields.name,
            field: 'Group Name',
            custom: () => {
                if (fields.name?.length > 30) {
                    return "Group Name should be less than 30 character"
                }
                return true
            }
        },
    ]), [fields])




    const createFunction = async (forcefully) => {
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {
            const dataToBePassed = { ...fields, members: [...fields.members.map(item => item._id)] }

            dataToBePassed.members.push(user.data._id)


            setLoading(true)
            dispatch(
                createChatGroup(dataToBePassed, () => {
                    dispatch(closeModal())
                    setLoading(false)
                }, (err) => {
                    setLoading(false)
                    setFields({ ...fields, err })
                })


            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }





    const onSubmit = async (e) => {
        e.preventDefault()


        createFunction()

    }




    return <ChatGroupCreateUi title={title} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(AddHolidayDataController)