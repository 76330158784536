import axios from "axios";
import { getHeaders } from "../utils/helpers/helper";
import endpoints from "./endpoints";



export const getFinanceCountApi = async params => {
    const callResponse = await axios({
        url: endpoints.financeCounts,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};

export const getExpenseApi = async params => {
    const callResponse = await axios({
        url: endpoints.expenseBase,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};




export const getExpenseByIdApi = async params => {
    const callResponse = await axios({
        url: endpoints.expenseById,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};
export const createExpenseApi = async (data) => {
    const callResponse = await axios({
        url: endpoints.expenseBase,
        method: "POST",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const updateExpenseApi = async (data) => {
    const callResponse = await axios({
        url: endpoints.expenseBase,
        method: "PUT",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const deleteExpenseApi = async (data) => {
    const callResponse = await axios({
        url: endpoints.expenseBase,
        method: "DELETE",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => err.response.data);

    return callResponse;
};
export const getFinanceApi = async params => {
    const callResponse = await axios({
        url: endpoints.financeBase,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};

export const getSuggestionForExpenseTitleApi = async params => {
    const callResponse = await axios({
        url: endpoints.expenseSuggetionBase,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};

export const getPreDefinedExpenseApi = async params => {
    const callResponse = await axios({
        url: endpoints.preDefinedExpenseBase,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};
export const createPreDefinedExpenseApi = async data => {
    const callResponse = await axios({
        url: endpoints.preDefinedExpenseBase,
        method: "post",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};

export const deletePreDefinedExpenseApi = async data => {
    const callResponse = await axios({
        url: endpoints.preDefinedExpenseBase,
        method: "delete",
        headers: getHeaders(),
        data,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};

export const createExpenseFromPredefinedExpenseApi = async data => {
    const callResponse = await axios({
        url: endpoints.expenseFromPreDefinedExpenseBase,
        method: "post",
        headers: {
            "Content-Type": "application/json",
            ...getHeaders()
        },
        data,
    })
        .then(response => response.data)
        .catch(err => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};
