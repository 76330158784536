import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
// import { deleteUserApi, getUserApi, updateUserField } from "../../apis/user.api"
import { closeModal, openModal } from "../../store/actions/modalAction"
import { findNameByRole, titleCase } from "../../utils/helpers/helper"
import { Box, CircularProgress, IconButton, Switch, Typography } from "@mui/material"
import { useMemo } from "react"
import CreateController from "./CreateController"
import { Delete, Edit } from "@mui/icons-material"
import MessageDilog from "../../components/MessageDilog"
import { getSecretsApi, updateSecretsField } from "../../apis/secrets.api"

const ActionComponent = memo(({ params, setParams, deleteApi }) => {
    const dispatch = useDispatch()




    const onEdit = () => {
        dispatch(openModal(<CreateController id={params._id} callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData })
        }} />, "sm"))
    }


    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        <IconButton size="inherit" onClick={onEdit}>
            <Edit color="info" fontSize="inherit" />
        </IconButton>

    </Box>
})

const BooleanActionComponent = memo(({ params, setParams, updateApi }) => {
    const dispatch = useDispatch()


    const [selected, setSelected] = useState(params['disabled'])
    const [loading, setLoading] = useState(false)

    const handleChange = (e) => {

        setLoading(true)
        dispatch(callApiAction(
            async () => await updateApi({ id: params._id, disabled: e.target.checked }),
            (response) => {
                setLoading(false)
                setSelected(e.target.checked)
            },
            (err) => {
                setLoading(false)
            }
        ))
        dispatch(closeModal())
    }

    if (loading)
        return <CircularProgress color="primary" fontSize="inherit" />
    return <Switch
        checked={selected}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
    />
})

const ListController = () => {
    const dispatch = useDispatch()



    const title = "Secrets "
    const fetchApi = getSecretsApi
    const deleteApi = null
    const updateApi =  updateSecretsField

    const columns = useMemo(() => [

        { id: 1, fieldName: 'key_name', label: 'Key Name', align: "left", sort: true },
        { id: 2, fieldName: 'key_value', label: 'Key Value', align: "left" },
        {
            id: 3, fieldName: 'third_party_app', minWidth: 150, label: 'App Name', align: "left"

            , renderValue: (params, setParams) => titleCase(params['third_party_app']),
        },
        {
            id: 4, fieldName: 'disabled', minWidth: 100, label: 'Disabled', align: "left"

            ,
            renderValue: (params, setParams) => <BooleanActionComponent updateApi={updateApi} params={params} setParams={setParams} />
        },


        {
            id: 5,
            fieldName: '',
            label: 'Action',

            align: "right",
            renderValue: (params, setParams) => <ActionComponent deleteApi={deleteApi} params={params} setParams={setParams} />,
        },

    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        searchable:['key_name'],
        sort: '',
        sortDirection: 1

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController callBack={async () => { fetchList() }} />, "sm"))
    }



    useEffect(() => {
        fetchList()
    }, [filters])



    return (
        <>
            <ListUi
                title={title}
                onCreateBtnClick={onCreateBtnClick}

                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default ListController