import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../../../store/hooks/useValidator"
import CreateUi from "../../CreateUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../../../store/actions/commonAction"
import { closeModal, openModal } from "../../../../store/actions/modalAction"
import { useParams } from "react-router-dom"

import { addProjectsApi } from "../../../../apis/projects.api"
import { CircularProgress, IconButton, Tooltip } from "@mui/material"
import { Refresh } from "@mui/icons-material"
import { callSnackBar } from "../../../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../../../utils/constants/constants"
import SubmitButton from "../../../../components/button/SubmitButton"
import MessageDilog from "../../../../components/MessageDilog"
import { MODAL_KEYS } from "../../../../utils/constants/modal.constant"
import { recieveProjectOrdersApi } from "../../../../apis/order.api"
import RecieveOrderUi from "./RecieveOrderUi"

const CreateController = ({ callBack, project_id, suppliers }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    const params = useParams()
    const modalKey = MODAL_KEYS.RECIEVE_ORDER
    const title = "Recieve Order"
    const createApi = recieveProjectOrdersApi

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        project_id: project_id,
        invoices: suppliers?.map((item) => ({
            supplier: item?._id,
            supplierName: item?.name,
            amount: 0
        })),

        app_deleivery_fees_amount: 0
    })



    const validationSchemaForCreate = useMemo(() => ([

        {
            required: true,
            value: fields.project_id,
            field: 'Project Id',
        },

        {

            value: fields.invoices,
            field: 'Supplier Invoices',
            custom: () => {
                for (let item of fields.invoices) {
                    if (!item.amount || item.amount == "") {
                        return "Add invoice no. to each invoice"
                    }
                    if (!item.amount || item.amount == "") {
                        return "Add Amount to each invoice"
                    }
                    if (!item.custom_invoice || item.custom_invoice == "") {
                        return "Attach invoice slip"
                    }
                }
                return true
            }
        },
        {
            required: true,
            value: fields.app_deleivery_fees_amount,
            field: 'Application And Delivery Fees',
        }
    ]), [fields])





    const createFunction = async (e) => {
        e?.preventDefault()
        const validationResponse = validate(validationSchemaForCreate)

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await createApi(fields),
                    async (response) => {

                        await callBack(response)
                        setLoading(false)


                        dispatch(closeModal(modalKey))



                    },
                    (err) => {
                        setLoading(false)
                        dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error))
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            dispatch(callSnackBar(validationResponse, SNACK_BAR_VARIETNS.error))
            setFields({ ...fields, 'err': validationResponse })
        }
    }


    const onSubmit = async (e) => {
        e.preventDefault()
        createFunction()

    }



    return <RecieveOrderUi modalKey={modalKey} title={title} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(CreateController)