import { Button } from '@mui/material'
import { useDispatch } from 'react-redux'
import { openModal } from '../../../../store/actions/modalAction'
import CreateOrderController from './CreateOrderController'
import { MODAL_KEYS } from '../../../../utils/constants/modal.constant'
import CustomButton, { CustomSubmitIconButton } from '../../../../components/button/CustomButton'
import { ArrowForward } from '@mui/icons-material'
const CreateOrderButton = ({ system, project_id, callBack }) => {
    const dispatch = useDispatch()
    const onClick = () => {
        dispatch(openModal(
            <CreateOrderController system={system} project_id={project_id} callBack={callBack} />,
            "md",
            undefined,
            MODAL_KEYS.CREATE_ORDER
        ))
    }
    return <CustomSubmitIconButton disableElevation variant="contained" sx={{}} color="primary" size="small" onClick={onClick} >
        <ArrowForward />
    </CustomSubmitIconButton>
}
export default CreateOrderButton