import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListUi"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"

import { closeModal, openModal } from "../../store/actions/modalAction"
import { Box, CircularProgress, IconButton, Switch, Typography } from "@mui/material"
import { useMemo } from "react"
import CreateController from "./CreateController"
import { Delete, Edit } from "@mui/icons-material"


import getLoanBenefitApi, { deleteLoanBenefitField } from "../../apis/loanbenefit.api"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import MessageDilog from "../../components/MessageDilog"
import getFBIntegrationApi, { deleteFBIntegrationField } from "../../apis/facebookintegration.api"

const DeleteButton = memo(({ params, setParams }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const onDelete = (e) => {
        e?.preventDefault()
        dispatch(closeModal())
        setLoading(true)
        dispatch(callApiAction(
            async () => await deleteFBIntegrationField({ id: params?._id }),
            (response) => {
                setParams({})
                setLoading(false)
            },
            (err) => {                
                setLoading(false)
            }
        ))

    }

    const onClick = () => {
        dispatch(openModal(<MessageDilog title="Alert!" message="Are you sure to delete" onSubmit={onDelete} />, "sm"))
    }

    if (loading)
        return <CircularProgress />
    return <IconButton onClick={onClick}>
        <Delete color="error" />
    </IconButton>
})
const ActionComponent = memo(({ params, setParams }) => {
    const dispatch = useDispatch()





    const onEdit = () => {
        dispatch(openModal(<CreateController id={params._id} callBack={(response, updatedData) => {
            setParams({ ...params, ...updatedData })
        }} />, "sm", undefined, MODAL_KEYS.FB_INTEGRATION))
    }


    return <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
        <IconButton size="inherit" onClick={onEdit}>
            <Edit color="info" fontSize="inherit" />
        </IconButton>
        <DeleteButton params={params} setParams={setParams} />
    </Box>
})



const ListController = () => {
    const dispatch = useDispatch()



    const title = "Facebook Integration"
    const fetchApi = getFBIntegrationApi
    const deleteApi = null


    const columns = useMemo(() => [

        { id: 1, fieldName: 'page_id', label: 'Page Id', align: "left", sort: true },
        { id: 2, fieldName: 'page_token', label: 'Token', align: "left" },



        {
            id: 5,
            fieldName: '',
            label: 'Action',

            align: "right",
            renderValue: (params, setParams) => <ActionComponent deleteApi={deleteApi} params={params} setParams={setParams} />,
        },

    ], [dispatch]);


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        searchable: ['page_id'],
        sort: '',
        sortDirection: 1

    })





    // do not change 

    const [loading, setLoading] = useState(false)
    const [list, setList] = useState({})









    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const onCreateBtnClick = () => {
        dispatch(openModal(<CreateController callBack={async () => { fetchList() }} />, "sm",undefined,MODAL_KEYS.FB_INTEGRATION))
    }



    useEffect(() => {
        fetchList()
    }, [filters])



    return (
        <>
            <ListUi
                title={title}
                onCreateBtnClick={onCreateBtnClick}

                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                columns={columns}


            />

        </>
    )
}
export default ListController