import { Chip, useTheme } from "@mui/material"
import { TASK_PRIORITY } from "../../../utils/constants/task.constants"
import { findObjectKeyByValue } from "../../../utils/helpers/helper"

const PriorityComponent = ({priority})=>{
const theme = useTheme()
    let color = 'default'

    if(priority==TASK_PRIORITY.HIGH){
        color = 'error'
    }
    if(priority==TASK_PRIORITY.MEDIUM){
        color = 'warning'
    }
return <Chip size="small" color={color} label={findObjectKeyByValue(priority,TASK_PRIORITY)} />
}
export default PriorityComponent