import { memo, useEffect, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { useParams } from "react-router-dom"
import { addLeadApi, getLeadByIdApi, updateLeadField } from "../../apis/lead.api"
import DetailedViewUi from "./DetailedViewUi"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import { getComplainByIdApi } from "../../apis/complain.api"

const DetailedViewController = ({  id }) => {
    
    const dispatch = useDispatch()
    
    const modalKey = undefined
    const title = "Complain Details"
    
    
    const getByIdApi = getComplainByIdApi

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        id,
       

    })



    
    
   



    const onSubmit = async (e) => {
        e.preventDefault()
      
    }

    const fetchById = (id) => {
        setLoading(true)
        dispatch(
            callApiAction(
                async () => await getByIdApi({ id }),
                async (response) => {


                    setFields({ ...fields, ...response })
                    
                    setLoading(false)

                },
                (err) => {
                    setFields({ ...fields, err })
                    setLoading(false)

                }
            )
        )
    }

    useEffect(() => {
        fetchById(id)
    }, [id])


    return <DetailedViewUi modalKey={modalKey} title={title} isUpdate={id} loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(DetailedViewController)