import { Box, CircularProgress,  Grid,  Typography } from "@mui/material"

import {  memo } from "react"
import {  useSelector } from 'react-redux'
import CustomDialog from "../../../components/layouts/common/CustomDialog"
import moment from "moment"
import PriorityComponent from "./PriorityComponent"
import StatusComponent from "./StatusComponent"
import DeleteTaskButton from "./DeleteTaskButton"



const TaskDetailsUi = ({ title, isUpdate, fields, setFields, loading, onSubmit }) => {

    const { user } = useSelector(state => state)

    return <>

        <CustomDialog

            loading={loading}
            err={fields.err}
            // onSubmit={onSubmit}
            title={`Task Details`}
            closeText="Close"
        // confirmText={``}
        >

            {loading ? <CircularProgress /> : <>

                <Grid container spacing={2}>
                    <Grid item xs={6} >
                        <Box >
                            <Typography variant="body1" fontWeight="bold" >Task Id :</Typography>
                            <Typography variant="subtitle2"  >{fields.task_id}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} >
                        <Box >
                            <Typography variant="body1" fontWeight="bold" >Task Title :</Typography>
                            <Typography variant="subtitle2"  >{fields.name}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} >
                        <Box >
                            <Typography variant="body1" fontWeight="bold" >Task Description :</Typography>
                            <Typography variant="subtitle2"  >{fields.description}</Typography>
                        </Box>

                    </Grid>
                    <Grid item xs={6} >
                        <Box >
                            <Typography variant="body1" fontWeight="bold" >Task Duration :</Typography>
                            <Typography variant="subtitle2"  >{moment(fields.start_date).format("DD/MM/YYYY")} - {moment(fields.due_date).format("DD/MM/YYYY")}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} >

                        <Box >
                            <Typography variant="body1" fontWeight="bold" >Priority  :</Typography>
                            <Typography variant="subtitle2"  ><PriorityComponent priority={fields.priority} /></Typography>
                        </Box>


                    </Grid>
                    <Grid item xs={6}>
                        <Box  >
                            <Typography variant="body1" fontWeight="bold" >Status  :</Typography>
                            <Typography variant="subtitle2"  ><StatusComponent id={fields._id} status={fields.status} /></Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box  >
                            <Typography variant="body1" fontWeight="bold" >Assigned By  :</Typography>
                            <Typography variant="subtitle2"  >
                                {
                                    (fields?.assigned_by?._id==user.data._id ?"You":fields?.assigned_by?.name) ?? "NA"
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}></Grid>


                    <Grid item xs={6}>
                        <Box  >
                            <Typography variant="body1" fontWeight="bold" >Delete Task</Typography>
                            <Typography variant="subtitle2"  >
                               <DeleteTaskButton id={fields._id} />
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>








            </>}
        </CustomDialog>
    </>
}
export default memo(TaskDetailsUi)