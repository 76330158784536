import { OpenInNew } from "@mui/icons-material"
import { Autocomplete, Button, ButtonGroup, CircularProgress, Grid, Grow, IconButton, MenuItem, Paper, Skeleton, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography, Zoom, styled } from "@mui/material"
import { Box } from "@mui/system"
import { Fragment, memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"


import DataTable from "../../components/tables/DataTable"

import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop"
import { BULK_INVOICE_TYPE, INVOICES_NAME, INVOICE_STATUS, INVOICE_TYPE } from "../../utils/constants/invoice.constant"
import AsynSearchBar from "../../components/inputs/AsynSearchBar"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { getInstallerApi, getUserApi } from "../../apis/user.api"
import { StyledSearchBar } from "../../components/inputs/SearchBar"
import InvoiceSummaryController from "./InvoiceSummary"
import SubmitButton from "../../components/button/SubmitButton"
import { openModal } from "../../store/actions/modalAction"
import BulkCreateController from "./BulkCreateController"
import { CustomTab, CustomTabs } from "../../components/layouts/common/Tabs"
import { isInvoiceAllowed } from "../../utils/helpers/invoice.helper"
import { USER_ROLES } from "../../utils/constants/constants"
import { moduleAccessCondition } from "../../utils/helpers/helper"
import MODULES from "../../utils/constants/module.constants"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const BulkInvoiceListUI = ({ title, filters, setFilters, list, onCreateBtnClick, loading, systemInvoice, columns }) => {

    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    return (
        <>

            <Box mb={3}>
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={4} >
                        <FilterTitleBox>
                            <Typography variant="h5" mb={2} >{title}</Typography>

                        </FilterTitleBox>

                        {<CustomTabs

                            TabScrollButtonProps
                            textColor="default"
                            TabIndicatorProps={{
                                style: {
                                    display: "none"
                                }
                            }}
                            // value={filters.status}

                            variant="scrollable"
                            scrollButtons="auto"
                            value={filters.sub_type}
                            onChange={(e, val) => {
                                setFilters({ ...filters, sub_type: val ? Number(val) : null, assigned_installer: null, assigned_supplier: null })

                            }}
                        >
                            <CustomTab label={"All"} value={null} >

                            </CustomTab>
                            {
                                Object.values(BULK_INVOICE_TYPE)?.filter(invoiceTypeId => isInvoiceAllowed(user?.data?.modules??[], invoiceTypeId))?.map((invoiceTypeId) => <CustomTab label={INVOICES_NAME[invoiceTypeId]} value={Number(invoiceTypeId)} key={invoiceTypeId} >

                                </CustomTab>)
                            }


                        </CustomTabs>}
                        <FiltersBox mt={3} >
                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1 }} >
                                <ButtonGroup disableElevation>
                                    <Button variant={filters.status == null && filters.parentStatus != 'pending' ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, status: null, parentStatus: null }) }} >All</Button>
                                    <Button variant={filters.status == INVOICE_STATUS.PENDING ? "contained" : "outlined"} onClick={() => {
                                        setFilters({ ...filters, status: INVOICE_STATUS.PENDING })

                                    }}>Pending</Button>

                                    <Button variant={filters.status == INVOICE_STATUS.PAID ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, status: INVOICE_STATUS.PAID, parentStatus: null }) }}>Paid</Button>
                                </ButtonGroup>
                            </PaddingBoxInDesktop>

                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", flex: 1, justifyContent: 'flex-end' }} pl={3} >

                                <Box>
                                   {moduleAccessCondition(user,[MODULES.CREATE_INVOICE])  &&<SubmitButton onClick={onCreateBtnClick} title="Create Bulk Invoice" >

                                    </SubmitButton>}
                                </Box>
                            </PaddingBoxInDesktop>




                        </FiltersBox>

                        <FiltersBox mt={3} >
                            {filters.sub_type == INVOICE_TYPE.ELECTRICIAN_INVOICE && <PaddingBoxInDesktop sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <AsyncDropDown

                                    id={'installer-bulk'}

                                    fullWidth
                                    lazyFun={async (params) => { return await getInstallerApi({ ...params }) }}
                                    disableclearable
                                    OptionComponent={({ option, ...rest }) => {
                                        return <MenuItem {...rest}>{option.name} </MenuItem>
                                    }}
                                    onChange={async (changedVal) => { setFilters({ ...filters, installer_id: changedVal?._id ?? null }) }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    margin="none"
                                    InputComponent={(params) => <StyledSearchBar placeholder="Select Installer/Electrician" {...params} size="small" margin="none" />}
                                />
                            </PaddingBoxInDesktop>}
                            {filters.sub_type == INVOICE_TYPE.SUPPLIER_INVOICE && <PaddingBoxInDesktop sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <AsyncDropDown

                                    id={'supplier-bulk'}

                                    fullWidth
                                    lazyFun={async (params) => { return await getUserApi({ ...params, role: USER_ROLES.supplier }) }}
                                    disableclearable
                                    OptionComponent={({ option, ...rest }) => {
                                        return <MenuItem {...rest}>{option.name} </MenuItem>
                                    }}
                                    onChange={async (changedVal) => { setFilters({ ...filters, supplier_id: changedVal?._id ?? null }) }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    margin="none"
                                    InputComponent={(params) => <StyledSearchBar placeholder="Select Supplier" {...params} size="small" margin="none" />}
                                />
                            </PaddingBoxInDesktop>}

                            <PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }} pl={2} >

                                <AsynSearchBar
                                    fullWidth
                                    title="Search Invoice no" size="small" placeholder={"Search Invoice no"}
                                    defaultValue={filters.search}
                                    onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
                                />
                            </PaddingBoxInDesktop>






                        </FiltersBox>

                    </Box>

                    <Box sx={{ minHeight: "300px" }}>
                        <DataTable key={filters.sub_type} columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                    </Box>
                </Paper>
            </Box>

        </>
    )
}
export default BulkInvoiceListUI