import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { callApiAction } from "../../store/actions/commonAction"
import { getFinanceCountApi } from "../../apis/finance.api"
import { getDateFiltersTime } from "../../utils/helpers/helper"
import FinanceCountsUi from "./FinanceCountsUI"

const FinanceCountsController = ({ }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState()
    const [data, setData] = useState({})
    const [filters, setFilters] = useState({
        dateKey: 'installtion_date',
        ...getDateFiltersTime('this_month'),
    })
    const fetchCounts = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await getFinanceCountApi({ ...filters }),
            (response) => {
                setData(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    useEffect(() => {
        fetchCounts()
    }, [filters])


    return <FinanceCountsUi
        filters={filters}
        setFilters={setFilters}
        data={data}
        loading={loading}
    />
   




 
}
export default FinanceCountsController