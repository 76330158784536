import { OpenInNew } from "@mui/icons-material"
import { Autocomplete, Button, ButtonGroup, CircularProgress, Grid, IconButton, MenuItem, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { Fragment, memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"



import SubmitButton from "../../components/button/SubmitButton"

import DataTable from "../../components/tables/DataTable"

import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop"
import AsynSearchBar from "../../components/inputs/AsynSearchBar"
import getComplainCategoryApi from "../../apis/complaincategory.api"
import { StyledSearchBar } from "../../components/inputs/SearchBar"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import ComplainCounts from "./ComplainCounts"
import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector"
import { moduleAccessCondition } from "../../utils/helpers/helper"
import MODULES from "../../utils/constants/module.constants"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const ListUi = ({ title, filters, setFilters, list, modal, loading, onCreateBtnClick, columns }) => {

    const user = useSelector(state => state.user)

    return (
        <>

            <Box mb={modal ? 0 : 3}>
                <Paper elevation={modal ? 0 : 2} sx={{ width: "100%", padding: modal ? 0 : 4 }} >
                    <Box mb={2} >
                        <FilterTitleBox>
                            <Box>
                                {!modal && <Typography variant="h5"  >{title}</Typography>}
                            </Box>
                            <Box >
                               {moduleAccessCondition(user, [MODULES.CREATE_COMPLAINS], []) && <SubmitButton variant="contained" onClick={onCreateBtnClick} title={"Add Complain"} />}
                            </Box>

                        </FilterTitleBox>
                        <FiltersBox mt={3} >
                            <PaddingBoxInDesktop sx={{ display: "flex", flex: 1 }} py={2} >


                                <TimeRangeSelector placeHolder="Creation Date" onChange={(newRange) => { setFilters({ ...filters, ...newRange }) }} />

                            </PaddingBoxInDesktop>

                            <PaddingBoxInDesktop flex={1} pl={2} >

                                <AsyncDropDown
                                    id={'complain-category-list'}
                                    
                                    lazyFun={async (params) => { return await getComplainCategoryApi({ ...params, searchable: ['title'] }) }}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <MenuItem {...rest}>{option.title}</MenuItem>
                                    }}
                                    size="small"
                                    onChange={async (changedVal) => { setFilters({ ...filters, category: changedVal ? changedVal._id : null, subCategory: null }) }}
                                    titleKey={'title'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder="Select Category" {...params} size="small" />}
                                />
                            </PaddingBoxInDesktop>
                            <PaddingBoxInDesktop display="flex" flex={1} pl={2} >

                                {filters.category && <AsyncDropDown
                                id={'complain-sub-category-list'}
                                    key={filters.category}
                                    
                                    lazyFun={async (params) => { return await getComplainCategoryApi({ ...params, parent: filters.category }) }}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <MenuItem {...rest}>{option.title}</MenuItem>
                                    }}
                                    onChange={async (changedVal) => { setFilters({ ...filters, subCategory: changedVal ? changedVal._id : null }) }}
                                    titleKey={'title'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder="Select Sub Category" {...params} size="small" />}
                                />}
                            </PaddingBoxInDesktop>
                        </FiltersBox>
                        <FiltersBox mt={3} >
                            <ComplainCounts filters={filters} setFilters={setFilters} />
                        </FiltersBox>
                        <FiltersBox mt={3} >

                            <PaddingBoxInDesktop mb={2} sx={{ display: "flex", justifyContent: "flex-end" }} pl={3} >

                                {/* <AsynSearchBar
                                    fullWidth
                                    title="Search By Source Name " size="small" placeholder={"Search By Source Name "}
                                    defaultValue={filters.search}
                                    onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
                                /> */}
                                {/* <StyledSearchBar  title="Search By Key Name " size="small" placeholder={"Search By key Name "} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} /> */}
                            </PaddingBoxInDesktop>



                        </FiltersBox>

                    </Box>

                    <Box sx={{ minHeight: modal ? "0px" : "300px" }}>
                        <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                    </Box>
                </Paper>
            </Box>

        </>
    )
}
export default ListUi