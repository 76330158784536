import { useState } from "react"
import CustomDialog from "../../components/layouts/common/CustomDialog"
import { MODAL_KEYS } from "../../utils/constants/modal.constant"
import { Autocomplete, Box, Button, ButtonGroup, Grid, MenuItem, Typography } from "@mui/material"
import { findObjectKeyByValue, moduleAccessCondition, titleCase, toTitleCase } from "../../utils/helpers/helper"
import { PROJECT_FLAGS, STOCK_TYPE } from "../../utils/constants/project.constant"
import { getStatusOfProjectFlags } from "../../utils/helpers/project.helper"
import { useDispatch, useSelector } from "react-redux"
import { closeModal, openModal } from "../../store/actions/modalAction"
import { Filter, Inventory, Tune } from "@mui/icons-material"
import CustomButton from "../../components/button/CustomButton"
import MODULES from "../../utils/constants/module.constants"
import { StyledSearchBar } from "../../components/inputs/SearchBar"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { getStockItemsApi } from "../../apis/projects.api"

export const ProductFilterButton = ({ filters, setFilters }) => {
    const dispatch = useDispatch()
    const { user } = useSelector(state => state)
    const onClick = () => {
        dispatch(openModal(
            <ProductFiltersComponent defaultFilters={filters} onSubmitFun={(updatedFilters) => {
                setFilters({ ...updatedFilters, filterKey: Math.random() })
                dispatch(closeModal(MODAL_KEYS.PROJECT_STOCK_FILTERS))
            }} />,
            "xs",
            undefined,
            MODAL_KEYS.PROJECT_STOCK_FILTERS
        ))
    }
    if (!moduleAccessCondition(user, [MODULES.PROJECT_ACTION_FILTER], []))
        return <></>
    return <Button startIcon={<Inventory />} disableElevation variant="contained" size="small" onClick={onClick} >
        Stock Filters
    </Button>
}

const ProductFiltersComponent = ({ defaultFilters, onSubmitFun }) => {


    const [filters, setFilters] = useState({ ...defaultFilters })
    const onSubmit = (e) => {
        e?.preventDefault()

        onSubmitFun(filters)
    }
    return <CustomDialog

        id={MODAL_KEYS.PROJECT_STOCK_FILTERS}
        title="Set Stock Filters"
        onSubmit={onSubmit}
        confirmText="Apply"

    >



        <Autocomplete
            value={findObjectKeyByValue(filters.product, STOCK_TYPE)}
            disableClearable
            onChange={(e, newVal) => {
                setFilters({ ...filters, product: newVal._id, productValue: null })
            }}
            options={[...Object.keys(STOCK_TYPE).map((key) => ({ label: titleCase(key), _id: STOCK_TYPE[key] }))]}

            sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
            renderInput={(params) => <StyledSearchBar placeholder="Select Stock Type" {...params} size="small" />}
        />



        <Box mt={2} />

        <AsyncDropDown
        defaultVal={filters.productValue?{ stockCode: filters.productValue, stockName: filters.productTitle }:null}
            key={filters?.product}
            lazyFun={async (params) => { return await getStockItemsApi({ ...params, searchModule: filters.product }) }}

            OptionComponent={({ option, ...rest }) => {
                return <MenuItem {...rest}>{option.stockName}({option.stockCode})</MenuItem>
            }}
            onChange={async (changedVal) => { setFilters({ ...filters, productTitle: changedVal ? changedVal.stockName : null, productValue: changedVal ? changedVal.stockCode : null }) }}
            titleKey={'stockCode'}
            valueKey={"stockCode"}
            InputComponent={(params) => <StyledSearchBar placeholder="Select Item" {...params} margin="none" size="small" />}
            getOptionLabel={(option) => {

                return `${option.stockName}(${option.stockCode})`;
            }}
            margin={0}
        />

        {/* <AsynSearchBar
                fullWidth
                title="Search By Key Name " size="small" placeholder={"Search By Stock name | Stock code "}
                defaultValue={filters.search}
                onChange={(changedVal) => { setFilters({ ...filters, search: changedVal }) }}
            /> */}


    </CustomDialog>
}
